import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HelperService } from "../../../../common/helper.service";
import { StudiosService } from "../../../studios.service";
import { ProfileService } from "../../../../common/profile.service";
import { map, skip } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { ContractsService } from "../../../../authentication/contract/contracts.service";
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-studio-contracts',
  templateUrl: './studio-contracts.component.html',
  styleUrls: ['./studio-contracts.component.scss'],
})
export class StudioContractsComponent implements OnInit {
  public env = environment;
  public user;

  private studioId;

  public contractValidity;
  public contractMessage = [];

  public contractSignModal = false;
  public contractData;
  public contractSignModelMaximized = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private helperService: HelperService,
    private studiosService: StudiosService,
    private contractsService: ContractsService,
    private profileService: ProfileService,
    private messageService: MessageService,
  ) {

    this.studioId = this.profileService.getOwnerStudio().id;
    this.contractValidity = this.studiosService.contractValidity$.pipe(
      skip(1),
      map((data) => {
        this.contractMessage = [];
        this.contractMessage.push(
          {
            severity: data.valid ? 'success' : 'error',
            summary: data.valid? 'Reviewed & Accepted the latest contract.' : 'Please review our updated contract!',
            detail: data.valid ? 'Signed on ' + this.helperService.formatDate(data.contract.createdAt , 'yyyy-MM-dd') : 'A new version has been uploaded, please read our full updated contract by clicking "View"'
          }
        )

        return data;
      })
    );
    this.studiosService.fetchContractsValidity(this.studioId);

  }

  ngOnInit(): void {
  }

  downloadContract() {
    this.studiosService.downloadLatestContract(this.studioId)
      .then((data) => {
        this.blobToFile(
          data,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          'bandbeat_contract.docx'
        )
      });

  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   * @param fileName - file name of the document.
   */
  blobToFile(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url; a.download = fileName; a.click();
    window.URL.revokeObjectURL(url);
  }

  showContract() {

    if (
      !this.profileService.getUserData().businessName || this.profileService.getUserData().businessName.length === 0
    ) {
      this.messageService.add(
        {
          key: 'globalToast',
          severity: 'error',
          summary: 'Missing data',
          detail: 'Add your official business name first!'
        });
    }else if (
      (!this.profileService.getUserData().address.country || this.profileService.getUserData().address.country.length === 0) ||
      (!this.profileService.getUserData().address.city || this.profileService.getUserData().address.city.length === 0) ||
      (!this.profileService.getUserData().address.street || this.profileService.getUserData().address.street.length === 0) ||
      (!this.profileService.getUserData().address.number || this.profileService.getUserData().address.number.length === 0) ||
      (!this.profileService.getUserData().address.zip || this.profileService.getUserData().address.zip.length === 0)
    ) {
      this.messageService.add(
        {
          key: 'globalToast',
          severity: 'error',
          summary: 'Missing data',
          detail: 'Add your billing address first!'
        });
    } else {

      this.contractsService.fetchLatestTemplate(
        {
          studio_name : this.profileService.getOwnerStudio().name,
          business_name : this.profileService.getUserData().businessName,
          billing_country : this.profileService.getUserData().address.country,
          billing_city : this.profileService.getUserData().address.city,
          billing_street : this.profileService.getUserData().address.street,
          billing_street_number : this.profileService.getUserData().address.number,
          billing_zip_code : this.profileService.getUserData().address.zip,
          billing_tin_number : this.profileService.getOwnerStudio().vat,
          billing_email : this.profileService.getUserData().email,
          studio_owner_name : (this.profileService.getUserData().name + ' ' + this.profileService.getUserData().lastName).trim(),
          individual_name : (this.profileService.getUserData().name + ' ' + this.profileService.getUserData().lastName).trim(),
          individual_capacity : 'OWNER',
        }
      ).then((data) => {
        this.contractData = data.value;

        this.contractSignModal = true;
      });
    }


  }

  public signContract() {
    return this.contractsService.createNewContract()
      .then((data) => {
        this.messageService.add(
          {
            key: 'globalToast',
            severity: 'success',
            summary: 'Contract Signed',
            detail: 'Your contract is valid according to latest Bandbeat template!'
          });
        this.contractSignModal = false;
        return this.studiosService.fetchContractsValidity(this.studioId);
      });
  }

  public contractModalMaximize(event) {
    this.contractSignModelMaximized = event.maximized;
  }

}
