import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HelperService} from '../../../common/helper.service';
import {StudiosService} from '../../studios.service';
import {BookingsService} from '../../../bookings/bookings.service';
import {ProfileService} from '../../../common/profile.service';
import {map} from 'rxjs/operators';
import { jsPDF } from "jspdf";
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver';

import 'jspdf-autotable'

import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-studio-payments',
  templateUrl: './studio-payments.component.html',
  styleUrls: ['./studio-payments.component.scss']
})
export class StudioPaymentsComponent implements OnInit {

  public env = environment;
  public user;

  private studioId;

  public transactions;

  bookingStatuses: any = [
    {label: 'Confirmed', value: 'studio-confirmed'},
    {label: 'Canceled', value: 'studio-canceled'},
    {label: 'Canceled', value: 'user-canceled'},
  ]


  public cols = [
    { field: 'shortId', header: 'ID' },
    { field: 'date', header: 'Booking Date' },
    { field: 'service', header: 'Service Type' },
    { field: 'paymentMethod', header: 'Payment Method' },
    { field: 'bookingStatus', header: 'Booking Status' },
    { field: 'returnPrice', header: 'Return' },
  ];
  private transactionsData = [];

  public pickedDate = new Date();
  public exportFileName = 'returns';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private helperService: HelperService,
    private studiosService: StudiosService,
    private bookingsService: BookingsService,
    private profileService: ProfileService,
  ) {

    this.studioId = this.profileService.getOwnerStudio().id;
    this.transactions = this.studiosService.transactions$.pipe(
      map((transactions) => {
        this.transactionsData = transactions.map((transaction) => {

          return {
            shortId : transaction.shortId,
            service : this.helperService.toTitleCase(transaction.service.type),
            date : transaction.bookingDate ,
            paymentMethod : ( transaction.paymentMethod === 'online' &&
            transaction.paymentDone === true &&
            transaction.paymentStatus &&
            transaction.paymentStatus === 'CAPTURED' ) ? this.helperService.toTitleCase(transaction.paymentMethod) : 'Cash',
            bookingStatus : this.labelBookingStatus(transaction),
            returnPrice : this.calculateReturn(transaction),
            bookingId : transaction.id,
            booking : transaction
          }
        });
        return this.transactionsData;
      })
    );
    this.studiosService.fetchStudioTransactions(this.studioId , new Date().toISOString(), new Date().toISOString());
    this.changeDownloadFileName();

  }

  ngOnInit(): void {
  }

  labelBookingStatus(booking: any) {
    const bookingStatus = this.bookingStatuses.find(el => el.value === booking.status);
    if (booking.reported === true) {
      return 'Reported'
    }

    if (bookingStatus) {
      if (bookingStatus.value === 'user-canceled') {
        return this.helperService.timeDifference(booking.bookingDate , booking.cancelledAt  ) > 24 ? bookingStatus.label + ' (N24)' : bookingStatus.label + ' 24';
      }
      return bookingStatus.label;
    } else {
      return 'Done';
    }
  }

  calculateReturn(booking: any) {
    if (booking.reported === true) {
      return 0;
    }
    return booking.totalPrice;
  }

  changeDownloadFileName() {
    this.exportFileName = 'returns_' + this.helperService.formatDate(this.pickedDate.toISOString() , 'MM_yyyy');
  }

  getCurrencySymbol(){
    const studioCurrency = this.profileService.getOwnerStudio().businessCurrency
      ? this.profileService.getOwnerStudio().businessCurrency
      : 'EUR';
    return this.helperService.getCurrencySymbol(studioCurrency);
  }


  calculateTotalEarnings(tablePayments) {
    if (tablePayments) {
      return tablePayments.reduce((total, obj) => {
        return obj.returnPrice + total
      },0)
    } else {
      return 0;
    }
  }

  exportPdf( tablePayments ) {
    const doc = new jsPDF('p', 'px', 'a4');
    const totalPagesExp = '{total_pages_count_string}';
    const headerText = this.profileService.getOwnerStudio().name + ' Payments ' + this.helperService.formatDate(this.pickedDate.toISOString() , 'MM-yyyy');
    doc.text(headerText, 15, 22);
    (doc as any).autoTable(
      {
        columns : this.cols.map(el => { return {title: el.header , dataKey : el.field} }),
        body : tablePayments,
      }
    );

    // @ts-ignore
    doc.text('Total Bookings : ' + tablePayments.length, 15, doc.lastAutoTable.finalY + 20,);
    // @ts-ignore
    doc.text('Total Returns : ' + this.calculateTotalEarnings(tablePayments), 15, doc.lastAutoTable.finalY + 40,);

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }


    doc.save(this.exportFileName + '.pdf');
  }


  exportExcel( tablePayments ) {

    const excelData = tablePayments.map((el) => {
      let excelRow = {};
      this.cols.forEach((col) => {
        excelRow[col.header] = el[col.field]
      });
      return excelRow;
    })

    const worksheet = xlsx.utils.json_to_sheet(excelData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer);
  }

  saveAsExcelFile(buffer: any): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    this.helperService.saveToFile(buffer, this.exportFileName + '.xlsx' , EXCEL_TYPE);
  }

  changeDate(event) {
    this.studiosService.fetchStudioTransactions(this.studioId , event.toISOString(), event.toISOString());
    this.changeDownloadFileName();
  }

}
