import { Component, OnInit } from '@angular/core';
import { MessageService } from "primeng/api";
import { UserService } from "../../../users/user/user.service";
import { ProfileService } from "../../../common/profile.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-studio-password',
  templateUrl: './studio-password.component.html',
  styleUrls: ['./studio-password.component.scss']
})
export class StudioPasswordComponent implements OnInit {

  public newPassword = '';
  public newPasswordRetry = '';
  public currentPassword = '';

  private userId;

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.userId = this.profileService.getUserData().id;
  }

  ngOnInit(): void {
  }

  changePassword() {
    if (this.currentPassword.length === 0 || this.newPassword.length === 0) {
      this.messageService.add({
        key: 'globalToast', severity: 'warn', summary: 'Warning', detail: 'You need to fill old and new password!', life: 3000});
      return;
    }
    if (this.newPassword.length > 0 && this.newPassword !== this.newPasswordRetry) {
      this.messageService.add({
        key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Passwords doesnt match', life: 3000});
      return;
    }

    if (this.newPassword.length > 0 && this.newPassword === this.currentPassword) {
      this.messageService.add({
        key: 'globalToast', severity: 'error', summary: 'Error', detail: 'You typed the same password, please enter a different password', life: 3000});
      return;
    }

    if (this.currentPassword.length > 0) {
      this.userService.changePassword(this.userId , this.currentPassword , this.newPassword)
        .then((data) => {
          this.messageService.add({
            key: 'globalToast', severity: 'success', summary: 'Success', detail: 'Password Changed', life: 3000});
            this.goBack()
        })
    }
  }

  goBack(){
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
