<div class="layout-wrapper" [ngClass]="{'layout-slim': app.menuMode === 'slim',
                                        'layout-static': app.menuMode === 'static',
                                        'layout-mobile-active': staticMenuMobileActive,
                                        'layout-static-inactive': staticMenuDesktopInactive && app.menuMode === 'static',
                                        'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple}"
     (click)="onLayoutClick()">

  <app-topbar></app-topbar>

  <div class="layout-content-wrapper" style="width:100%;margin-left:0px;">

    <p-toast
      [style]="{'overflow-wrap' : 'break-word'}"
      [baseZIndex]="5000"
      life="4000"
      key="globalToast"
    ></p-toast>


    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  </div>

</div>

<p-scrollTop></p-scrollTop>
