<p-card [style]="{borderRadius : 4 }" header="{{studio.name}}" styleClass="p-card-shadow"
        subheader="{{studio.address}}">
    <p><strong>Price range | </strong>sdfsdfsdfs</p>
    <p><strong>Service | </strong></p>
    <p><strong>Rooms | </strong> {{studio.rooms.length}}</p>
    <ng-template pTemplate="footer">
        <div style="text-align:right">
            <button class="p-button-text" label="Book the studio" pButton pRipple type="button"></button>
        </div>
    </ng-template>
</p-card>
