<h1>
  Monthly Reports
</h1>

<div class="grid" *ngIf="monthlyReports | async as reportData">

  <div class="col-12 md:col-6">
    <span style="margin-right:2em;">
      Select available month
    </span>
    <p-dropdown
      [options]="availableMonths"
      [(ngModel)]="pickedMonth"
      optionLabel="name"
      placeholder="Select a Month"
      (onChange)="pickMonth($event)"
    ></p-dropdown>
  </div>

</div>


