import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BandsService} from "../../bands.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {HelperService} from "../../../common/helper.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {InstrumentAndEquipmentService} from "../../../common/instruments.equipments.service";

@Component({
  selector: 'app-band-members',
  templateUrl: './band-members.component.html',
  styleUrls: ['./band-members.component.scss']
})
export class BandMembersComponent implements OnInit, OnChanges {

  @Input() bandId: string;
  public env = environment;

  enableActions = false;

  public cols: any[] = [];
  public members: any;
  public loading = true;
  public memberDialog = false;

  public member: any = {};
  public memberEmail: string;
  public submitted = false;

  public musicRolesMap;

  constructor(private bandsService: BandsService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private instrumentAndEquipmentService : InstrumentAndEquipmentService,
              private helperService: HelperService,
              private router: Router ,
              private route: ActivatedRoute
  ) {
    this.musicRolesMap = this.instrumentAndEquipmentService.getMusicRolesMap();
  }


  ngOnInit(): void {

    this.members = this.bandsService.bandMembers$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.bandId = changes.bandId.currentValue;
    if (changes.hasOwnProperty('bandId') && changes.bandId.currentValue) {
      this.bandsService.fetchBandMembers(this.bandId)
        .then((members) => {
          this.loading = false;
        });
    }
  }

  openNew() {
    this.memberEmail = '';
    this.submitted = false;
    this.memberDialog = true;
  }

  editMember(member) {

  }

  removeMember(member) {
    this.bandsService.removeMember(this.bandId , member.user.id).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Member Removed', life: 3000});
    });
  }

  inviteMember() {
    if (this.memberEmail.trim().length > 0) {
      this.submitted = true;
      this.bandsService.inviteBandMemberByMail(this.bandId , this.memberEmail).then((data) => {
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Sent', life: 3000});
        this.memberEmail = '';
        this.memberDialog = false;
        this.bandsService.fetchBandInvites(this.bandId);
      });
    }
  }

  refresh() {
    this.loading = true;
    this.bandsService.fetchBandMembers(this.bandId)
      .then((members) => {
        this.loading = false;
      });
  }

  hideDialog() {
    this.memberEmail = '';
    this.memberDialog = false;
    this.submitted = false;
  }

  saveMember() {

  }


}
