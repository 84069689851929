import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BookingsService} from '../bookings.service';
import {zip} from 'rxjs';

@Component({
  selector: 'app-new-booking',
  templateUrl: './new-booking.component.html',
  styleUrls: ['./new-booking.component.scss']
})
export class NewBookingComponent implements OnInit {

  items: MenuItem[];
  activeIndex = 0;



  @ViewChild('bookingSteps') bookingSteps: ElementRef<HTMLInputElement>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,

    private bookingsService: BookingsService
  ) { }

  ngOnInit(): void {

    zip(this.route.url , this.route.queryParams)
      .subscribe((routerData) => {
        this.bookingsService.resetNewBookingData();
        const url = routerData[0];
        const params = routerData[1];

        const restartBooking = url.findIndex(path => path.path === 'restartbooking') > -1;
        const restartedBooking = params.restartBooking;

        this.items = [{
          label: 'Pick Type',
          routerLink: ['type']
        },
          {
            label: 'Pick Studio',
            routerLink: ['studio']
          },
          {
            label: 'Add Info',
            routerLink: ['info']
          },
          {
            label: 'Confirmation',
            routerLink: ['confirm']
          }
        ];

        if (restartBooking) {
          this.items.shift();
        }
        if (restartedBooking) {
          this.bookingsService.restartNewBookingData(params.restartBooking)
            .then((data) => {
              this.activeIndex = 1;
              this.router.navigate(['studio'
                ],
                {
                  relativeTo: this.route
                }
              );
            });        }

      });
  }

  changeStep(event) {

  }
  nextStep() {
    if (this.activeIndex === 0) {
      this.router.navigate(
        ['studio'],
        { relativeTo: this.route}
      );
    } else if (this.activeIndex === 1) {
      this.router.navigate(
        ['type'],
        { relativeTo: this.route}
      );
    }
  }

}
