import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-studio-advanced',
    templateUrl: './studio-advanced.component.html',
    styleUrls: ['./studio-advanced.component.scss']
})
export class StudioAdvancedComponent implements OnInit {

    @Input() studio;

    constructor() {
    }

    ngOnInit(): void {
    }

}
