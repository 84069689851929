import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance, differenceInHours, format } from 'date-fns'


@Pipe({
  name: 'dateParser'
})
export class DateParserPipe implements PipeTransform {

  transform(value: string, arg?: string): unknown {
    const dateValue = new Date(value);
    const currentDate = new Date();
    if (arg && arg.length > 0) {
      return format(dateValue , arg)
    }
    const diff = differenceInHours(currentDate , dateValue);
    if (diff < 32) {
        return formatDistance(dateValue, currentDate, { addSuffix: true })
    }
    return format(dateValue , 'yyyy-MM-dd HH:mm')
  }

}
