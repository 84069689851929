import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { StudiosService } from '../../studios.service';
import { BookingsService } from '../../../bookings/bookings.service';
import { ProfileService } from '../../../common/profile.service';
import { filter, map, take, first, tap } from 'rxjs/operators';
import { HelperService } from '../../../common/helper.service';
import { environment } from '../../../../environments/environment';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { InstrumentAndEquipmentService } from '../../../common/instruments.equipments.service';

declare var google: any;

@Component({
  selector: 'app-studio-profile',
  templateUrl: './studio-profile.component.html',
  styleUrls: ['./studio-profile.component.scss'],
  providers: [ConfirmationService],
})
export class StudioProfileComponent implements OnInit {
  public phonePrefix = '(+30) ';
  public countries: any[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private helperService: HelperService,
    private studiosService: StudiosService,
    private bookingsService: BookingsService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private instrumentAndEquipmentService: InstrumentAndEquipmentService,
    private confirmationService: ConfirmationService
  ) {
    this.services = [
      { name: 'Recording', code: 'recording' },
      { name: 'Rehearsal', code: 'rehearsal' },
    ];
    this.countries = this.helperService.getCountriesData();
  }

  @Input() studioId: string;

  studio: any;
  studioEquipment: any;

  env = environment;
  rooms: any;

  availableRentals = true;
  instruments;
  equipments;
  instrumentBrandMap = new Map<String, any[]>();
  equipmentBrandMap = new Map<String, any[]>();

  newInstrument;
  newInstrumentBrand;
  newInstrumentName;
  newInstrumentPrice;
  newInstrumentAvailability = true;

  newRoomDescription;
  newRoomName;
  newRoomDialog = false;

  editRoomNameDialog = false;
  roomToEdit;

  services: any[];
  availableServices: any[];

  // newServiceName;
  newServiceType;
  newServicePrice;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  availabilityDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  showNewInstrumentRow = false;
  showNewServiceRow = false;
  showNewEquipmentRow = false;
  // newDiscountCutOff;
  newDiscountName;
  newDiscountPercentage = 50;

  newPackageCutOff;
  newPackagePayOff;
  newPackageName;

  studioImagesDialog = false;

  activeState: boolean[] = [];

  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedFile: any = '';
  uploadImageDialog = false;

  streetAddress: string;
  autocomplete: google.maps.places.Autocomplete;
  studioMap: google.maps.Map;
  @ViewChild('addressField') addressField: ElementRef<HTMLInputElement>;

  public mapOptions: any = {
    center: { lat: 37.9908697, lng: 23.7208298 },
    zoom: 12,
    draggable: true,
    styles: [
      { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#263c3f' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#6b9a76' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#2f3948' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }],
      },
    ],
  };

  public mapOverlays: any = [];

  public businessCurrency: string;
  public businessCurrencySymbol: string;

  ngOnInit(): void {
    this.instruments = this.instrumentAndEquipmentService.instruments;
    this.equipments = this.instrumentAndEquipmentService.equipments;

    this.instruments.forEach((inst) => {
      this.instrumentBrandMap.set(inst.type, inst.brands);
    });
    this.equipments.forEach((eq) => {
      this.equipmentBrandMap.set(eq.type, eq.brands);
    });

    this.studio = this.studiosService.studio$.pipe(
      filter((std) => Object.keys(std).length !== 0),
      map((studioData) => {
        const logo = studioData.media.find(
          (media) => media.metadata.type === 'logo'
        );
        if (logo) {
          studioData.logo = logo['link'];
        } else {
          studioData.logo = 'assets/images/bandbeat/sample_studio_logo.png';
        }
        const cover = studioData.media.find(
          (media) => media.metadata.type === 'cover'
        );
        if (cover) {
          studioData.cover = cover['link'];
        } else {
          studioData.cover = 'assets/images/bandbeat/sample_studio_logo.png';
        }
        studioData.studioImages = studioData.media.filter(
          (md) => md.metadata.type !== 'logo' && md.metadata.type !== 'cover'
        );
        if (studioData.studioImages.length === 0) {
          this.studioImagesDialog = false;
        }
        this.availabilityDays.forEach((day) => {
          if (studioData.availability[day].from) {
            studioData.availability[day].from = new Date(
              studioData.availability[day].from
            );
            studioData.availability[day].from.setMinutes(
              Math.ceil(studioData.availability[day].from.getMinutes() / 30) *
                30
            );
          }
          if (studioData.availability[day].to) {
            studioData.availability[day].to = new Date(
              studioData.availability[day].to
            );
            studioData.availability[day].to.setMinutes(
              Math.ceil(studioData.availability[day].to.getMinutes() / 30) * 30
            );
          }
        });
        if (!studioData.hasOwnProperty('coordinates')) {
          studioData.coordinates = {
            x: null,
            y: null,
          };
        } else {
          this.mapOverlays = [
            new google.maps.Marker({
              position: {
                lat: studioData.coordinates.x,
                lng: studioData.coordinates.y,
              },
              icon: '/assets/images/bandbeat/bb_logo_map_layer_marker.png',
              draggable: true,
            }),
          ];

          this.mapOptions.center = {
            lat: studioData.coordinates.x,
            lng: studioData.coordinates.y,
          };
          this.mapOptions.center.zoom = 18;
        }
        if (!studioData.hasOwnProperty('address')) {
          studioData.address = {
            street: null,
            number: null,
            zip: null,
            city: null,
            country: null,
          };
        } else if (
          studioData.address.hasOwnProperty('number') &&
          studioData.address.number
        ) {
          studioData.address.number = studioData.address.number + '';
        }
        if (studioData.address.country) {
          this.selectCountry({ value: studioData.address.country });
        }
        return studioData;
      }),
      tap((data) => {
        this.initAutoComplete();
      })
    );

    this.studioEquipment = this.studiosService.rentalEquipment$.pipe(
      map((equipments) => {
        return equipments.map((eq) => {
          eq.editAvailable = eq.available;
          return eq;
        });
      })
    );

    if (this.profileService.isStudio()) {
      this.studioId = this.profileService.getOwnerStudio().id;
      this.businessCurrency = this.profileService.getOwnerStudio()
        .businessCurrency
        ? this.profileService.getOwnerStudio().businessCurrency
        : 'EUR';
      this.businessCurrencySymbol = this.helperService.getCurrencySymbol(
        this.businessCurrency
      );
      this.studiosService.fetchStudio(this.studioId);
      this.studiosService.fetchStudioRentalEquipment(this.studioId);
    }
  }

  setMap(event) {
    this.studioMap = event.map;
  }

  overlayDragEnd(event) {
    this.studio.pipe(first()).subscribe((studioData: any) => {
      studioData.coordinates = {
        x: event.originalEvent.latLng.lat(),
        y: event.originalEvent.latLng.lng(),
      };

      this.mapOverlays = [
        new google.maps.Marker({
          position: {
            lat: event.originalEvent.latLng.lat(),
            lng: event.originalEvent.latLng.lng(),
          },
          icon: '/assets/images/bandbeat/bb_logo_map_layer_marker.png',
          draggable: true,
        }),
      ];

      this.studioMap.setCenter({
        lat: event.originalEvent.latLng.lat(),
        lng: event.originalEvent.latLng.lng(),
      });
      this.studioMap.setZoom(18);
    });
  }

  initAutoComplete() {
    if (!this.addressField || !this.autocomplete) {
      setTimeout(() => {
        if (this.addressField || !this.autocomplete) {
          this.autocomplete = new google.maps.places.Autocomplete(
            this.addressField.nativeElement,
            {
              language: 'en',
              fields: ['address_components', 'geometry'],
              types: ['address'],
            }
          );
          this.autocomplete.addListener('place_changed', () => {
            const place = this.autocomplete.getPlace();
            console.log(place);

            const street = place.address_components.find((el) =>
              el.types.includes('route')
            );
            const roadNumber = place.address_components.find((el) =>
              el.types.includes('street_number')
            );
            const zip = place.address_components.find((el) =>
              el.types.includes('postal_code')
            );
            const city = place.address_components.find((el) =>
              el.types.includes('locality')
            );
            const country = place.address_components.find((el) =>
              el.types.includes('country')
            );

            if (street && roadNumber && zip && city && country) {
              this.studio.pipe(first()).subscribe((studioData: any) => {
                studioData.address = {
                  street: street.long_name,
                  number: roadNumber.long_name,
                  zip: zip.long_name,
                  city: city.long_name,
                  country: country.long_name,
                };

                studioData.coordinates = {
                  x: place.geometry.location.lat(),
                  y: place.geometry.location.lng(),
                };

                this.mapOverlays = [
                  new google.maps.Marker({
                    position: {
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                    },
                    icon: '/assets/images/bandbeat/bb_logo_map_layer_marker.png',
                    draggable: true,
                  }),
                ];

                this.studioMap.setCenter({
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                });
                this.studioMap.setZoom(18);
              });
            } else {
              this.messageService.add({
                key: 'globalToast',
                severity: 'error',
                summary: 'Error',
                detail: 'Select more specific address!',
                life: 3000,
              });
              this.streetAddress = '';
            }
          });
        }
      }, 1000);
    }
  }

  addNewInstrument() {
    const newInstrument: any = {};
    newInstrument.name = this.newInstrumentName;
    newInstrument.category = this.newInstrument?.type;
    newInstrument.price = this.newInstrumentPrice;
    newInstrument.available = this.newInstrumentAvailability;
    newInstrument.brand = this.newInstrumentBrand?.type;
    if (!newInstrument.category) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Instrument Type is required!',
        life: 3000,
      });
    } else if (!newInstrument.brand) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Instrument Brand is required!',
        life: 3000,
      });
    } else if (!newInstrument.name) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Instrument Name is required!',
        life: 3000,
      });
    } else if (!newInstrument.price && newInstrument.price !== 0) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Instrument Price is required!',
        life: 3000,
      });
    } else {
      this.studiosService
        .createInstrument(this.studioId, newInstrument)
        .then((data) => {
          this.newInstrumentPrice = null;
          this.newInstrument = null;
          this.newInstrumentName = null;
          this.newInstrumentBrand = null;
          this.newInstrumentAvailability = true;
          this.showNewInstrumentRow = false;
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Instrument Added',
            life: 3000,
          });
        });
    }
  }

  addInstrumentRow() {
    this.showNewInstrumentRow = true;
  }

  clearNewInstrument() {
    this.showNewInstrumentRow = false;
  }

  addServiceRow(room) {
    this.showNewServiceRow = true;
    this.buildAvailableServices(room);
  }

  private buildAvailableServices(room) {
    this.availableServices = [
      { name: 'Recording', code: 'recording' },
      { name: 'Rehearsal', code: 'rehearsal' },
    ];
    let services = [];
    services = Object.assign(services, this.availableServices);
    services.forEach((service) => {
      if (room.services.find((e) => e.type === service.code)) {
        this.availableServices.splice(
          this.availableServices.indexOf(service),
          1
        );
      }
    });
  }

  clearNewService(room) {
    room.newServiceType = null;
    room.newServicePrice = null;
    this.showNewServiceRow = false;
  }

  addEquipmentRow() {
    this.showNewEquipmentRow = true;
  }

  clearNewEquipment() {
    this.showNewEquipmentRow = false;
  }

  toggleEquipmentEdit(equipment) {
    equipment.edit = !equipment.edit;
    if (equipment.edit) {
      equipment.editName = equipment.name;
      equipment.editDescription = equipment.description;
      return;
    }
    equipment.editName = '';
    equipment.editDescription = '';
  }

  toggleServiceEdit(service) {
    service.edit = !service.edit;
    if (service.edit) {
      service.editPrice = service.price;
      return;
    }
    service.editPrice = 0;
  }

  showNewDiscount(service, type) {
    if (type === 'package') {
      service.addNewPackageDialog = true;
    } else {
      service.addNewDiscountDialog = true;
    }
  }

  addNewDiscount(room, service, type) {
    const newDiscount: any = {};
    if (type === 'discount') {
      if (this.newDiscountPercentage == null) {
        this.messageService.add({
          key: 'globalToast',
          severity: 'warn',
          summary: 'Warning',
          detail: 'You need to input a valid percentage for the discount!',
          life: 3000,
        });
        return;
      }

      newDiscount.rules = {
        percentage: this.newDiscountPercentage,
      };
      if (this.newDiscountName) {
        newDiscount.name = this.newDiscountName;
      }
    } else if (type === 'package') {
      if (this.newPackageCutOff == null || this.newPackageCutOff > 6) {
        this.messageService.add({
          key: 'globalToast',
          severity: 'info',
          summary: 'Info',
          detail: 'You need to input a valid cut off for the package!',
          life: 3000,
        });
        return;
      }
      if (
        this.newPackagePayOff == null ||
        this.newPackagePayOff > service.price * this.newPackageCutOff
      ) {
        this.messageService.add({
          key: 'globalToast',
          severity: 'warn',
          summary: 'Warning',
          detail: 'You need to input a valid pay off for the package!',
          life: 3000,
        });
        return;
      }

      newDiscount.rules = {
        cutoff: this.newPackageCutOff,
        payoff: this.newPackagePayOff,
      };
      newDiscount.type = 'amount';
      if (this.newPackageName) {
        newDiscount.name = this.newPackageName;
      }
    }

    this.studiosService
      .createServiceDiscount(this.studioId, room.id, service.id, newDiscount)
      .then((data) => {
        this.updateLocalData(data);
        this.newDiscountPercentage = 50;
        this.newDiscountName = null;

        this.newPackageCutOff = null;
        this.newPackagePayOff = null;
        this.newPackageName = null;

        service.addNewDiscountDialog = false;
        service.addNewPackageDialog = false;
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Discount Added',
          life: 3000,
        });
      })
      .catch((err) => {
        this.messageService.add({
          key: 'globalToast',
          severity: 'error',
          summary: 'Error',
          detail: err.error.message,
          life: 3000,
        });
        throw err;
      });
  }

  removeDiscount(event, room, service, discount) {
    this.studiosService
      .removeServiceDiscount(this.studioId, room.id, service.id, discount.id)
      .then((data) => {
        this.updateLocalData(data);
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Discount Removed',
          life: 3000,
        });
      });
  }

  toggleInstrumentEdit(instrument) {
    instrument.edit = !instrument.edit;
    if (instrument.edit) {
      instrument.equipment.editName = instrument.equipment.name;
      instrument.editPrice = instrument.price;
      return;
    }
    instrument.equipment.editName = '';
    instrument.equipment.editPrice = 0;
  }

  removeInstrument(instrumentId) {
    this.studiosService
      .removeInstrument(this.studioId, instrumentId)
      .then((data) => {
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Instrument Removed',
          life: 3000,
        });
      });
  }

  editInstrument(instrumentId, instrument) {
    if (!instrument.equipment.editName) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Instrument name is required!',
        life: 3000,
      });
    } else if (!instrument.editPrice && instrument.editPrice !== 0) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Instrument price is required!',
        life: 3000,
      });
    } else {
      const editedInstrument: any = {};
      if (
        instrument.equipment.editName &&
        instrument.equipment.editName.trim().length > 0
      ) {
        editedInstrument.name = instrument.equipment.editName;
      }
      if (instrument.editPrice || instrument.editPrice === 0) {
        editedInstrument.price = instrument.editPrice;
      }
      if (
        instrument.hasOwnProperty('editAvailable') &&
        instrument.editAvailable !== instrument.available
      ) {
        editedInstrument.available = instrument.editAvailable;
      }
      if (Object.keys(editedInstrument).length > 0) {
        this.studiosService
          .editInstrument(this.studioId, instrumentId, editedInstrument)
          .then((data) => {
            instrument.edit = false;
            this.messageService.add({
              key: 'globalToast',
              severity: 'success',
              summary: 'Successful',
              detail: 'Instrument Edited',
              life: 3000,
            });
          });
      } else {
        instrument.edit = false;
      }
    }
  }

  setStatusInstrument(status = true, instrumentId) {
    this.studiosService
      .editInstrument(this.studioId, instrumentId, { available: status })
      .then((data) => {
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Instrument Status Changed',
          life: 3000,
        });
      });
  }

  saveDetails() {
    return this.studio
      .pipe(take(1))
      .toPromise()
      .then((studioData) => {
        let editedStudio = Object.assign({}, studioData);

        editedStudio = (({
          id,
          name,
          email,
          facebookHandle,
          instagramHandle,
          phoneNumber,
          description,
          landLine,
          coordinates,
          availability,
        }) => ({
          id,
          name,
          email,
          facebookHandle,
          instagramHandle,
          phoneNumber,
          description,
          landLine,
          coordinates,
          availability,
        }))(editedStudio);

        const addressKeys = Object.keys(studioData.address);
        editedStudio.address = {};
        addressKeys.forEach((key) => {
          if (studioData.address[key]) {
            editedStudio.address[key] = studioData.address[key];
          }
        });

        const coordinateKeys = Object.keys(studioData.coordinates);
        editedStudio.coordinates = {};
        coordinateKeys.forEach((key) => {
          if (studioData.coordinates[key]) {
            editedStudio.coordinates[key] = studioData.coordinates[key];
          }
        });

        return this.studiosService.editStudio(editedStudio);
      })
      .then((data) => {
        this.updateLocalData(data);
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Changes saved',
          life: 3000,
        });
        return Promise.resolve(data);
      })
      .catch((err) => {
        this.messageService.add({
          key: 'globalToast',
          severity: 'error',
          summary: 'Error',
          detail: err.error.message,
          life: 3000,
        });
        throw err;
      });
  }

  addNewRoom() {
    const newRoom: any = {};
    newRoom.name = this.newRoomName;
    this.studiosService.createRoom(this.studioId, newRoom).then((data) => {
      this.updateLocalData(data);
      this.newRoomName = null;
      this.newRoomDialog = false;
      this.updateRoomAccordionStates();
      this.messageService.add({
        key: 'globalToast',
        severity: 'success',
        summary: 'Successful',
        detail: 'Room Added',
        life: 3000,
      });
    });
  }

  removeRoom(roomId) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove this room?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.studiosService.removeRoom(this.studioId, roomId).then((data) => {
          this.updateLocalData(data);
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Room Removed',
            life: 3000,
          });
        });
      },
    });
  }

  addNewService(room) {
    const newService: any = {};
    // newService.name = room.newServiceName;

    if (room.newServiceType == null) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'You need to select a service!',
        life: 3000,
      });
      return;
    }

    newService.type = room.newServiceType;
    newService.price = room.newServicePrice;
    this.studiosService
      .createService(this.studioId, room.id, newService)
      .then((data) => {
        this.updateLocalData(data);
        // room.newServiceName = null;
        room.newServiceType = null;
        room.newServicePrice = null;
        this.showNewServiceRow = false;
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Service Added',
          life: 3000,
        });
      });
  }

  removeService(room, serviceId) {
    this.studiosService
      .removeService(this.studioId, room.id, serviceId)
      .then((data) => {
        this.updateLocalData(data);
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Service Removed',
          life: 3000,
        });
        this.buildAvailableServices(room);
      });
  }

  addNewEquipment(room) {
    if (
      room.newEquipmentName === null ||
      room.newEquipmentName.match(/^\s*$/) !== null
    ) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Equipment name is required!',
        life: 3000,
      });
    } else if (
      !room.newEquipmentDescription ||
      room.newEquipmentDescription.match(/^\s*$/) !== null
    ) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Equipment description is required!',
        life: 3000,
      });
    } else {
      const newEquipment: any = {};
      newEquipment.name = room.newEquipmentName;
      newEquipment.category = room.newEquipment?.type;
      newEquipment.brand = room.newEquipmentBrand?.type;
      newEquipment.description = room.newEquipmentDescription;
      this.studiosService
        .createEquipment(this.studioId, room.id, newEquipment)
        .then((data) => {
          room.newEquipmentName = null;
          room.newEquipment = null;
          room.newEquipmentBrand = null;
          room.newEquipmentDescription = null;
          this.showNewEquipmentRow = false;
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Room Equipment Added',
            life: 3000,
          });
        });
    }
  }

  editEquipment(roomId, equipment) {
    if (!equipment.editName) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Equipment name is required!',
        life: 3000,
      });
    } else if (!equipment.editDescription) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Equipment description is required!',
        life: 3000,
      });
    } else {
      const editedEquipment: any = {};
      if (equipment.editName) {
        editedEquipment.name = equipment.editName;
      }
      if (equipment.editDescription) {
        editedEquipment.description = equipment.editDescription;
      }
      this.studiosService
        .editEquipment(this.studioId, roomId, equipment.id, editedEquipment)
        .then((data) => {
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Equipment Edited',
            life: 3000,
          });
          equipment.edit = false;
        });
    }
  }

  removeEquipment(roomId, equipmentId) {
    this.studiosService
      .removeEquipment(this.studioId, roomId, equipmentId)
      .then((data) => {
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Room Equipment Removed',
          life: 3000,
        });
      });
  }

  async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  }

  async imageUpload(event, type, entity, roomId?, roomUploader?) {
    if (type === 'cover') {
      event = { files: [] };
      event.files.push(
        await this.dataUrlToFile(
          this.croppedImage,
          this.profileService
            .getOwnerStudio()
            .name.split(' ')
            .join('_')
            .concat('cover')
            .concat('.jpeg')
        )
      );
    }
    if (entity === 'studio') {
      this.saveDetails().then((data) => {
        this.studiosService
          .addStudioImage(this.studioId, event.files[0], type)
          .then((data) => {
            this.uploadImageDialog = false;
            this.updateLocalData(data);
            this.messageService.add({
              key: 'globalToast',
              severity: 'success',
              summary: 'Successful',
              detail: 'Image Uploaded',
              life: 3000,
            });
          })
          .catch((err) => {
            if (roomUploader) {
              roomUploader.clear();
            }
            this.uploadImageDialog = false;
            const error = err.error;
            if (error.code === 500 && error.message === 'File too large') {
              this.messageService.add({
                key: 'globalToast',
                severity: 'error',
                summary: 'Error',
                detail: 'File is over 4mb!',
                life: 3000,
              });
            }
          });
      });
    }
    if (entity === 'room') {
      this.studiosService
        .addRoomImage(this.studioId, roomId, event.files[0], type)
        .then((data) => {
          this.updateLocalData(data);
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Image Uploaded',
            life: 3000,
          });
        })
        .catch((err) => {
          if (roomUploader) {
            roomUploader.clear();
          }
          const error = err.error;
          if (error.code === 500 && error.message === 'File too large') {
            this.messageService.add({
              key: 'globalToast',
              severity: 'error',
              summary: 'Error',
              detail: 'File is over 4mb!',
              life: 3000,
            });
          }
        });
    }
  }

  removeImage(image, entity, roomId?) {
    if (entity === 'studio') {
      this.studiosService
        .deleteStudioImage(this.studioId, image.id)
        .then((data) => {
          this.updateLocalData(data);
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Image Removed',
            life: 3000,
          });
        });
    }
    if (entity === 'room') {
      this.studiosService
        .deleteRoomImage(this.studioId, roomId, image.id)
        .then((data) => {
          this.updateLocalData(data);
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Image Removed',
            life: 3000,
          });
        });
    }
  }

  editService(roomId, service) {
    const editedService = {
      price: service.editPrice,
    };
    if (service.editPrice || service.editPrice === 0) {
      editedService.price = service.editPrice;
    }
    this.studiosService
      .editService(this.studioId, roomId, service.id, editedService)
      .then((data) => {
        this.updateLocalData(data);
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Service Edited',
          life: 3000,
        });
        service.edit = false;
      });
  }

  hideDialog() {
    this.newRoomDialog = false;
    this.editRoomNameDialog = false;
  }

  editRoomShow(event, room) {
    event.stopPropagation();
    this.roomToEdit = room;
    this.roomToEdit.editedName = room.name;
    this.editRoomNameDialog = true;
  }
  updateRoomAccordionStates() {
    this.studio.pipe(first()).subscribe((studioData) => {
      for (let i = 0; i < studioData.rooms.length; i++) {
        this.activeState[i] = false;
      }
      this.activeState[studioData.rooms.length - 1] = true;
    });
  }

  editRoom() {
    if (
      this.roomToEdit.editedName !== this.roomToEdit.name &&
      this.roomToEdit.editedName.length > 0
    ) {
      this.studiosService
        .editRoom(this.studioId, this.roomToEdit.id, {
          name: this.roomToEdit.editedName,
        })
        .then((data) => {
          this.updateLocalData(data);
          this.roomToEdit = null;
          this.hideDialog();
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Room Edited',
            life: 3000,
          });
        });
    } else {
      this.hideDialog();
    }
  }

  resetNewInstrumentBrand() {
    if (
      !this.newInstrument?.brands.some(
        (brand) => brand.type === this.newInstrumentBrand?.type
      )
    ) {
      this.newInstrumentBrand = null;
    }
  }

  resetNewEquipmentBrand(room: any) {
    if (
      !room.newEquipment?.brands.some(
        (brand) => brand.type === room.newEquipmentBrand?.type
      )
    ) {
      room.newEquipmentBrand = null;
    }
  }

  updateLocalData(studio) {
    this.helperService.saveLocalStorage('studio', studio);
    this.profileService.setOwnerStudio(studio);
  }

  fileSelected(event: any): void {
    if (event.target.files.length > 0) {
      this.imageChangedEvent = event;
      this.uploadImageDialog = true;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
    this.croppedFile = image;
  }
  loadImageFailed() {
    this.uploadImageDialog = false;
    this.messageService.add({
      key: 'globalToast',
      severity: 'error',
      summary: 'Error',
      detail: 'File not supported!',
      life: 3000,
    });
  }

  public selectCountry(event) {
    const selected = this.countries.find(
      (country) => event.value === country.label
    );
    this.phonePrefix = '(+' + selected.phone + ') ';
  }
}
