import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {UserService} from './user.service';
import {ConfirmationService} from "primeng/api";
import { environment } from "../../../environments/environment";
import {InstrumentAndEquipmentService} from "../../common/instruments.equipments.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [ConfirmationService]
})
export class UserComponent implements OnInit {
  public env = environment;
  public userId: string;
  public user: any = {};

  public instruments: any;
  public musicRolesMap;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private instrumentAndEquipmentService: InstrumentAndEquipmentService,
    ) {

    this.musicRolesMap = this.instrumentAndEquipmentService.getMusicRolesMap();
  }

  ngOnInit(): void {

    this.user = this.userService.user$;

    this.route.params.subscribe((params: Params) => {
      this.userId = params.id;
      this.userService.fetchUser(this.userId);
    });


  }

}
