<div class="layout-wrapper layout-slim">
  <div class="layout-topbar">
    <div class="layout-topbar-left">
      <a class="app-name" href="/">
        <img alt="main logo" [src]="'assets/images/bandbeat/bb_logo_header.png'" class="app-logo">
        <span>Bandbeat</span>
      </a>
    </div>
  </div>

  <div class="layout-content-wrapper" style="width:100%;margin-left:0px;">
    <div class="layout-content" style="background:linear-gradient(147.38deg, #050505 99%, #121212 77%) !important">
      <div class="landing-body" style="background:transparent !important; height: 100%">
        <div class="landing-wrapper">
          <div class="header-content grid " style="margin-right: unset;color: #FFFFFF;">
            <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
              <section style="width:100%">
                <h1 class="welcomeMessage" >Welcome to <span class="keyword">Band</span>beat</h1>
                <p class="subtitle mb-4" >Please fill in your email to reset your password</p>

                <div class="p-fluid grid formgrid">
                  <div class="field col-12 md:col-6">
                                        <span class="p-float-label">
                                            <input inputTrim
                                              id="email"
                                              type="text"
                                              pInputText
                                              [(ngModel)]="userEmail"
                                            />
                                            <label for="email">Email</label>
                                        </span>
                  </div>

                  <div class="col-12"></div>
                  <div class="field col-12 md:col-6">
                    <button
                      pButton
                      pRipple
                      label="Request New Password"
                      type="button"
                      class="p-button-raised p-button-rounded p-button-lg"
                      (click)="forgotPass()"
                    ></button>
                  </div>




                </div>


              </section>
            </div>
            <div class="col-12 md:col-4 md:col-offset-1 overflow-hidden">
              <!-- <app-wave-animation></app-wave-animation> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <p-toast
      [style]="{'overflow-wrap' : 'break-word'}"
      [baseZIndex]="5000"
      life="4000"
    ></p-toast>

    <app-footer></app-footer>
  </div>
</div>
