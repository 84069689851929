<div class="grid bandbeatPage">

  <div class="col-12">
      <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left"  *ngIf="enableActions">
          <button
            pButton
            pRipple
            label="New"
            icon="pi pi-plus"
            class="p-button-success mr-2 mb-2"
            (click)="openNew()"
          ></button>
        </ng-template>

        <ng-template pTemplate="right">
          <button
            pButton
            pRipple
            label="Export"
            icon="pi pi-upload"
            class="p-button-success"
            (click)="dt.exportCSV()"
          ></button>
        </ng-template>
      </p-toolbar>
  </div>
  <div class="col-12">
      <h5>Studios</h5>
  </div>
  <div class="col-12">



    <p-table
      #dt
      [value]="(studios | async)?.results"
      [columns]="cols"
      [rows]="10"
      [paginator]="true"
      [globalFilterFields]="['name','email','phoneNumber']"
      [rowHover]="true"
      dataKey="id"
      styleClass="p-datatable-customers"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [showCurrentPageReport]="true"
    >
      <ng-template pTemplate="caption">
        <div class="flex p-flex-column md:flex-row md:justify-content-between table-header">
          <h5 style="margin: auto">Manage Studios</h5>
          <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                          pInputText
                          type="text"
                          (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search..."
                        />
                    </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem" *ngIf="enableActions">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-template ngFor let-col [ngForOf]="cols" >
            <th [pSortableColumn]="col.field">{{col.header}} <p-sortIcon [field]="col.field"></p-sortIcon></th>

          </ng-template>
          <th></th>
        </tr>

        <tr>
          <th *ngIf="enableActions"></th>
          <th>
            <p-columnFilter type="text" field="id" matchMode="contains" [showMenu]="false" [showClearButton]="true"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" field="name" matchMode="contains" [showMenu]="false"></p-columnFilter>
          </th>

          <th>
            <p-columnFilter type="text" field="owner" matchMode="contains" [showMenu]="false"></p-columnFilter>

          </th>

          <th>

            <p-columnFilter field="status" matchMode="equals" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)" placeholder="Any" [showClear]="true">
                  <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>

          <th>
            <p-columnFilter type="text" field="phoneNumber" matchMode="contains" [showMenu]="false"></p-columnFilter>
          </th>
          <th>
            <!--              <p-columnFilter type="date" field="lastLogin" ></p-columnFilter>-->
          </th>

          <th></th>
          <th></th>
        </tr>


      </ng-template>
      <ng-template pTemplate="body" let-studio>
        <tr>
          <td *ngIf="enableActions">
            <p-tableCheckbox [value]="studio"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of cols">
            <span class="p-column-title">{{col.header}}</span>
            <ng-template [ngIf]="col.field === 'lastLogin' || col.field === 'createdAt'">
              {{studio[col.field] | date : 'MM/dd/yyyy'}}
            </ng-template>
            <ng-template [ngIf]="col.field === 'owner'">
              {{studio.owner.email}}
            </ng-template>
            <ng-template [ngIf]="col.field !== 'lastLogin' && col.field !== 'createdAt' && col.field !== 'owner'">
              {{studio[col.field]}}
            </ng-template>
          </td>

          <td>
            <button
              pButton
              pRipple
              icon="pi pi-eye"
              class="p-button-rounded p-button-info mr-2"
              (click)="seeDetails(studio)"
            ></button>
            <button
              *ngIf="studio.status === 'active'"
              pButton
              pRipple
              icon="pi pi-times"
              class="p-button-rounded p-button-danger mr-2"
              (click)="enableStudio(studio)"
            ></button>
            <button
              *ngIf="studio.status === 'inactive'"
              pButton
              pRipple
              icon="pi pi-check"
              class="p-button-rounded p-button-success mr-2"
              (click)="enableStudio(studio)"
            ></button>
            <button
              pButton
              pRipple
              icon="pi pi-pencil"
              [disabled]="!enableActions"
              class="p-button-rounded p-button-success mr-2"
              (click)="editStudio(studio)"
            ></button>
            <button
              pButton
              pRipple
              icon="pi pi-trash"
              [disabled]="!enableActions"
              class="p-button-rounded p-button-warning"
              (click)="deleteStudio(studio)"
            ></button>
          </td>



        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
          In total there are {{ (studios | async)?.results ? (studios | async)?.results.length : 0 }} users.
        </div>
      </ng-template>
    </p-table>




  </div>




</div>

<p-dialog [(visible)]="studioDialog" [style]="{width: '450px'}" header="Studio Details" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <img [src]="'assets/images/sample_studio.png'" style="width:100%;height:auto;" [alt]="studio.image" class="product-image" >
    <div class="field">
      <label for="name">Name</label>
      <input type="text" pInputText id="name" [(ngModel)]="studio.name" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !studio.name">Name is required.</small>
    </div>
    <div class="field">
      <label for="email">Email</label>
      <input type="text" pInputText id="email" [(ngModel)]="studio.email" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !studio.email">Email is required.</small>
    </div>
    <div class="field">
      <label for="label">Label</label>
      <input type="text" pInputText id="label" [(ngModel)]="studio.label" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !studio.label">Label is required.</small>
    </div>
    <div class="field">
      <label for="description">Description</label>
      <input type="text" pInputText id="description" [(ngModel)]="studio.description" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !studio.label">Description is required.</small>
    </div>
    <div class="field">
      <label for="vat">VAT</label>
      <input type="text" pInputText id="vat" [(ngModel)]="studio.vat" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !studio.label">VAT is required.</small>
    </div>
    <div class="field">
      <label for="phoneNumber">Phone Number</label>
      <p-inputNumber
        inputId="phoneNumber"
        placeholder="Phone Number"
        prefix="(+30) "
        [useGrouping]="false"
        [(ngModel)]="studio.phoneNumber"
        [maxlength]="16"
      ></p-inputNumber>
      <small class="p-invalid" *ngIf="submitted && !studio.phoneNumber">Phone Number is required.</small>
    </div>
    <div class="field">
      <label for="email">Address</label>
      <input type="text" pInputText id="address" [(ngModel)]="studio.address" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !studio.address">Address is required.</small>
    </div>


    <div class="field">
      <label >Coordinates</label>
      <div class="grid p-fluid">

        <div class="col-12 md:col-4">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">X</span>
            <input type="text" [(ngModel)]="studio.coordinates.x"  pInputText placeholder="X Coordinates">
          </div>
        </div>

        <div class="col-12 md:col-4">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">Y</span>
            <input type="text" [(ngModel)]="studio.coordinates.y" pInputText placeholder="Y Coordinates">
          </div>
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveStudio()"></button>
  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
