import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCalendar'
})
export class FilterCalendarPipe implements PipeTransform {

  transform(value: any, args: string[]): unknown {
    const returnValue = Object.assign({} , value);
    if (returnValue && returnValue.events) {
      returnValue.events = returnValue.events.filter((val) => args.includes(val.booking.room.id))
    }
    return returnValue;
  }

}
