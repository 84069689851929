import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Observable} from 'rxjs';
import {HelperService} from '../common/helper.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BookingsService} from './bookings.service';
import {map} from "rxjs/operators";

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
  providers: [ConfirmationService],
})
export class BookingsComponent implements OnInit {
  bookings: Observable<any[]>;

  cols: any[] = [
    { field: 'filterLabel', header: 'Label' },
    { field: 'bookingType', header: 'Type' },
    { field: 'studioName', header: 'Studio' },
    { field: 'studioVat', header: 'VAT' },
    { field: 'shortId', header: 'Booking Id' },
    { field: 'bookingDate', header: 'Booking Date' },
    { field: 'bookerName', header: 'Booker' },
    { field: 'bookedBy', header: 'Booked By' },
    { field: 'status', header: 'Status' },
    { field: 'reported', header: 'Reported' },
    { field: 'paymentMethod', header: 'Payment Method' },
    { field: 'cancelledAt', header: 'Canceled At' },
    { field: 'createdAt', header: 'Created At' },
    { field: 'totalPrice', header: 'Total Price' },
    { field: 'shouldBeRefunded', header: 'Needs Refund' },
  ];

  selectedColumns: any[] = [
    { field: 'studioName', header: 'Studio' },
    { field: 'shortId', header: 'Booking Id' },
    { field: 'bookingDate', header: 'Booking Date' },
    { field: 'status', header: 'Status' },
    { field: 'bookingType', header: 'Type' },
    { field: 'paymentMethod', header: 'Payment Method' },
  ];

  bookingTypes: any = [
    { label: 'Offline', value: 'offline' },
    { label: 'Band', value: 'band' },
    { label: 'Solo', value: 'solo' },
  ];

  refundTypes: any = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  reportTypes: any = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  bookingStatuses: any = [
    { label: 'Planning', value: 'planning' },
    { label: 'Planning Complete', value: 'planning-complete' },
    { label: 'Pending Confirmation', value: 'pending-confirmation' },
    { label: 'Studio Confirmed', value: 'studio-confirmed' },
    { label: 'Studio Declined', value: 'studio-declined' },
    { label: 'Studio Canceled', value: 'studio-canceled' },
    { label: 'User Canceled', value: 'user-canceled' },
  ];

  loading = true;
  enableActions = false;

  constructor(
    private bookingsService: BookingsService,
    private helperService: HelperService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.bookings = this.bookingsService.bookings$.pipe(
      map((bookings) => {
        bookings.forEach((booking) => {
          booking.studioName = booking.studio.label;
          booking.studioVat = booking.studio.vat;
          booking.bookerName = booking.booker.email;
          if (booking.bookingType === 'band') {
            booking.bookedBy = booking.team.name;
          } else {
            booking.bookedBy = booking.user.email;
          }
          booking.filterLabel =
            (booking.service ? booking.service.type : 'undefined') +
            ' ' +
            this.helperService.formatDate(booking.bookingDate, 'dd/MM/yyyy');
        });
        return bookings;
      })
    );
    this.bookingsService.fetchBookings().then((data) => {
      this.loading = false;
    });
  }

  seeBooking(booking) {
    this.router.navigate([booking.id], { relativeTo: this.route });
  }
}
