import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BandsService} from '../../bands.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {filter, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {BookingsService} from '../../../bookings/bookings.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-band-bookings',
  templateUrl: './band-bookings.component.html',
  styleUrls: ['./band-bookings.component.scss']
})
export class BandBookingsComponent implements OnInit, OnChanges {

  @Input() bandId: string;
  public env = environment;

  bookings: any;
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bandsService: BandsService,
    private bookingsService: BookingsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.bookings = this.bandsService.bookings$.pipe(
      filter(el => el.results),
      map((bookings) => bookings.results.map((booking) => {
        booking.studio.studioLogo = booking.studio.media.find(e => e.metadata.type === 'logo');

        return booking;
      }) )
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.bandId = changes.bandId.currentValue;
    if (changes.hasOwnProperty('bandId') && changes.bandId.currentValue) {
      this.bandsService.fetchBandBookings(this.bandId)
        .then((bookings) => {
          this.loading = false;
        });
    }
  }


  deleteBooking(booking): void {
    this.bookingsService.deleteBooking(booking.id)
      .then((data) => {
        this.bandsService.fetchBandBookings(this.bandId);
      });
  }

  addMember(): void {

  }

  seeDetails(booking) {
    this.router.navigate(['bookings', booking.id], { relativeTo: this.route });
  }

}
