<p-dataView #dv
            [value]="(invites | async)"
            [paginator]="true"
            [rows]="9"
            filterBy="invite.user.name"
            [loading]="loading"
            layout="list">
  <ng-template pTemplate="header">

  </ng-template>

  <ng-template let-invite pTemplate="listItem">
    <div class="col-12">
      <div class="product-list-item">



        <img
          [src]="invite.user.hasOwnProperty('avatar') && invite.user.avatar ? invite.user.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
          [alt]="invite.user.name"/>
        <div class="product-list-detail">
          <div class="product-name">{{invite.user.name}}</div>
          <div>
            <i style="margin-left: 0.5em;" class="pi pi-phone product-category-icon"></i><span class="product-category">{{invite.user.phoneNumber}}</span>
            <i style="margin-left: 0.5em;" class="pi pi-at product-category-icon"></i><span class="product-category">{{invite.user.email}}</span>
            <br>
            <i style="margin-left: 0.5em;" class="pi pi-calendar-plus product-category-icon"></i><span class="product-category">{{invite.user.lastLogin | date : 'dd/MM/yyyy'}}</span>
            <br>
            <p-tag [value]="'Invited at '+ (invite.createdAt  | date : 'dd/MM/yyyy') " icon="pi pi-calendar"></p-tag>

          </div>

        </div>

        <div class="product-list-action">

          <span *ngIf="invite.status === 'pending'" [class]="'product-badge status-instock'">PENDING</span>
          <span *ngIf="invite.status === 'rejected'" [class]="'product-badge status-outofstock'">REJECTED</span>


          <br>


          <p-button
            *ngIf="enableActions"
            pRipple
            styleClass="p-button-danger mr-2 mb-2"
            icon="pi pi-minus-circle"
            label="Delete Invite"
            (click)="deleteInvite(invite)"
          ></p-button>

        </div>


      </div>
    </div>
  </ng-template>



</p-dataView>




