<p-card [style]="{border: '1px solid #938F99', borderRadius : 12}" styleClass="p-card-shadow">

  <ng-template *ngIf="equipment.description" pTemplate="header">
    <div class="grid align-items-center">
      <div class="col-2">
        <app-filled-icon pTooltip = "{{equipment.category | titleCaseExtendedPipe}}" tooltipPosition="top" [tnIcon]="equipmentMap.get(equipment.category)"></app-filled-icon>
      </div>
      <div class="col-10"><h5 style="margin: auto">{{equipment.brand | titleCaseExtendedPipe}}</h5>
        <div>{{equipment.name}}</div>
      </div>
    </div>
  </ng-template>


  <div *ngIf = "!equipment.description" class="grid grid-nogutter align-items-center">
    <div class="col-2">
      <app-filled-icon pTooltip = "{{equipment.category | titleCaseExtendedPipe}}" tooltipPosition="top" [tnIcon]="equipmentMap.get(equipment.category)"></app-filled-icon>
    </div>
    <div class="col-10"><h5 style="margin: auto">{{equipment.brand | titleCaseExtendedPipe}}</h5>
      <div>{{equipment.name}}</div>
    </div>
  </div>


  <p>{{equipment.description}}</p>
</p-card>
