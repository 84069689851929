<div class="layout-dashboard">
    <div class="grid">
      <div class="col-12 md:col-6 lg:col-3">

        <app-overview-box
          [title]="'USERS SIGNED UP'"
          [subtitle]="'304'"
          [color]="'blue'"
          [icon]="'users'"
        >
        </app-overview-box>

      </div>
      <div class="col-12 md:col-6 lg:col-3">

        <app-overview-box
          [title]="'STUDIOS UP'"
          [subtitle]="'10'"
          [color]="'yellow'"
          [icon]="'map'"
        >
        </app-overview-box>

      </div>
      <div class="col-12 md:col-6 lg:col-3">

        <app-overview-box
          [title]="'TEAMS CREATED'"
          [subtitle]="'30'"
          [color]="'green'"
          [icon]="'directions'"
        >
        </app-overview-box>

      </div>
      <div class="col-12 md:col-6 lg:col-3">

        <app-overview-box
          [title]="'APPOINTMENTS BOOKED'"
          [subtitle]="'930'"
          [color]="'purple'"
          [icon]="'comments'"
        >
        </app-overview-box>

      </div>

        <div class="col-12 lg:col-6">
            <div class="card widget-overview-chart h-full">
                <div class="card-header">
                    <span class="font-bold">Acquisition Overview</span>
                    <p-dropdown [options]="overviewWeek" [(ngModel)]="selectedOverviewWeek" optionLabel="name"
                                (onChange)="changeOverviewWeek($event); overview.refresh()"></p-dropdown>
                </div>

                <div class="graph mb-3">
                    <p-chart #overview type="bar" [data]="overviewChart" [options]="overviewChartOptions"
                             id="overview-chart"></p-chart>
                </div>

                <div class="graph-footer flex align-items-center justify-content-end">
                    <button pButton type="button" label="Organic"
                            class="p-button-outlined p-button-rounded p-button-sm font-bold mr-3 py-1 first-data-button"></button>
                    <button pButton type="button" label="Referral"
                            class="p-button-outlined p-button-rounded p-button-sm font-bold py-1 second-data-button"></button>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
            <div class="card p-0 widget-people h-full relative">
                <div class="header font-bold py-4 px-3">People</div>
                <div class="overflow-y-auto" style="max-height: calc(100% - 100px)">
                    <div class="mb-4 px-3 flex align-items-center justify-content-start">
                        <p-avatar label="BS" size="large" shape="circle" styleClass="text-base font-bold"
                                  [style]="{'background-color':'rgba(101, 214, 173, 0.1)', 'color': '#27AB83', 'border': '1px solid #65D6AD'}"></p-avatar>
                        <div class="ml-3">
                            <span class="name block">Brooklyn Simmons</span>
                            <span class="position block">Manager at Mitsubishi</span>
                        </div>
                    </div>
                    <div class="mb-4 px-3 flex align-items-center justify-content-start">
                        <p-avatar label="LA" size="large" shape="circle" styleClass="text-base font-bold"
                                  [style]="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></p-avatar>
                        <div class="ml-3">
                            <span class="name block">Leslie Alexander</span>
                            <span class="position block">Customer Success at General Electric</span>
                        </div>
                    </div>
                    <div class="mb-4 px-3 flex align-items-center justify-content-start">
                        <p-avatar label="JB" size="large" shape="circle" styleClass="text-base font-bold"
                                  [style]="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></p-avatar>
                        <div class="ml-3">
                            <span class="name block">Jerome Bell</span>
                            <span class="position block">Mario Carrier at Nintendo</span>
                        </div>
                    </div>
                    <div class="mb-4 px-3 flex align-items-center justify-content-start">
                        <p-avatar label="WW" size="large" shape="circle" styleClass="text-base font-bold"
                                  [style]="{'background-color':'rgba(43, 176, 237, 0.1)', 'color': '#127FBF', 'border': '1px solid #2BB0ED'}"></p-avatar>
                        <div class="ml-3">
                            <span class="name block">Wade Warren</span>
                            <span class="position block">Reliability Engineer at eBay</span>
                        </div>
                    </div>
                    <div class="mb-4 px-3 flex align-items-center justify-content-start">
                        <p-avatar label="AB" size="large" shape="circle" styleClass="text-base font-bold"
                                  [style]="{'background-color':'rgba(255, 155, 155, 0.1)', 'color': '#CF1124', 'border': '1px solid #FF9B9B'}"></p-avatar>
                        <div class="ml-3">
                            <span class="name block">Annette Black</span>
                            <span class="position block">Delivery Woman at Pizza Hut</span>
                        </div>
                    </div>
                    <div class="mb-4 px-3 flex align-items-center justify-content-start">
                        <p-avatar label="AF" size="large" shape="circle" styleClass="text-base font-bold"
                                  [style]="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></p-avatar>
                        <div class="ml-3">
                            <span class="name block">Albert Flores</span>
                            <span class="position block">Team Leader at Insomniac Games</span>
                        </div>
                    </div>
                </div>
                <div
                    class="footer cursor-pointer flex align-items-center justify-content-center p-3 absolute bottom-0 w-full">
                    <span class="font-bold mr-2">SEE ALL</span>
                    <i class="pi pi-chevron-right"></i>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
            <div class="card widget-profile-completion">
                <div class="flex justify-content-center">
                    <img
                        [src]="'assets/layout/images/dashboard/completion-graph-' + (app.colorScheme === 'light' ? 'light' : 'dark') + '.svg'"
                        alt="graph" class="w-auto md:w-full mb-5">
                </div>

                <div class="mb-4 flex align-items-center justify-content-start">
                    <p-avatar icon="pi pi-user-edit" size="large" shape="circle" styleClass="text-base font-bold"
                              [style]="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></p-avatar>
                    <div class="ml-3">
                        <span class="label block">Add your personal information</span>
                        <span class="action block font-bold">Go Profile Edit</span>
                    </div>
                </div>

                <div class="mb-4 flex align-items-center justify-content-start">
                    <p-avatar icon="pi pi-send" size="large" shape="circle" styleClass="text-base font-bold"
                              [style]="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></p-avatar>
                    <div class="ml-3">
                        <span class="label block">Verify your phone</span>
                        <span class="action block font-bold">Send Verification SMS</span>
                    </div>
                </div>

                <div class="mb-4 flex align-items-center justify-content-start">
                    <p-avatar icon="pi pi-video" size="large" shape="circle" styleClass="text-base font-bold"
                              [style]="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></p-avatar>
                    <div class="ml-3">
                        <span class="label block">Verify your Face ID</span>
                        <span class="action block font-bold">Upload Pictures</span>
                    </div>
                </div>

                <div class="mb-4 flex align-items-center justify-content-start">
                    <p-avatar icon="pi pi-briefcase" size="large" shape="circle" styleClass="text-base font-bold"
                              [style]="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></p-avatar>
                    <div class="ml-3">
                        <span class="label block">Give permissions for personal data</span>
                        <span class="action block font-bold">View Agreement</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-4">
            <div class="card widget-image-stats relative">
                <div class="card-header">
                    <span class="font-bold">Sales Channels</span>
                    <div class="header-right-section flex align-items-center">
                        <button pButton type="button" icon="pi pi-angle-left"
                                class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-2 p-2"></button>
                        <button pButton type="button" icon="pi pi-angle-right"
                                class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-3 p-2"></button>
                        <span class="font-bold">1</span><span>/5</span>
                    </div>
                </div>
                <img src="../../../../assets/layout/images/dashboard/product-watch.png" alt="product" class="w-full my-4">

                <div class="flex align-items-center justify-content-between mb-2">
                    <span class="font-bold">Online Marketplaces</span>
                    <span>%40</span>
                </div>
                <p-progressBar value="40" [showValue]="false" styleClass="orange-bar mb-4"></p-progressBar>

                <div class="flex align-items-center justify-content-between mb-2">
                    <span class="font-bold">Distributors</span>
                    <span>%89</span>
                </div>
                <p-progressBar value="89" [showValue]="false" styleClass="green-bar mb-4"></p-progressBar>

                <div class="flex align-items-center justify-content-between mb-2">
                    <span class="font-bold">Affiliate</span>
                    <span>%82</span>
                </div>
                <p-progressBar value="82" [showValue]="false" styleClass="green-bar"></p-progressBar>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
            <div class="card widget-tasks">
                <div class="card-header block mb-3 px-2">
                    <span class="block font-bold mb-3">Upcoming Tasks</span>
                    <span class="block font-bold text-2xl mb-4">Design Promo Artwork for Garden</span>
                    <span class="badge block w-min font-bold mb-4 px-2 py-1 border-1 border-solid">Design</span>
                    <span class="clock"><i class="pi pi-clock mr-1"></i>Jan 15 . <span
                        class="font-bold">4 days 36 mins</span></span>
                </div>
                <p class="px-2 mb-8">Sit amet nulla facilisi morbi tempus iaculis. Dolor magna eget est lorem ipsum
                    dolor sit. Turpis egestas pretium aenean pharetra magna ac placerat vestibulum lectus. </p>
                <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                    <div class="footer-left flex align-items-center">
                        <button pButton type="button" icon="pi pi-angle-left"
                                class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-2 p-2 w-2rem h-2rem"></button>
                        <button pButton type="button" icon="pi pi-angle-right"
                                class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-3 p-2 w-2rem h-2rem"></button>
                        <span class="font-bold">4</span><span>/ 52</span>
                    </div>
                    <div class="footer-right">
                        <p-avatarGroup styleClass="w-9rem justify-content-evenly">
                            <p-avatar image="assets/demo/images/avatar/amyelsner.png" shape="circle"></p-avatar>
                            <p-avatar image="assets/demo/images/avatar/asiyajavayant.png" shape="circle"></p-avatar>
                            <p-avatar image="assets/demo/images/avatar/onyamalimba.png" shape="circle"></p-avatar>
                            <p-avatar image="assets/demo/images/avatar/ionibowcher.png" shape="circle"></p-avatar>
                            <p-avatar image="assets/demo/images/avatar/xuxuefeng.png" shape="circle"></p-avatar>
                        </p-avatarGroup>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
            <div class="card widget-ratings">
                <div class="card-header font-bold mb-4">Ratings</div>
                <p-tabView orientation="left">
                    <p-tabPanel header="Tab Title I">
                        <table class="w-full">
                            <tbody>
                            <tr>
                                <td class="w-4">Icarus</td>
                                <td class="w-1"><span class="font-bold">3.9</span></td>
                                <td class="w-2">
                                    <div
                                        class="flex align-items-center justify-content-between px-2 py-1 warning-badge">
                                        <i class="pi pi-circle-off"></i>
                                        <span class="font-bold ml-1">0.2</span>
                                    </div>
                                </td>
                                <td class="w-4">
                                    <div class="flex align-items-center justify-content-center low-tag p-1">
                                        <span class="font-bold">LOW PRIORITY</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-4">Omega</td>
                                <td class="w-1"><span class="font-bold">2.8</span></td>
                                <td class="w-2">
                                    <div
                                        class="flex align-items-center justify-content-between px-2 py-1 warning-badge">
                                        <i class="pi pi-circle-off"></i>
                                        <span class="font-bold ml-1">0.9</span>
                                    </div>
                                </td>
                                <td class="w-4">
                                    <div class="flex align-items-center justify-content-center high-tag p-1">
                                        <span class="font-bold">HIGH PRIORITY</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-4">Woofer</td>
                                <td class="w-1"><span class="font-bold">4.6</span></td>
                                <td class="w-2">
                                    <div
                                        class="flex align-items-center justify-content-between px-2 py-1 success-badge">
                                        <i class="pi pi-arrow-up"></i>
                                        <span class="font-bold ml-1">1.7</span>
                                    </div>
                                </td>
                                <td class="w-4">
                                    <div class="flex align-items-center justify-content-center high-tag p-1">
                                        <span class="font-bold">HIGH PRIORITY</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-4">Alphine</td>
                                <td class="w-1"><span class="font-bold">9.0</span></td>
                                <td class="w-2">
                                    <div class="flex align-items-center justify-content-between px-2 py-1 danger-badge">
                                        <i class="pi pi-arrow-down"></i>
                                        <span class="font-bold ml-1">4.5</span>
                                    </div>
                                </td>
                                <td class="w-4">
                                    <div class="flex align-items-center justify-content-center low-tag p-1">
                                        <span class="font-bold">LOW PRIORITY</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-4">Doctrine</td>
                                <td class="w-1"><span class="font-bold">6.1</span></td>
                                <td class="w-2">
                                    <div
                                        class="flex align-items-center justify-content-between px-2 py-1 warning-badge">
                                        <i class="pi pi-circle-off"></i>
                                        <span class="font-bold ml-1">7.0</span>
                                    </div>
                                </td>
                                <td class="w-4">
                                    <div class="flex align-items-center justify-content-center high-tag p-1">
                                        <span class="font-bold">HIGH PRIORITY</span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </p-tabPanel>
                    <p-tabPanel header="Tab Title II">
                        <table class="w-full">
                            <tbody>
                            <tr>
                                <td class="w-4">Omega</td>
                                <td class="w-1"><span class="font-bold">2.8</span></td>
                                <td class="w-2">
                                    <div
                                        class="flex align-items-center justify-content-between px-2 py-1 warning-badge">
                                        <i class="pi pi-circle-off"></i>
                                        <span class="font-bold ml-1">0.9</span>
                                    </div>
                                </td>
                                <td class="w-4">
                                    <div class="flex align-items-center justify-content-center high-tag p-1">
                                        <span class="font-bold">HIGH PRIORITY</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-4">Alphine</td>
                                <td class="w-1"><span class="font-bold">9.0</span></td>
                                <td class="w-2">
                                    <div class="flex align-items-center justify-content-between px-2 py-1 danger-badge">
                                        <i class="pi pi-arrow-down"></i>
                                        <span class="font-bold ml-1">4.5</span>
                                    </div>
                                </td>
                                <td class="w-4">
                                    <div class="flex align-items-center justify-content-center low-tag p-1">
                                        <span class="font-bold">LOW PRIORITY</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-4">Icarus</td>
                                <td class="w-1"><span class="font-bold">3.9</span></td>
                                <td class="w-2">
                                    <div
                                        class="flex align-items-center justify-content-between px-2 py-1 warning-badge">
                                        <i class="pi pi-circle-off"></i>
                                        <span class="font-bold ml-1">0.2</span>
                                    </div>
                                </td>
                                <td class="w-4">
                                    <div class="flex align-items-center justify-content-center low-tag p-1">
                                        <span class="font-bold">LOW PRIORITY</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-4">Woofer</td>
                                <td class="w-1"><span class="font-bold">4.6</span></td>
                                <td class="w-2">
                                    <div
                                        class="flex align-items-center justify-content-between px-2 py-1 success-badge">
                                        <i class="pi pi-arrow-up"></i>
                                        <span class="font-bold ml-1">1.7</span>
                                    </div>
                                </td>
                                <td class="w-4">
                                    <div class="flex align-items-center justify-content-center high-tag p-1">
                                        <span class="font-bold">HIGH PRIORITY</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-4">Doctrine</td>
                                <td class="w-1"><span class="font-bold">6.1</span></td>
                                <td class="w-2">
                                    <div
                                        class="flex align-items-center justify-content-between px-2 py-1 warning-badge">
                                        <i class="pi pi-circle-off"></i>
                                        <span class="font-bold ml-1">7.0</span>
                                    </div>
                                </td>
                                <td class="w-4">
                                    <div class="flex align-items-center justify-content-center high-tag p-1">
                                        <span class="font-bold">HIGH PRIORITY</span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-6">
            <div class="card widget-timeline">
                <div class="card-header font-bold mb-4">Timeline</div>
                <div class="flex mb-2 p-3 pb-0">
                    <div class="flex flex-column align-items-center">
                        <span
                            class="flex align-items-center justify-content-center border-circle p-2 timeline-icon timeline-icon-1">
                            <i class="pi pi-twitter"></i>
                        </span>
                        <div class="h-full timeline-line-1" style="width: 2px; min-height: 4rem"></div>
                    </div>

                    <div class="ml-3 w-full">
                        <div class="flex align-items-center justify-content-between timeline-event-header mb-3 mt-2">
                            <span><span class="font-bold">Tom Mathias</span> retweeted a tweet about your following keyword.</span>
                            <span class="font-bold subtext">46m</span>
                        </div>
                        <div class="p-3"
                             [style]="{'background-color':app.colorScheme === 'dark'? 'rgba(227, 248, 255, 0.1)' :'rgba(227, 248, 255, 0.5)', 'border-radius': '6px'}">
                            <div class="flex align-items-center justify-content-between mb-2">
                                <img src="../../../../assets/demo/images/avatar/xuxuefeng.png" alt="avatar" class="w-2rem">
                                <p-avatarGroup styleClass="w-6rem justify-content-evenly">
                                    <p-avatar image="assets/demo/images/avatar/amyelsner.png" shape="circle"></p-avatar>
                                    <p-avatar image="assets/demo/images/avatar/asiyajavayant.png"
                                              shape="circle"></p-avatar>
                                    <p-avatar image="assets/demo/images/avatar/onyamalimba.png"
                                              shape="circle"></p-avatar>
                                </p-avatarGroup>
                            </div>
                            <span class="font-bold">Jeff Atwood</span>
                            <p class="subtext text-sm line-height-3 mb-3" style="max-width: 28rem">Hooray! I suck at
                                hardware but I was able to get the pi zero W, plus usb case, plus two color e-ink hat,
                                to work!</p>

                            <p-progressBar [value]="50" [showValue]="false" styleClass="w-6"></p-progressBar>
                        </div>
                    </div>
                </div>
                <div class="flex mb-2 p-3 pb-0">
                    <div class="flex flex-column align-items-center">
                        <span
                            class="flex align-items-center justify-content-center border-circle p-2 timeline-icon timeline-icon-2">
                            <i class="pi pi-star"></i>
                        </span>
                        <div class="h-full timeline-line-2" style="width: 2px; min-height: 2rem"></div>
                    </div>

                    <div class="ml-3 w-full">
                        <div class="flex align-items-center justify-content-between timeline-event-header mb-3 mt-2">
                            <span><span class="font-bold">Scott Wesley</span> starred NS0021 on product list.</span>
                            <span class="font-bold subtext">1h 51m</span>
                        </div>
                    </div>
                </div>
                <div class="flex mb-4 p-3 pb-0 shadow-2" style="border-radius: 10px">
                    <div class="flex flex-column align-items-center">
                        <span
                            class="flex align-items-center justify-content-center border-circle p-2 timeline-icon timeline-icon-3">
                            <i class="pi pi-link"></i>
                        </span>
                        <div class="h-full timeline-line-3" style="width: 2px; min-height: 4rem"></div>
                    </div>

                    <div class="ml-3 w-full">
                        <div class="flex align-items-center justify-content-between timeline-event-header mb-3 mt-2">
                            <span><span class="font-bold">Jorge Gomez</span> shared design files on cloud.</span>
                            <span class="font-bold subtext">12h 14m</span>
                        </div>
                        <div class="flex align-items-center justify-content-start mb-2">
                            <img src="../../../../assets/layout/images/dashboard/bg-detail.svg" alt="bg" class="w-2rem">
                            <span class="font-bold ml-2">01.Verona-Remastered.fig</span>
                        </div>
                        <div class="flex align-items-center justify-content-start mb-3">
                            <img src="../../../../assets/layout/images/dashboard/bg-detail.svg" alt="bg" class="w-2rem">
                            <span class="font-bold ml-2">02.Verona-Marketing.fig</span>
                        </div>
                    </div>
                </div>
                <div class="timeline-footer flex align-items-center justify-content-center cursor-pointer">
                    <i class="pi pi-chevron-down p-1"></i>
                    <span class="font-bold ml-2">See More</span>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6">
            <div class="card widget-graph p-5">
                <div class="card-header font-bold mb-5">NASDAQ 100</div>

                <p-chart type="line" [data]="chartData" [options]="chartOptions" id="nasdaq-chart"></p-chart>
            </div>
        </div>
    </div>
</div>
