<div class="grid" *ngIf="band | async as bandData">
  <div class="col-8 col-offset-2">

      <img
        [style]="{'max-height': '500px' , 'width' : '150px', 'height':'auto'} "
        class="m-5"
        [src]="bandData.avatar ? bandData.avatar['link'] : 'assets/images/sample_band.png'"
        alt="User Image"
      >

  </div>
  <div class="col-8 col-offset-2">
      <div class="grid">
        <div class="col-4">
          Name : {{bandData.name}}
        </div>
        <div class="col-4">
          Nr of Members : {{bandData.members.length}}
        </div>
        <div class="col-4">
          Status : {{bandData.status}}
        </div>
        <div class="col-12">
          {{bandData.description}}
        </div>
      </div>
  </div>

  <div class="col-12">
      <p-tabView styleClass="tabview-custom">
        <p-tabPanel>

          <ng-template pTemplate = "header">
            <i class="pi pi-calendar"></i>
            <span>Members</span>
          </ng-template>

          <app-band-members
            [bandId]="bandData.id"
          ></app-band-members>

          <app-band-invites
            [bandId]="bandData.id"
          ></app-band-invites>


        </p-tabPanel>
        <p-tabPanel>

          <ng-template pTemplate = "header">
            <i class="pi pi-calendar"></i>
            <span>Bookings</span>
          </ng-template>


          <app-band-bookings
            [bandId]="bandData.id"
          ></app-band-bookings>

        </p-tabPanel>
        <p-tabPanel >

          <ng-template pTemplate = "header">
            <i class="pi pi-user"></i>
            <span>Reviews</span>
          </ng-template>

        </p-tabPanel>


      </p-tabView>
  </div>


</div>


<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
