import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserService} from '../user.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {filter, map} from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import {BandsService} from "../../../bands/bands.service";
import {HelperService} from "../../../common/helper.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-user-bands',
  templateUrl: './user-bands.component.html',
  styleUrls: ['./user-bands.component.scss'],
})
export class UserBandsComponent implements OnInit, OnChanges {


  @Input() userId: string;
  public env = environment;

  public bandDialog = false;
  public band: any = {};
  public submitted = false;

  public invitesAndBands: any = {};

  public loading = true;
  enableActions = false;


  constructor(
    private userService: UserService,
    private bandsService: BandsService,
    private helperService: HelperService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
    ) { }

  ngOnInit(): void {

    this.invitesAndBands = combineLatest(
      this.userService.invites$,
      this.userService.bands$,
      (one, two) => {
        return {invites : one.results, bands : two};
      }
    ).pipe(
      filter((data) => (data.bands && data.invites)),
      map((data) => {
        this.loading = false;
        data.bands.map(band => {
          band.dataType = 'band'
          const bandAvatar = band.media ? band.media.find(e => e.metadata.type === 'avatar') : undefined;
          if (bandAvatar) {
            band.avatar = bandAvatar;
          }
          band.filterName = band.name;
          return band;
        });
        data.invites.map(invite => {
          invite.dataType = 'invite'
          invite.team.bandAvatar = invite.team.media ? invite.team.media.find(e => e.metadata.type === 'avatar') : undefined;
          invite.filterName = invite.team.name;
          return invite;
        });
        const allItems = [
          ...data.invites.filter(invite => invite.status === 'pending'),
          ...data.bands
        ];
        return allItems;
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userId = changes.userId.currentValue;
    if (changes.hasOwnProperty('userId') && changes.userId.currentValue) {
      this.userService.fetchUserInvites(this.userId);
      this.userService.fetchUserBands(this.userId);
    }
  }

  acceptInvite(invite) {
    this.userService.acceptUserInvite(this.userId , invite.id).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Accepted', life: 3000});
      this.userService.fetchUserBands(this.userId);
    });
  }

  declineInvite(invite) {

    this.userService.declineUserInvite(this.userId , invite.id).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'error', summary: 'Successful', detail: 'Invite Declined', life: 3000});
    });
  }

  leaveBand(band) {

    if (band.members.length === 1) {
      this.confirmationService.confirm({
        message: 'You are the last member of the band! Are you sure you want to leave?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.userService.leaveUserBand(this.userId , band.id).then((data) => {
            this.messageService.add({
              key: 'globalToast', severity: 'error', summary: 'Successful', detail: 'You left the band', life: 3000});
          });
        }
      });
    } else {
      this.userService.leaveUserBand(this.userId , band.id).then((data) => {
        this.messageService.add({
          key: 'globalToast', severity: 'error', summary: 'Successful', detail: 'You left the band', life: 3000});
      });
    }
  }

  saveBand() {
    this.submitted = true;

    if (this.band.name && this.band.name.trim()) {

      this.bandsService.addBand( this.band )
        .then((result) => {
          this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Band Created', life: 3000});
          this.bandDialog = false;
          this.band = {};
          this.userService.fetchUserBands(this.userId);
        });

    }
  }
  hideDialog() {
    this.band = {};
    this.bandDialog = false;
    this.submitted = false;
  }

  refresh() {
    this.loading = true;
    this.userService.fetchUserInvites(this.userId);
    this.userService.fetchUserBands(this.userId);
  }

  seeBand(band) {
    this.helperService.navigateTo('dashboard/admin/bands/' + band.id);
  }

}
