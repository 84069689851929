import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {BookingsService} from '../bookings.service';
import {filter, map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  public bookingId: string;
  public booking: any;

  constructor(
    private route: ActivatedRoute,
    private bookingsService: BookingsService,
  ) { }


  ngOnInit(): void {

    this.booking = this.bookingsService.booking$
      .pipe(
        filter((booking) => Object.keys(booking).length !== 0 )
      );

    this.route.params.subscribe((params: Params) => {
      this.bookingId = params.bookingId;
      this.bookingsService.fetchBooking(this.bookingId);
    });
  }

}
