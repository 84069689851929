<div >
  <p-card  *ngIf="booking | async as bookingData">
    <ng-template pTemplate="title">
      Booking : {{bookingData.label}}
    </ng-template>
    <ng-template
      [ngIf]="bookingData.bookingType === 'band'"
      pTemplate="subtitle">
      {{bookingData.team?.name}} {{bookingData.user?.name}} @ {{bookingData.studio.label}}
    </ng-template>
    <ng-template pTemplate="content">

      <div class="grid">

        <div
          [ngClass]="{'col-12 md:col-12 lg:col-12' : bookingData.bookingType === 'band' , 'col-6 md:col-6 lg:col-6' : bookingData.bookingType !== 'band' }"
        >
<!--          <div class="card widget-blog p-0">-->
<!--            <div class="card-header relative">-->
<!--            </div>-->
<!--            <div class="card-content p-4">-->
              <div class="grid">
                <div
                  [ngClass]="{'col-12' : bookingData.bookingType !== 'band' , 'col-6' : bookingData.bookingType === 'band' }"
                >
                  <img
                    src="assets/images/sample_booking.png"
                    alt="blog-bg"
                    [ngClass]="{'w-6' : bookingData.bookingType === 'band', 'w-12' : bookingData.bookingType !== 'band'}"
                    class="h-full"
                  >
                </div>
                <div
                  [ngClass]="{'col-12' : bookingData.bookingType !== 'band' , 'col-6' : bookingData.bookingType === 'band' }"
                >
                  <div >
                    <p class="font-bold"><i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i> Label : {{bookingData.label}} </p>
                    <p class="font-bold"><i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i> Date : {{bookingData.bookingDate}} </p>
                    <p class="font-bold"><i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i> Notes :  </p>
                    <p
                      *ngFor="let note of bookingData.notes"
                      class="font-bold"
                    > - {{note.message}}</p>
                  </div>
                </div>
              </div>
<!--            </div>-->
<!--          </div>-->

        </div>

        <div class="col-6" *ngIf="bookingData.bookingType === 'band'">
          <div class="grid">
            <div class="col-12 md:col-12 lg:col-12 ">
<!--              <div class="card widget-blog p-0">-->
<!--                <div class="card-header relative">-->
                  <img
                    [src]="bookingData.team.avatar ? bookingData.team.avatar.link : 'assets/images/sample_band.png'"
                    alt="blog-bg"
                    class="w-full h-full"
                  >
                  <div class="header-right-section flex align-items-center absolute right-0 top-0 p-3">
                    <button pButton type="button" icon="pi pi-angle-left" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold text-white border-white mr-2 p-2"></button>
                    <button pButton type="button" icon="pi pi-angle-right" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold text-white border-white mr-3 p-2"></button>
                    <span class="font-bold text-white">{{bookingData.team.media.length + 1}}</span><span class="text-white">/{{bookingData.team.media.length + 1}}</span>
                  </div>
<!--                </div>-->
<!--                <div class="card-content p-4">-->
                  <div class="flex align-items-center justify-content-between mb-3">
                    <span class="font-bold">{{bookingData.team.name}} <i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i></span>
                  </div>
                  <p class="mb-5">{{bookingData.team.description}} </p>
<!--                </div>-->
<!--              </div>-->
            </div>
            <div class="col-12 md:col-12 lg:col-12">
<!--              <div class="card widget-teamstatus">-->
                <div class="card-header font-bold pl-2">BandBeats</div>
                <div class="flex flex-wrap align-items-start justify-content-around pt-5">
                  <div class="flex flex-column align-items-center justify-content-center mb-2">
                    <a
                      (click)="showDialog()"
                      style="cursor: pointer"
                      class="new-button border-circle flex align-items-center justify-content-center mb-2">
                      <i class="pi pi-plus"></i>
                    </a>
                    <span class="font-bold text-sm">Add New</span>
                  </div>
                  <div
                    *ngFor="let member of bookingData.members"
                    class="flex flex-column align-items-center justify-content-center mb-2"
                  >
                    <div class="member border-solid border-circle flex align-items-center justify-content-center relative">
                      <img
                        [src]="member.avatar ? member.avatar.link : 'assets/images/bandbeat/sample_user_logo.png'"
                        width="44"
                        height="44"
                        class="border-circle"
                      >
                      <span class="member-badge absolute">
                    <i
                      *ngIf="member.vote === 'pending'"
                      class="pi pi-times text-sm"
                      style="color:red;background-color:black"
                    ></i>
                    <i
                      *ngIf="member.vote === 'accepted'"
                      style="color:#27AB83"
                      class="pi pi-check text-sm"
                    ></i>
                  </span>
                    </div>
                    <span class="font-bold">{{member.name}}</span>
                    <span class="subtext text-sm">{{member.email}}</span>
                  </div>
                </div>
<!--              </div>-->
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="grid">

            <div class="col-12 md:col-12 lg:col-12 ">
<!--              <div class="card widget-blog p-0">-->
<!--                <div class="card-header relative">-->
                  <img
                    [src]="bookingData.studio.avatar ? bookingData.studio.avatar.link : 'assets/images/sample_studio.png'"
                    alt="blog-bg"
                    class="w-full h-full"
                  >
                  <div class="header-right-section flex align-items-center absolute right-0 top-0 p-3">
                    <button pButton type="button" icon="pi pi-angle-left" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold text-white border-white mr-2 p-2"></button>
                    <button pButton type="button" icon="pi pi-angle-right" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold text-white border-white mr-3 p-2"></button>
                    <span class="font-bold text-white">{{bookingData.studio.media?.length + 1}}</span><span class="text-white">/{{bookingData.studio.media?.length + 1}}</span>
                  </div>
<!--                </div>-->
<!--                <div class="card-content p-4">-->
                  <div class="flex align-items-center justify-content-between mb-3">
                    <span class="font-bold">{{bookingData.studio.label}} <i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i></span>
                  </div>
                  <p class="mb-5">{{bookingData.studio.description}} </p>

                  <div
                    *ngIf="bookingData.service && bookingData.service"
                    class="flex align-items-center justify-content-between mb-3"
                  >
                    <span class="font-bold">{{bookingData.service.name}} - {{bookingData.service.price}}<i class="pi pi-money-bill ml-3 text-xl" style="color: #27AB83"></i></span>
                  </div>
                  <div
                    *ngIf="bookingData.service && bookingData.room && bookingData.room"
                    class="flex align-items-center justify-content-between mb-3"
                  >
                    <span class="font-bold">{{bookingData.room.name}}<i class="pi pi-home ml-3 text-xl" style="color: #27AB83"></i></span>
                  </div>

                  <span
                    *ngIf="bookingData.service && bookingData.room && bookingData.room.equipment"
                    class="font-bold mb-5">Room Equipment :  </span>

                  <div
                    *ngIf="bookingData.service && bookingData.room && bookingData.room.equipment"
                    class="flex align-items-center justify-content-between mb-3"
                  >
                    <span
                      *ngFor="let equipment of bookingData.room.equipment"
                    >{{equipment.name}} - {{equipment.description}}</span>
                  </div>

                  <div class="flex align-items-center justify-content-between">
                    <div class="subtext">
                      <i class="pi pi-envelope mr-1"></i>
                      <span>{{bookingData.studio.email}}</span>
                      <i class="pi pi-phone ml-4 mr-1"></i>
                      <span>{{bookingData.studio.phoneNumber}}</span>
                      <i class="pi pi-map ml-4 mr-1"></i>
                      <span>{{bookingData.studio.address}}</span>
                    </div>
                  </div>

<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>



      </div>




    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-around">
      </div>
    </ng-template>
  </p-card>
</div>
