import { Pipe, PipeTransform } from '@angular/core';
import { addHours, formatDistance, differenceInHours, format } from 'date-fns'

@Pipe({
  name: 'durationParser'
})
export class DurationParserPipe implements PipeTransform {

  transform(value: string, duration: number): string {
    const startDate = new Date(value);
    const endDate = addHours(startDate , duration);

    return format(startDate , 'HH:mmaaa') + ' - ' + format(endDate , 'HH:mmaaa')
  }

}
