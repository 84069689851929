import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {StudiosService} from '../studios.service';
import {filter, map, skip, tap} from "rxjs/operators";
import { environment } from "../../../environments/environment";
import {HelperService} from "../../common/helper.service";

@Component({
  selector: 'app-studio',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.scss']
})
export class StudioComponent implements OnInit {
  public env = environment;
  public enableActions = false;
  public studioBookings: any;
  public studioReviews: any;
  public contractValidity: any;
  public contractMessage: any;
  public bookingsCols = [];
  public studioCols = [];
  public bookingsStatus = [];

  public studio: any;
  public studioId: string;

  constructor(
    private route: ActivatedRoute,
    private studioService: StudiosService,
    private helperService: HelperService,
  ) { }
  ngOnInit(): void {

    this.studio = this.studioService.studio$.pipe(
      filter(std => Object.keys(std).length !== 0),
      map((std) => {
        std.avatar = std.media.find(md => md.metadata.type === 'logo');
        std.cover = std.media.find(md => md.metadata.type === 'cover');

        if ( !std.hasOwnProperty('address') ) {
          std.address = {
            street : null,
            number : null,
            zip : null,
            city : null,
            country : null
          };
        }

        return std;
      })
    );

    this.studioBookings = this.studioService.bookings$;
    this.studioReviews = this.studioService.reviews$;

    this.contractValidity = this.studioService.contractValidity$.pipe(
      skip(1),
      map((data) => {
        this.contractMessage = [];
        this.contractMessage.push(
          {
            severity: data.valid ? 'success' : 'error',
            summary: data.valid? 'Reviewed & Accepted the latest contract.' : 'Hasnt Accepted our updated contract!',
            detail: data.valid ? 'Signed on ' + this.helperService.formatDate(data.contract.createdAt , 'yyyy-MM-dd') : 'A new version has been uploaded"'
          }
        )

        return data;
      })
    );

    this.route.params.subscribe((params: Params) => {
      this.studioId = params.id;
      this.studioService.fetchStudio(this.studioId, true);
      this.studioService.fetchStudioBookings(this.studioId);
      this.studioService.fetchStudioReviews(this.studioId);
      this.studioService.fetchContractsValidity(this.studioId);
    });



    this.bookingsCols = [
      {field: 'id', header: 'Id'},
      {field: 'shortId', header: 'Short Id'},
      {field: 'status', header: 'Status'},
      {field: 'bookingType', header: 'Type '},
      {field: 'duration', header: 'Duration'},
      {field: 'totalPrice', header: 'Price'},

      {field: 'bookingDate', header: 'Date'}
    ];


  }

  toggleVisibility(review) {
    this.studioService.toggleReview(review.id , !review.visibility);
  }

}
