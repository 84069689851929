import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BookingsService} from "../../bookings.service";

@Component({
  selector: 'app-booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.scss']
})
export class BookingInfoComponent implements OnInit {

  bookingData;

  bookDate;
  bookDuration = 1;
  bookLabel = '';
  bookNote = '';
  bookVoting = false;

  loading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bookingsService: BookingsService
  ) {

  }
  ngOnInit(): void {
    this.bookingData = this.bookingsService.getNewBookingData();
    this.initData();
    this.loading = false;
  }

  initData(): void {
    if (this.bookingData.hasOwnProperty('info')) {
      if (this.bookingData.info.bookLabel) {
        this.bookLabel = this.bookingData.info.bookLabel;
      }
      if (this.bookingData.info.bookVoting) {
        this.bookVoting = this.bookingData.info.bookVoting;
      }
      if (this.bookingData.info.bookDate) {
        this.bookDate = new Date(this.bookingData.info.bookDate);
      }
      if (this.bookingData.info.bookDuration) {
        this.bookDuration = this.bookingData.info.bookDuration;
      }

    }
  }


  nextPage() {
    const infoData: any = {};
    infoData.bookDate = this.bookDate;
    infoData.bookLabel = this.bookLabel;
    infoData.bookNote = this.bookNote;
    infoData.bookDuration = this.bookDuration;
    infoData.bookVoting = this.bookVoting;
    this.bookingsService.setNewBookingData(infoData , 'info');
    this.router.navigate(
      ['confirm'],
      { relativeTo: this.route.parent}
    );
  }
  previousPage() {
    this.router.navigate(
      ['studio'],
      { relativeTo: this.route.parent}
    );
  }
}
