import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { NotificationsService } from "../../notifications/notifications.service";
import { AuthenticationService } from "../authentication.service";
import { UserService } from "../../users/user/user.service";
import { ContractsService } from "../contract/contracts.service";
import { MessageService } from "primeng/api";
import { HelperService } from "../../common/helper.service";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  public env = environment;
  countries: any[];
  currencies: any[];

  @Input() businessName: string;
  @Output() businessNameChange = new EventEmitter<any>();

  @Input() studioVat: string;
  @Output() studioVatChange = new EventEmitter<any>();

  @Input() businessCountry: any;
  @Output() businessCountryChange = new EventEmitter<any>();

  @Output() businessCityChange = new EventEmitter<any>();
  @Input() businessCity: any;

  @Input() businessZip: any;
  @Output() businessZipChange = new EventEmitter<any>();

  @Input() businessNumber: any;
  @Output() businessNumberChange = new EventEmitter<any>();

  @Input() businessStreet: any;
  @Output() businessStreetChange = new EventEmitter<any>();

  @Input() businessCurrency: any;
  @Output() businessCurrencyChange = new EventEmitter<any>();

  @Output() proceedToContract = new EventEmitter<any>();
  @Output() backTo = new EventEmitter<any>();

  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private contractsService: ContractsService,
    private messageService: MessageService,
    private helperService: HelperService
  ) {
    this.countries = this.helperService.getCountriesData();
  }
  ngOnInit(): void {}

  public goBack() {
    this.updateValues();
    this.backTo.emit('user');
  }

  private updateValues() {
    this.businessNameChange.emit(this.businessName);
    this.businessCityChange.emit(this.businessCity);
    this.businessCountryChange.emit(this.businessCountry);
    this.businessZipChange.emit(this.businessZip);
    this.businessStreetChange.emit(this.businessStreet);
    this.businessNumberChange.emit(this.businessNumber);
    this.businessCurrencyChange.emit(this.businessCurrency);
    this.studioVatChange.emit(this.studioVat);
  }

  public proceed() {
    this.updateValues();

    if (
      this.businessNumber &&
      this.businessStreet &&
      this.businessZip &&
      this.businessCountry &&
      this.businessCity &&
      this.businessName &&
      this.businessCurrency &&
      this.studioVat
    ) {
      this.proceedToContract.emit('contract');
    } else {
      this.messageService.clear();
      this.messageService.add(
        {
          severity: 'error',
          summary: 'Wrong data',
          detail: 'All the fields are required!'
        });
    }

  }

  public selectCountry(event) {
    const selected = this.countries.find((country) => this.businessCountry === country.label);
    this.businessCurrency = selected.currency;
  }

  public selectCurrency(event) {
    const selected = this.countries.find(country => this.businessCurrency === country.currency);
  }

}
