import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {Observable} from 'rxjs';
import {HelperService} from '../common/helper.service';
import {BandsService} from './bands.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-bands',
  templateUrl: './bands.component.html',
  styleUrls: ['./bands.component.scss'],
  providers: [ConfirmationService]
})
export class BandsComponent implements OnInit {

  bands: Observable<any[]>;

  band: any;

  submitted: boolean;

  sortOptions: SelectItem[];

  sortKey: string;

  sortOrder: number;

  sortField: string;

  bandDialog: boolean;

  loading = true;


  constructor(private bandsService: BandsService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private helperService: HelperService,
              private router: Router ,
              private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.bands = this.bandsService.bands$;
    this.bandsService.fetchBands().then((data) => {
      this.loading = false;
    });

    this.sortOptions = [
      {label: 'Name A-Z ', value: 'name'},
      {label: 'Name Z-A ', value: '!name'}
    ];
  }


  onSortChange(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }


  openNew() {
    this.band = {
    };
    this.submitted = false;
    this.bandDialog = true;
  }

  deleteBand(band) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected band?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.bandsService.deleteBand(band.id)
          .then((result) => {
            this.messageService.add({
              key: 'globalToast',severity: 'success', summary: 'Successful', detail: 'Band Deleted', life: 3000});
          });
      }
    });
  }

  saveBand() {

    this.submitted = true;
    if (this.band.name.trim()) {
      this.bandsService.addBand(this.band)
        .then((result) => {
          this.messageService.add({
            key: 'globalToast',severity: 'success', summary: 'Successful', detail: 'Band Created', life: 3000});
          this.bandDialog = false;
          this.band = {};
        });
    }
  }


  hideDialog() {
    this.bandDialog = false;
    this.submitted = false;
  }

  customSort(event) {
/*    console.log('======');
    console.log(event);
    console.log('======');*/
  }

  seeBand(band) {
    this.router.navigate([band.id], { relativeTo: this.route });
  }

}
