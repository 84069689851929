import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../users/user/user.service';
import {ProfileService} from '../../../common/profile.service';
import {BookingsService} from '../../bookings.service';

@Component({
  selector: 'app-booking-type',
  templateUrl: './booking-type.component.html',
  styleUrls: ['./booking-type.component.scss']
})
export class BookingTypeComponent implements OnInit {

  bookingTypes = [
    {name: 'Solo', code: 'solo'},
    {name: 'Band', code: 'band'}
  ];
  bookingType = 'solo';

  availableBands = [];
  selectedBand;

  availableMembers;
  selectedMembers;

  userId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private profileService: ProfileService,
    private bookingsService: BookingsService
  ) {

  }

  ngOnInit(): void {

    this.userId = this.profileService.getUserData().id;

    const stepData = this.bookingsService.getNewBookingData();
    if (stepData.type) {
      if (stepData.type.bookingType) {
        this.bookingType = stepData.type.bookingType;
      }
      if (stepData.type.selectedBand) {
        this.selectedBand = stepData.type.selectedBand;
        if (this.selectedBand.id) {
          this.availableMembers = this.selectedBand.band.members.map((member) => {
            return {name: member.user.name , id: member.id, user: member.user};
          });
        }
      }
      if (stepData.type.selectedMembers) {
        this.selectedMembers = stepData.type.selectedMembers;
      }
    }

    this.userService.fetchUserBands(
      this.userId
    ).then((userbands: any) => {
      this.availableBands = userbands.results.map((band) => {
        return {name : band.name , id: band.id, band};
      });
      this.availableBands.unshift({name: 'Select a Band' , id: null});
    });
  }

  selectBand(event) {
    this.selectedMembers = [];
    this.availableMembers = [];
    if (this.selectedBand.id) {
      this.availableMembers = this.selectedBand.band.members.map((member) => {
        return {name: member.user.name , id: member.id, user: member.user};
      });
      this.selectedMembers.push(
        this.availableMembers.find(mbr => mbr.user.id === this.userId).user
      )
    }
  }

  nextPage() {
    const typeData: any = {};
    typeData.bookingType = this.bookingType;
    typeData.selectedBand = this.selectedBand;
    typeData.selectedMembers = this.selectedMembers;

    this.bookingsService.setNewBookingData(typeData , 'type');
    this.router.navigate(['dashboard/newbooking/studio']);
  }

}
