import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../users/user/user.service';
import {ProfileService} from '../../../common/profile.service';
import {StudiosService} from '../../../studios/studios.service';
import {BookingsService} from '../../bookings.service';

@Component({
  selector: 'app-booking-studio',
  templateUrl: './booking-studio.component.html',
  styleUrls: ['./booking-studio.component.scss']
})
export class BookingStudioComponent implements OnInit {

  availableStudios = [];
  selectedStudio;

  availableServices = [];
  selectedService;

  availableRooms = [];
  selectedRoom;

  loading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private studiosService: StudiosService,
    private bookingsService: BookingsService
  ) {

  }

  ngOnInit(): void {
    this.fetchStudios();

    this.loading = false;
  }

  fetchStudios() {
    this.studiosService.fetchStudios()
      .then((studios: any) => {
        this.availableStudios = studios.results.map((studio) => {
          return {name : studio.label , id: studio.id, studio};
        });
        if (
          this.bookingsService.getNewBookingData().studio &&
          this.bookingsService.getNewBookingData().studio.selectedStudio
        ) {
          this.selectedStudio = this.availableStudios
            .find(
              (studio) => studio.id === this.bookingsService.getNewBookingData().studio.selectedStudio.id
            );
          return this.selectStudio(null);
        }
        return studios;
      });
  }

  selectStudio(event) {
    return Promise.resolve().then(
      () => {
        this.availableServices = [];
        this.selectedService = undefined;
        if (this.selectedStudio.id) {
          this.availableServices = this.selectedStudio.studio.services.map((service) => {
            return {name: service.name , id: service.id, service};
          });
          this.availableServices.unshift({name: 'Other' , id: null});

          if (
            this.bookingsService.getNewBookingData().studio &&
            this.bookingsService.getNewBookingData().studio.selectedService &&
            this.bookingsService.getNewBookingData().studio.selectedService.id
          ) {
            this.selectedService = this.availableServices
              .find((service) => service.id === this.bookingsService.getNewBookingData().studio.selectedService.id );
            this.selectService(null);
          }

        }
      }

    );
  }

  selectService(event) {
    if (
      this.selectedService.service &&
      this.selectedService.service.hasOwnProperty('rooms')
    ) {
      this.availableRooms = this.selectedService.service.rooms
        .map(
          roomId => this.selectedStudio.studio.rooms
            .find((room) => room.id === roomId)
        ).map(room => ({name: room.name , id: room.id , room}));
      this.availableRooms.unshift({name: 'Other' , id: null});

      if (
        this.bookingsService.getNewBookingData().studio &&
        this.bookingsService.getNewBookingData().studio.selectedRoom &&
        this.bookingsService.getNewBookingData().studio.selectedRoom.id
      ) {
        this.selectedRoom = this.availableRooms.find((room) => room.id === this.bookingsService.getNewBookingData().studio.selectedRoom.id );
        this.selectRoom(null);
      }

    }
  }

  selectRoom(event) {
/*    console.log(event);
    console.log(this);*/
  }


  nextPage() {
    const studioData: any = {};
    studioData.selectedStudio = this.selectedStudio;
    studioData.selectedService = this.selectedService;
    studioData.selectedRoom = this.selectedRoom;

    this.bookingsService.setNewBookingData(studioData , 'studio');
    this.router.navigate(
      ['info'],
      { relativeTo: this.route.parent}
    );
  }
  previousPage() {
    this.router.navigate(
      ['type'],
      { relativeTo: this.route.parent}
    );
  }


}
