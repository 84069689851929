import { Component, OnInit } from '@angular/core';
import {UsersService} from './users.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Observable} from 'rxjs';
import {HelperService} from '../common/helper.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [ConfirmationService]
})
export class UsersComponent implements OnInit {

  enableActions = false;
  userDialog: boolean;

  users: Observable<any[]>;

  user: any;

  selectedUsers: any[];

  submitted: boolean;

  cols: any[];

  roles: any = [
    {label: 'Admins', value: 'admin'},
    {label: 'Users', value: 'user'},
    {label: 'Partners', value: 'partner'}
  ];

  constructor(
    private usersService: UsersService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private helperService: HelperService,
    private router: Router ,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.cols = [
      {field: 'id', header: 'Id'},
      {field: 'name', header: 'Name'},
      {field: 'role', header: 'Role'},
      {field: 'email', header: 'Email'},
      {field: 'phoneNumber', header: 'Phone Number'},
      {field: 'lastLogin', header: 'Last Login'},

      {field: 'createdAt', header: 'Created'}
    ];

    this.users = this.usersService.users$;
    this.usersService.fetchUsers(100000);
  }


  openNew() {
    this.user = {};
    this.submitted = false;
    this.userDialog = true;
  }

  deleteSelectedUsers( ) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected users?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deletePromises = this.selectedUsers.map(selectedUser => this.usersService.deleteUser(selectedUser.id));
        Promise.all(deletePromises)
          .then((success) => {
            this.selectedUsers = null;
            this.messageService.add({
              key: 'globalToast',severity: 'success', summary: 'Successful', detail: 'Users Deleted', life: 3000});
        });
      }
    });
  }

  deleteUser(user) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected user?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.usersService.deleteUser(user.id)
          .then((result) => {
            this.messageService.add({
              key: 'globalToast',severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000});
          });
      }
    });
  }

  saveUser() {
    this.submitted = true;

    if (this.user.name.trim()) {
      if (this.user.id) {

        this.usersService.editUser(this.user)
          .then((result) => {
            this.messageService.add({
              key: 'globalToast',severity: 'success', summary: 'Successful', detail: 'User Edited', life: 3000});
            this.userDialog = false;
            this.user = {};
          });
      } else {

        this.usersService.addUser(this.user)
          .then((result) => {
            this.messageService.add({
              key: 'globalToast',severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000});
            this.userDialog = false;
            this.user = {};
          });
      }

    }
  }

  hideDialog() {
    this.userDialog = false;
    this.submitted = false;
  }

  editUser(user) {
    this.user = {...user};
    this.userDialog = true;
  }

  seeUser(user) {
    this.router.navigate([user.id], { relativeTo: this.route });
  }

}
