import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { HelperService } from "../common/helper.service";

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  private reviews: BehaviorSubject<any>;
  public readonly reviews$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private dataStore: {
    reviews: any;
  };

  constructor( private helperService: HelperService ) {

    this.reviews = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.reviews$ = this.reviews.asObservable();


    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.dataStore = {
      reviews: []
    };

  }

  fetchReviews( populate = false ) {
    return this.helperService.getAction('/reviews' , {populate} )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.reviews, data);
        this.reviews.next(data);
        return data;
      });
  }

  editReview( reviewId: string, review: any) {
    return this.helperService.patchAction('/reviews/' + reviewId  , review )
      .toPromise()
      .then((data) => {
        const foundReview = this.dataStore.reviews.results.find(rev => rev.id === reviewId);
        if (foundReview) {
          foundReview.visibility = data.visibility;
          foundReview.rating = data.rating;
          foundReview.note = data.note;
        }
        this.dataStore.reviews.results = [...this.dataStore.reviews.results];
        this.reviews.next(this.dataStore.reviews);
        return data;
      });
  }
}
