<p-card [style]="{border: '1px solid #938F99', borderRadius : 12}" styleClass="p-card-shadow">
  <ng-template pTemplate="header">
    <div class="grid align-items-center">
      <div class="col-2" style="margin: auto">
        <p-avatar
          [image]="item.hasOwnProperty('avatar') && item.avatar ? item.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
          shape="circle" styleClass="mr-2"></p-avatar>
      </div>
      <div class="col-7"><h5 style="margin: auto">{{item.name}}</h5>
        <div>{{item.members.length}} members</div>
      </div>
      <div class="col-3"></div>
    </div>
  </ng-template>

  <p-avatarGroup styleClass="mb-3">
    <ng-template
      ngFor
      let-bandMember
      [ngForOf]="item.members"
      let-i="index">
      <p-avatar pTooltip="{{bandMember.user?.name + ' ' + bandMember.user?.lastName}}" tooltipPosition="bottom"
                [image]="bandMember.user.avatar ? bandMember.user.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                shape="circle"
                size="large"
      ></p-avatar>
    </ng-template>
  </p-avatarGroup>
</p-card>
