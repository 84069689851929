<div class="grid bandbeatPage">
  <div class="col-12">

      <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
<!--          <button-->
<!--            pButton-->
<!--            pRipple-->
<!--            label="New"-->
<!--            icon="pi pi-plus"-->
<!--            class="p-button-success mr-2 mb-2"-->
<!--            (click)="openNew()"-->
<!--          ></button>-->
        </ng-template>

        <ng-template pTemplate="right">
          <button
            pButton
            pRipple
            label="Export"
            icon="pi pi-upload"
            class="p-button-help mb-2"
            (click)="dt.exportCSV()"
          ></button>
        </ng-template>
      </p-toolbar>

  </div>
  <div class="col-12">
      <p-table
        #dt
        [value]="(bookings | async)"
        [loading]="loading"
        [columns]="selectedColumns"
        [rows]="10"
        [paginator]="true"
        [globalFilterFields]="['filterLabel','studioName','bookerName','bookedBy']"
        [rowHover]="true"
        dataKey="id"
        styleClass="p-datatable-customers"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [showCurrentPageReport]="true"
      >
        <ng-template pTemplate="caption">
          <div class="flex p-flex-column md:flex-row md:justify-content-between table-header">
            <h5 class="m-0">Manage Bookings</h5>
            <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                          pInputText
                          type="text"
                          (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search..."
                        />
                    </span>
          </div>


          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                         selectedItemsLabel="{0} columns selected" [style]="{'min-width': '200px'}" placeholder="Choose Columns"></p-multiSelect>

        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>

            <ng-template ngFor let-column [ngForOf]="columns">

              <th *ngIf="column.field === 'filterLabel'" pSortableColumn="filterLabel">Label <p-sortIcon field="filterLabel"></p-sortIcon></th>
              <th *ngIf="column.field === 'bookingType'" pSortableColumn="bookingType">Type <p-sortIcon field="bookingType"></p-sortIcon></th>

              <th *ngIf="column.field === 'studioName'" pSortableColumn="studioName">Studio <p-sortIcon field="studioName"></p-sortIcon></th>
              <th *ngIf="column.field === 'studioVat'" pSortableColumn="studioVat">VAT <p-sortIcon field="studioVat"></p-sortIcon></th>
              <th *ngIf="column.field === 'shortId'" pSortableColumn="shortId">Booking ID <p-sortIcon field="shortId"></p-sortIcon></th>
<!--              <th *ngIf="column.field === 'bookingDate'" pSortableColumn="bookingDate">Booking Date <p-sortIcon field="bookingDate"></p-sortIcon></th>-->
              <th *ngIf="column.field === 'bookerName'" pSortableColumn="bookerName">Booker <p-sortIcon field="bookerName"></p-sortIcon></th>
              <th *ngIf="column.field === 'bookedBy'" pSortableColumn="bookedBy">Booked By <p-sortIcon field="bookedBy"></p-sortIcon></th>
              <th *ngIf="column.field === 'status'" pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
              <th *ngIf="column.field === 'reported'" pSortableColumn="reported">Is Reported <p-sortIcon field="reported"></p-sortIcon></th>


              <th *ngIf="column.field === 'bookingDate'" pSortableColumn="bookingDate">Booking Date <p-sortIcon field="bookingDate"></p-sortIcon></th>
              <th *ngIf="column.field === 'shouldBeRefunded'" pSortableColumn="shouldBeRefunded">Needs Refund <p-sortIcon field="shouldBeRefunded"></p-sortIcon></th>
              <th *ngIf="column.field === 'paymentMethod'" pSortableColumn="paymentMethod">Payment Method <p-sortIcon field="paymentMethod"></p-sortIcon></th>
              <th *ngIf="column.field === 'cancelledAt'" pSortableColumn="cancelledAt">Canceled At <p-sortIcon field="cancelledAt"></p-sortIcon></th>
              <th *ngIf="column.field === 'createdAt'" pSortableColumn="createdAt">Created At <p-sortIcon field="createdAt"></p-sortIcon></th>
              <th *ngIf="column.field === 'totalPrice'" pSortableColumn="totalPrice">Total Price <p-sortIcon field="totalPrice"></p-sortIcon></th>


            </ng-template>

            <th></th>
          </tr>

          <tr>



            <ng-template ngFor let-column [ngForOf]="columns">
              <th>
                <p-columnFilter *ngIf="column.field === 'filterLabel'" type="text" field="filterLabel"></p-columnFilter>

                <p-columnFilter *ngIf="column.field === 'bookingType'" field="bookingType" matchMode="in" [showMenu]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-multiSelect [ngModel]="value" [options]="bookingTypes" (onChange)="filter($event.value)" placeholder="Any" [showClear]="true">
                      <ng-template let-option pTemplate="item">
                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>

                <p-columnFilter *ngIf="column.field === 'studioName'" type="text" field="studioName"></p-columnFilter>

                <p-columnFilter *ngIf="column.field === 'bookerName'" type="text" field="bookerName"></p-columnFilter>

                <p-columnFilter *ngIf="column.field === 'status'" field="status" matchMode="equals" [showMenu]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="bookingStatuses" (onChange)="filter($event.value)" placeholder="Any" [showClear]="true">
                      <ng-template let-option pTemplate="item">
                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>

                <p-columnFilter *ngIf="column.field === 'reported'" field="reported" matchMode="equals" [showMenu]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="reportTypes" (onChange)="filter($event.value)" placeholder="Any" [showClear]="true">
                      <ng-template let-option pTemplate="item">
                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>

                <p-columnFilter *ngIf="column.field === 'shouldBeRefunded'" field="shouldBeRefunded" matchMode="equals" [showMenu]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="refundTypes" (onChange)="filter($event.value)" placeholder="Any" [showClear]="true">
                      <ng-template let-option pTemplate="item">
                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </th>

            </ng-template>

          </tr>


        </ng-template>
        <ng-template pTemplate="body" let-booking let-columns="columns">
          <tr>


            <td *ngFor="let column of columns">
              <ng-template [ngIf]="column.field === 'filterLabel'">
                <span class="p-column-title">Label</span>
                {{booking.filterLabel}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'bookingType'">
                <span class="p-column-title">Type</span>
                {{booking.bookingType }}
              </ng-template>
              <ng-template [ngIf]="column.field === 'studioName'">
                <span class="p-column-title">Studio</span>
                {{booking.studioName}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'bookerName'">
                <span class="p-column-title">Booker</span>
                {{booking.bookerName}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'bookedBy'">
                <span class="p-column-title">Booked By </span>
                <ng-template
                  [ngIf]="booking.bookingType === 'band'"
                >
                  {{booking.bookedBy}}
                </ng-template>
                <ng-template
                  [ngIf]="booking.bookingType === 'solo'"
                >
                  {{booking.bookedBy}}
                </ng-template>
              </ng-template>
              <ng-template [ngIf]="column.field === 'status'">
                <span class="p-column-title">Status</span>
                <span
                  [class]="'product-badge status-' + (booking.status ? 'instock' : 'outofstock')"
                >{{booking.status}}</span>
              </ng-template>
              <ng-template [ngIf]="column.field === 'reported'">
                <span class="p-column-title">Is Reported</span>
                {{booking.reported}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'filterLabel'">
                <span class="p-column-title">Booking Date</span>
                {{booking.bookingDate | date:'dd/MM/yyyy-HH:MM'}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'shouldBeRefunded'">
                <span class="p-column-title">Needs Refund</span>
                {{booking.shouldBeRefunded}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'cancelledAt'">
                <span class="p-column-title">Canceled At</span>
                {{booking.cancelledAt | dateParser: 'dd/MM/yyyy-HH:MM'}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'createdAt'">
                <span class="p-column-title">Created At</span>
                {{booking.createdAt | dateParser: 'dd/MM/yyyy-HH:MM'}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'paymentMethod'">
                <span class="p-column-title">Payment Method</span>
                {{booking.paymentMethod}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'totalPrice'">
                <span class="p-column-title">Total Price</span>
                {{booking.totalPrice}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'studioVat'">
                <span class="p-column-title">VAT</span>
                {{booking.studioVat}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'shortId'">
                <span class="p-column-title">Booking Id</span>
                {{booking.shortId}}
              </ng-template>
              <ng-template [ngIf]="column.field === 'bookingDate'">
                <span class="p-column-title">Booking Date</span>
                {{booking.bookingDate | dateParser: 'dd/MM/yyyy-HH:MM'}}
              </ng-template>
            </td>



            <td>
              <button
                *ngIf="enableActions"
                pButton
                pRipple
                icon="pi pi-eye"
                class="p-button-rounded p-button-info mr-2"
                (click)="seeBooking(booking)"
              ></button>

            </td>



          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="flex align-items-center justify-content-between">
            In total there are {{ (bookings | async)?.results ? (bookings | async)?.results.length : 0 }} bookings.
          </div>
        </ng-template>
      </p-table>

  </div>
</div>
