<div class="grid grid-nogutter bandbeatPage" *ngIf="notifications | async as notificationsData">

  <div class="col-12">
    <h2>My notifications</h2>
  </div>

  <div class="col-12 md:col-8">
    <p-toolbar>
      <div class="p-toolbar-group-left">
      </div>

      <div class="p-toolbar-group-right">

        <!--                <p-button-->
        <!--                        label="Refresh"-->
        <!--                        icon="pi pi-refresh"-->
        <!--                        styleClass="p-button-info mr-2"-->
        <!--                        (click)="refresh()"-->
        <!--                ></p-button>-->

        <p-button class = "secondary"
                  label="Mark as read"
                  icon="pi pi-times"
                  (click)="readAllNotifications(notificationsData)"
        ></p-button>

      </div>
    </p-toolbar>
  </div>

  <div class="col-12 md:col-8">


    <p-dataView #dv
                [value]="notificationsData"
                [paginator]="true"
                [rows]="9"
                [loading]="loading"
                layout="list">

      <ng-template let-notification pTemplate="listItem">


        <div class="col-12" [ngClass]="{'unread-notification' : !notification.readAt}">
          <div class="product-list-item">

            <ng-template
              [ngIf]="notification.type === 'bookings'"
            >
              <ng-template
                [ngIf]="(
                                    notification.data.status === 'studio-declined' ||
                                    notification.data.status === 'studio-canceled' ||
                                    notification.data.status === 'studio-confirmed'
                                )"
              >
                <img
                  [src]="notification.data.hasOwnProperty('studioLogo') ? notification.data.studioLogo : 'assets/images/bandbeat/sample_user_logo.png'"
                  [alt]="notification.type"
                />
              </ng-template>
              <ng-template
                [ngIf]="!(
                                    notification.data.status === 'studio-declined' ||
                                    notification.data.status === 'studio-canceled' ||
                                    notification.data.status === 'studio-confirmed'
                                )"
              >
                <img
                  [src]="notification.sender.hasOwnProperty('avatar') ? notification.sender.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                  [alt]="notification.type"
                />
              </ng-template>


            </ng-template>
            <img
              *ngIf="notification.type === 'general'"
              [src]="'assets/images/bandbeat/sample_user_logo.png'"
              [alt]="notification.type"
            />
            <img
              *ngIf="notification.type === 'invites'"
              [src]="notification.sender.hasOwnProperty('avatar') ? notification.sender.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
              [alt]="notification.type"
            />
            <img
              *ngIf="
                                    notification.type !== 'bookings' &&
                                    notification.type !== 'invites' &&
                                    notification.type !== 'general'
                                "
              [src]="'assets/images/bandbeat/sample_user_logo.png'"
              [alt]="notification.type"
            />
            <div class="product-list-detail">
              <div *ngIf="notification.type == 'bookings'">

                <ng-template
                  [ngIf]="notification.data.category === 'newBookingNotification'"
                >
                  <ng-template
                    [ngIf]="notification.data.status === 'pending-confirmation' || !notification.data.status"
                  >
                    <span
                      *ngIf="!notification.data.team"
                      style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>
                    <span
                      *ngIf="notification.data.team"
                      style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.data.teamName}}</span>
                    <span> requested a new </span>
                    <span class="keyword">{{notification.data.serviceType}}</span>
                    <span>.</span>
                  </ng-template>
<!--                  <ng-template-->
<!--                    [ngIf]="notification.data.status === 'studio-confirmed'"-->
<!--                  >-->
<!--                    <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>-->
<!--                    <span> accepted your </span>-->
<!--                    <span class="keyword">session</span>-->
<!--                    <span> request! </span>-->
<!--                  </ng-template>-->
<!--                  <ng-template-->
<!--                    [ngIf]="notification.data.status === 'studio-declined'"-->
<!--                  >-->
<!--                    <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>-->
<!--                    <span> declined your </span>-->
<!--                    <span class="keyword">session</span>-->
<!--                    <span> request! </span>-->
<!--                  </ng-template>-->
                  <ng-template
                    [ngIf]="notification.data.status === 'user-declined' || notification.data.status === 'user-canceled' || notification.data.status === 'user-delete-decline'"
                  >
                    <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>
                    <span> just canceled </span>
                    <span
                      *ngIf="!notification.data.team"
                    >their </span>
                    <span
                      *ngIf="notification.data.team"
                    >{{notification.data.teamName}} </span>
                    <span class="keyword">{{notification.data.serviceType}}</span>
                    <span> booking!</span>
                  </ng-template>
                </ng-template>


              </div>

<!--              <div>-->
<!--                <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>-->
<!--                <span> requested to book a new </span>-->
<!--                <span class="keyword">session</span>-->
<!--                <span>!</span>-->
<!--              </div>-->

              <div *ngIf="notification.type == 'general'">
                <span>{{notification.message}}</span>
              </div>

              <div class="product-description">On {{notification.createdAt | dateParser}}</div>
            </div>
            <div class="product-list-action">
<!--              <p-button-->
<!--                *ngIf="!notification.readAt"-->
<!--                (onClick)="readNotification(notification)"-->
<!--                label="New"-->
<!--                icon="pi pi-circle"-->
<!--                styleClass="p-button p-button-outlined"-->
<!--              ></p-button>-->

              <p-button
                *ngIf="notification.type === 'bookings'"
                icon="pi pi-chevron-circle-right"
                [label]="!notification.readAt ? 'View More *' : 'View More'"
                (click)="accessNotification(notification)"
              ></p-button>

              <p-button
                *ngIf="
                notification.type === 'general' &&
                notification.data &&
                notification.data.category === 'newStudioSignUp'
                "
                icon="pi pi-chevron-circle-right"
                [label]="!notification.readAt ? 'View More *' : 'View More'"
                (click)="accessNotification(notification)"
              ></p-button>
<!--              <div *ngIf="notification.readAt">-->
<!--                <i-->
<!--                  class="pi pi-check-circle product-category-icon product-price"-->
<!--                ></i>-->
<!--                <span-->
<!--                  class="product-category"-->
<!--                >Read At {{notification.readAt.read_at | dateParser}}</span>-->
<!--              </div>-->
            </div>
          </div>
        </div>

      </ng-template>

    </p-dataView>

  </div>



</div>

