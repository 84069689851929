<p-dataView #dv
            [value]="(members | async)"
            [paginator]="true"
            [rows]="9"
            filterBy="team.name"
            [loading]="loading"
            layout="list">
  <ng-template pTemplate="header">

    <div class="flex align-items-center justify-content-between">
      <p-button *ngIf="enableActions" icon="pi pi-plus" styleClass="p-button-success" (onClick)="openNew()"></p-button>
      <span class="p-input-icon-left mb-2 md:mb-0">
                    <i class="pi pi-search"></i>
                    <input type="search" pInputText placeholder="Search by Name" (input)="dv.filter($event.target.value)">
      </span>
      <p-button icon="pi pi-refresh" (onClick)="refresh()"></p-button>
    </div>

  </ng-template>
  <ng-template let-member pTemplate="listItem">
    <div class="col-12">
      <div class="product-list-item">



          <img
            [src]="member.user.hasOwnProperty('avatar') && member.user.avatar ? member.user.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
            [alt]="member.user.name"
          />
        <div class="product-list-detail">
          <div class="product-name">{{member.user.name}}</div>
          <div>
            <i style="margin-left: 0.5em;" class="pi pi-phone product-category-icon"></i><span class="product-category">{{member.user.phoneNumber}}</span>
            <i style="margin-left: 0.5em;" class="pi pi-at product-category-icon"></i><span class="product-category">{{member.user.email}}</span>
            <br>
            <i style="margin-left: 0.5em;" class="pi pi-calendar-plus product-category-icon"></i><span class="product-category">{{member.user.lastLogin | date : 'dd/MM/yyyy'}}</span>

          </div>
          <p-avatarGroup label="P" class="product-price">
            <ng-template ngFor let-musicRole let-i="index" [ngForOf]="member.user.musicRoles">

              <p-chip
                [label]="musicRolesMap.get(musicRole.type)?.label"
                [ngClass]="{'primary-music-role':musicRole.primary}"
                styleClass="custom-chip mr-4 primary"
                class="custom-chip"
                icon="{{musicRolesMap.get(musicRole.type)?.icon}}"
              ></p-chip>
            </ng-template>


          </p-avatarGroup>
        </div>



          <div class="product-list-action">
            <p-button
              *ngIf="enableActions"
              pRipple
              styleClass="p-button-danger mr-2 mb-2"
              icon="pi pi-minus-circle"
              label="Remove User from Band"
              (click)="removeMember(member)"
            ></p-button>

          </div>


      </div>
    </div>
  </ng-template>



</p-dataView>

<p-dialog [(visible)]="memberDialog" [style]="{width: '450px'}" header="New Member Invite" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <img
      [src]="'assets/images/sample_band.png'"
      style="width:100%;height:auto;"
      [alt]="member.image"
      class="product-image" >
    <div class="field">
      <label for="name">Email</label>
      <input type="text" pInputText id="name" [(ngModel)]="memberEmail" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !memberEmail">Email is required.</small>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="inviteMember()"></button>
  </ng-template>
</p-dialog>


