import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HelperService} from '../../common/helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  private user: BehaviorSubject<any>;
  public readonly user$: Observable<any>;

  private invites: BehaviorSubject<any>;
  public readonly invites$: Observable<any>;

  private bookings: BehaviorSubject<any>;
  public readonly bookings$: Observable<any>;

  private bands: BehaviorSubject<any>;
  public readonly bands$: Observable<any>;

  private registrations: BehaviorSubject<any>;
  public readonly registrations$: Observable<any>;

  private ratings: BehaviorSubject<any>;
  public readonly ratings$: Observable<any>;

  private reviews: BehaviorSubject<any>;
  public readonly reviews$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private dataStore: {
    user: any,
    invites: any,
    bands: any,
    bookings: any,
    ratings: any,
    reviews: any
    registrations: any,
  };

  constructor( private helperService: HelperService ) {

    this.user = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.user$ = this.user.asObservable();

    this.invites = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.invites$ = this.invites.asObservable();

    this.bands = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.bands$ = this.bands.asObservable();

    this.bookings = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.bookings$ = this.bookings.asObservable();

    this.ratings = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.ratings$ = this.ratings.asObservable();

    this.reviews = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.reviews$ = this.reviews.asObservable();

    this.registrations = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.registrations$ = this.registrations.asObservable();

    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.dataStore = {
      user: {
      },
      invites: {},
      bands: [],
      bookings: [],
      ratings: {},
      reviews: {},
      registrations: []
    };

  }

  fetchUser( userId: string) {
    return this.helperService.getAction('/users/' + userId )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.user, data);
        this.user.next(data);
        return data;
      });
  }

  getUserExistence( usermail: string) {
    return this.helperService.getAction('/general/checkuserexists', {usermail} )
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  editUser( userId: string, editedUser: any) {
    return this.helperService.patchAction('/users/' + userId , editedUser )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.user, data);
        this.user.next(data);
        return data;
      });
  }

  changePassword( userId: string, password: string, newPassword: string) {
    return this.helperService.patchAction('/users/' + userId + '/profile/password', {password , newPassword})
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.user, data);
        this.user.next(data);
        return data;
      });
  }


  fetchUserRegistrations(userId: string) {

    return this.helperService.getAction('/users/' + userId + '/registrations')
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.registrations, data.results);
        this.registrations.next(this.dataStore.registrations);
        return data;
      });
  }
  addRegistration(userId: string, event: any) {
    return this.helperService.postAction('/users/' + userId + '/registrations', event)
      .toPromise()
      .then((data) => {
        this.dataStore.registrations.push(data);
        this.registrations.next(this.dataStore.registrations);
        return data;
      });
  }
  removeRegistrationByType(userId: string, eventType: string) {
    return this.helperService.deleteAction('/users/' + userId + '/registrations/event/' + eventType)
      .toPromise()
      .then((data) => {
        this.dataStore.registrations = this.dataStore.registrations.filter(reg => reg.event !== eventType);
        this.registrations.next(this.dataStore.registrations);
        return data;
      });
  }



  addInstrument( userId: string, instrument: any ) {
    return this.helperService.postAction('/users/' + userId + '/instruments', instrument)
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.user, data);
        this.user.next(data);
        return data;
      });
  }

  editInstrument( userId: string , instrumentId: string, instrument: any) {
    return this.helperService.patchAction('/users/' + userId + '/instruments/' + instrumentId, instrument)
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.user, data);
        this.user.next(data);
        return data;
      });
  }

  removeInstrument( userId: string , instrumentId: string ) {
    return this.helperService.deleteAction('/users/' + userId + '/instruments/' + instrumentId)
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.user, data);
        this.user.next(data);
        return data;
      });
  }

  fetchUserInvites( userId: string) {

    return this.helperService.getAction('/users/' + userId + '/invites')
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.invites, data);
        this.invites.next(data);
        return data;
      });
  }

  acceptUserInvite( userId: string, inviteId: string ) {
    return this.helperService.postAction('/users/' + userId + '/invites/' + inviteId)
      .toPromise()
      .then((data) => {
        const editedInvite = this.dataStore.invites.results.find(inv => inv.id === inviteId);
        editedInvite.status = data.status;
        this.invites.next(this.dataStore.invites);
        return data;
      });
  }

  declineUserInvite( userId: string, inviteId: string) {
    return this.helperService.deleteAction('/users/' + userId + '/invites/' + inviteId)
      .toPromise()
      .then((data) => {
        const editedInvite = this.dataStore.invites.results.find(inv => inv.id === inviteId);
        editedInvite.status = data.status;
        this.invites.next(this.dataStore.invites);
        return data;
      });
  }

  fetchUserBands( userId: string) {

    return this.helperService.getAction('/users/' + userId + '/teams')
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.bands, data);
        this.bands.next(this.dataStore.bands.results);
        return data;
      });
  }

  leaveUserBand( userId: string, bandId: string) {

    return this.helperService.deleteAction('/users/' + userId + '/teams/' + bandId)
      .toPromise()
      .then((data) => {
        this.dataStore.bands = this.dataStore.bands.filter(bnd => bnd.id !== bandId);
        this.bands.next(this.dataStore.bands);
        return data;
      });
  }

  fetchUserBookings( userId: string ) {
    return this.helperService.getAction('/users/' + userId + '/bookings' )
      .toPromise()
      .then((data) => {
        data.results = data.results.map(el => this.mapInternalData(el));
        this.dataStore.bookings = [ ...data.results ];
        this.bookings.next(data);
        return data;
      });
  }

  fetchUserRatings(userId: string) {
    return this.helperService.getAction('/users/' +userId+ '/ratings')
      .toPromise()
      .then((data) => {
        this.dataStore.ratings = [ ...data.results ];
        this.ratings.next(data);
        return data;
      })
  }

  fetchUserReviews(userId: string) {
    return this.helperService.getAction('/reviews' , {user: userId , populate: true})
      .toPromise()
      .then((data) => {
        this.dataStore.reviews = [ ...data.results ];
        this.reviews.next(data);
        return data;
      })
  }


  mapInternalData( booking: any) {
    if (booking.hasOwnProperty('room') && typeof booking.room === 'string') {
      booking.room = booking.studio.rooms.find((room) => room.id === booking.room);
    }
    if (booking.hasOwnProperty('service') && typeof booking.service === 'string') {
      booking.service = booking.room.services.find((service) => service.id === booking.service);
    }
    if (booking.hasOwnProperty('instruments') && booking.instruments.length > 0) {
      booking.instruments = booking.instruments.map((instrument) => {
        return booking.studio.instruments.find((inst) => inst.id === instrument);
      });
    }
    return booking;
  }


}

