import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../../common/helper.service';
import { StudiosService } from '../../studios/studios.service';
import { BookingsService } from '../../bookings/bookings.service';
import { ProfileService } from '../../common/profile.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { filter, map, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-wizard-studio-media',
  templateUrl: './wizard-studio-media.component.html',
  styleUrls: ['./wizard-studio-media.component.scss'],
})
export class WizardStudioMediaComponent implements OnInit {
  env = environment;
  private studioId;

  public studio;

  public hasInput = { cover: false, logo: false, description: false };

  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedFile: any = '';
  uploadImageDialog = false;

  disableNext = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private helperService: HelperService,
    private studiosService: StudiosService,
    private bookingsService: BookingsService,
    private profileService: ProfileService,
    private messageService: MessageService
  ) {
    this.studio = this.studiosService.studio$.pipe(
      filter((std) => Object.keys(std).length !== 0),
      map((studioData) => {
        this.hasInput.description = !!studioData.description?.length;
        const logo = studioData.media.find(
          (media) => media.metadata.type === 'logo'
        );
        if (logo) {
          studioData.logo = logo['link'];
          this.hasInput.logo = true;
          this.disableNextLogic();
        } else {
          studioData.logo = 'assets/images/bandbeat/sample_studio_logo.png';
        }
        const cover = studioData.media.find(
          (media) => media.metadata.type === 'cover'
        );
        if (cover) {
          studioData.cover = cover['link'];
          this.hasInput.cover = true;
        } else {
          studioData.cover = 'assets/images/bandbeat/sample_studio_logo.png';
        }

        return studioData;
      })
    );
  }

  ngOnInit(): void {
    this.studioId = this.profileService.getOwnerStudio().id;
    this.studiosService.fetchStudio(this.studioId, true);
  }

  async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  }

  async imageUpload(event, type, entity) {
    if (type === 'cover') {
      event = { files: [] };
      event.files.push(
        await this.dataUrlToFile(
          this.croppedImage,
          this.profileService
            .getOwnerStudio()
            .name.split(' ')
            .join('_')
            .concat('cover')
            .concat('.jpeg')
        )
      );
      this.hasInput.cover = true;
      this.disableNextLogic();
    }
    if (type === 'logo') {
      this.hasInput.logo = true;
      this.disableNextLogic();
    }
    if (entity === 'studio') {
      this.studiosService
        .addStudioImage(this.studioId, event.files[0], type)
        .then((data) => {
          this.uploadImageDialog = false;
          this.updateLocalData(data);
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Image Uploaded',
            life: 3000,
          });
        })
        .catch((err) => {
          this.uploadImageDialog = false;
          const error = err.error;
          if (error.code === 500 && error.message === 'File too large') {
            this.messageService.add({
              key: 'globalToast',
              severity: 'error',
              summary: 'Error',
              detail: 'File is over 4mb!',
              life: 3000,
            });
          }
        });
    }
  }

  fileSelected(event: any): void {
    if (event.target.files.length > 0) {
      this.imageChangedEvent = event;
      this.uploadImageDialog = true;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
    this.croppedFile = image;
  }
  loadImageFailed() {
    this.uploadImageDialog = false;
    this.messageService.add({
      key: 'globalToast',
      severity: 'error',
      summary: 'Error',
      detail: 'File not supported!',
      life: 3000,
    });
  }

  saveDetails() {
    return this.studio
      .pipe(take(1))
      .toPromise()
      .then((studioData) => {
        let editedStudio = Object.assign({}, studioData);
        editedStudio = (({ id, description }) => ({ id, description }))(
          editedStudio
        );
        return this.studiosService.editStudio(editedStudio);
      })
      .then((data) => {
        this.updateLocalData(data);
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Changes saved',
          life: 3000,
        });
        return Promise.resolve(data);
      })
      .catch((err) => {
        this.messageService.add({
          key: 'globalToast',
          severity: 'error',
          summary: 'Error',
          detail: err.error.message,
          life: 3000,
        });
        throw err;
      });
  }

  updateLocalData(studio) {
    this.helperService.saveLocalStorage('studio', studio);
    this.profileService.setOwnerStudio(studio);
  }

  textChange(line) {
    this.hasInput.description = !!line?.length;
    this.disableNextLogic();
  }

  previousPage() {
    this.router.navigate(['studio-info'], { relativeTo: this.route.parent });
  }

  public disableNextLogic() {
    return (this.disableNext = !(
      this.hasInput.cover &&
      this.hasInput.logo &&
      this.hasInput.description
    ));
  }

  nextPage(skip = false) {
    if (!skip) {
      this.saveDetails().then((data) => {
        this.router.navigate(['studio-hours'], {
          relativeTo: this.route.parent,
        });
      });
    } else {
      this.router.navigate(['studio-hours'], { relativeTo: this.route.parent });
    }
  }
}
