<div class="layout-topbar" [ngClass]="{'topbarScrolled' : scrolled}">
  <div class="layout-topbar-left">
    <a class="app-name" [routerLink]="['/']">
      <img alt="main logo" [src]="'assets/images/bandbeat/bb_logo_header.png'" class="app-logo">
      <span>Bandbeat</span>
    </a>

<!--    <a href="#" class="menu-button" (click)="appMain.onMenuButtonClick($event)">-->
<!--      <div class="menu-button-icon"></div>-->
<!--    </a>-->

    <ul class="layout-topbar-main-menu" *ngIf="loggedIn && !isAdmin">
      <li>
        <a
          [routerLink]="['/dashboard/partner/bookings']"
          [routerLinkActiveOptions]="{exact: true}"
          routerLinkActive="topbar-active-menuitem-routerlink">Bookings
          <span class="active-bottom"></span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/dashboard/partner/calendar']"
          routerLinkActive="topbar-active-menuitem-routerlink">My Calendar
          <span class="active-bottom"></span>
        </a>
      </li>
    </ul>



  </div>
  <div class="layout-topbar-right layout-topbar-left">
    <ul class="layout-topbar-main-menu" *ngIf="!loggedIn">
      <li>
        <a style="display:flex; align-items:center" [routerLink]="['/login']" routerLinkActive="topbar-active-menuitem-routerlink">Sign In
          <span class="active-bottom"></span>
        </a>
      </li>
      <li>
        <a style="display:flex; align-items:center" [routerLink]="['/signup']" routerLinkActive="topbar-active-menuitem-routerlink">Sign Up
          <span class="active-bottom"></span>
        </a>
      </li>
    </ul>

    <ul class="layout-topbar-actions " *ngIf="loggedIn">

      <li
        *ngIf="!isAdmin && (shouldShowWizard | async)"
        pTooltip="Edit your studio through wizard!"
        tooltipPosition="left"
      >

        <a
          class="notifications-link contract-error"

          [routerLink]="['/dashboard/partner/wizard']"
        >
          <span>
              <i
                class="material-symbols-outlined">video_settings</i>
          </span>
          <span class="active-bottom"></span>
        </a>


      </li>

      <li
        *ngIf="!isAdmin && !(isContractValid | async)"
        pTooltip="Please review our updated contract!"
        tooltipPosition="left"
      >

        <a
          class="notifications-link contract-error"

          [routerLink]="['/dashboard/partner/profile']"
        >
          <span>
              <i
                class="material-symbols-outlined">contract</i>
          </span>
          <span class="active-bottom"></span>
        </a>


      </li>


      <li *ngIf="notifications | async as notificationsData">


        <a
          class="notifications-link"
          [routerLink]="['/dashboard/partner/notifications']"
          routerLinkActive="topbar-active-menuitem-routerlink">
          <!--                    <p-badge-->
          <!--                            size="large"-->
          <!--                            *ngIf="notificationsData && notificationsData.length > 0"-->
          <!--                            [value]="notificationsData.length"-->
          <!--                    ></p-badge>-->
          <span>
              <i
                *ngIf="notificationsData && notificationsData.length > 0"
                pBadge
                [value]="notificationsData.length + ''"
                class="material-icons-outlined">notifications</i>
              <i
                *ngIf="!(notificationsData && notificationsData.length > 0)"
                class="material-icons-outlined">notifications</i>
          </span>
          <span class="active-bottom"></span>
        </a>
      </li>

<!--      <li>-->


<!--        <a-->
<!--          class="notifications-link"-->
<!--          [routerLink]="['/dashboard/partner/notifications']"-->
<!--          routerLinkActive="topbar-active-menuitem-routerlink">-->
<!--          <span> <i class="material-icons-outlined">notifications</i> Notifications</span>-->
<!--          <span class="active-bottom"></span>-->
<!--        </a>-->
<!--      </li>-->

      <li #profile style="border-radius: 50%;border:unset !important;" class="profile-item" [ngClass]="{'active-topmenuitem fadeInDown': appMain.activeTopbarItem === profile}">
        <div class="profile-container" (click)="appMain.onTopbarItemClick($event,profile)">

          <img
            alt="main logo"
            [src]="studioLogo ? studioLogo['link'] : 'assets/images/sample_studio.png'"
            class="app-logo"
          >

          <i
            *ngIf="studio && studio.status === 'active'"
            class="pi pi-check active-studio"
          ></i>
          <i
            *ngIf="studio && studio.status === 'inactive'"
            class="pi pi-pause inactive-studio"
          ></i>

        </div>

        <ul class="profile-menu fadeInDown">

          <li
            *ngFor="let item of userMenu; let i = index;"
          >
            <a [routerLink]="item.link" href="#" (click)="appMain.onTopbarSubItemClick($event)">
              <span>{{item.label}}</span>
            </a>
          </li>

          <li>
            <a href="#" (click)="appMain.onTopbarSubItemClick($event);logoutUser()">
              <span>Log Out</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>


  </div>
</div>
