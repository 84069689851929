<div class="grid bandbeatPage">

  <div class="col-12">
    <h3>Your Calendar</h3>
  </div>

  <div class="col-12 lg:hidden">

    <button
      pButton
      pRipple
      label="Add Unavailable Time"
      icon="pi pi-plus"
      class="
        w-full
        p-button-secondary
        secondary-button
        mr-2
        mb-2
      "
      (click)="showNew(true)"
    ></button>

    <div class="card widget-list">
      <h4>Select Rooms</h4>
      <ng-template ngFor let-child let-i="index" [ngForOf]="availableRooms">

        <div
          style="padding: 12px;"
          class="mb-4 px-3 flex align-items-center justify-content-start">
          <div class="option-title">
            <div class="roomColorBox" style="background:{{child.color}}"></div>
            <span>  {{child.name}} </span>
          </div>
          <div >
            <p-checkbox name="groupname" [value]="child.code" [(ngModel)]="selectedRooms"></p-checkbox>
          </div>
        </div>

      </ng-template>
    </div>

  </div>

  <div class="col-12 lg:col-10">
    <div class="grid">

<!--      <div class="col-12">-->
<!--        <h3>Your Calendar</h3>-->
<!--      </div>-->
      <div class="col-12">
        <full-calendar [options]="(options | filterCalendar: selectedRooms)"></full-calendar>
      </div>
    </div>
  </div>

  <div class="right-panel lg:col-2">

    <button
      pButton
      pRipple
      label="Add Unavailable Time"
      icon="pi pi-plus"
      class="
        w-full
        p-button-secondary
        secondary-button
        mr-2
        mb-2
      "
      (click)="showNew(true)"
    ></button>

    <div class="card widget-list">
      <h4>Rooms</h4>
      <ng-template ngFor let-child let-i="index" [ngForOf]="availableRooms">

        <div
          style="padding: 12px 0 12px 0;"
          class="flex align-items-center justify-content-start">
          <div class="option-title">
            <div class="roomColorBox" style="background:{{child.color}}"></div>
            <span>  {{child.name}} </span>
          </div>
          <div >
            <p-checkbox name="groupname" [value]="child.code" [(ngModel)]="selectedRooms"></p-checkbox>
          </div>
        </div>

      </ng-template>
    </div>

  </div>

</div>

<p-dialog
  [(visible)]="offlineBookingDialog"
  [style]="{width: '450px'}"
  header="Add Unavailable Time"
  [modal]="true"
  styleClass="p-fluid">
  <ng-template pTemplate="content">



    <div class="formgrid grid">
      <div class="field col-12 md:col-12">
        <span class=" p-float-label">

          <p-calendar
            id="datetime" dateFormat="dd/mm/yy"
            [(ngModel)]="bookDate"
            (onSelect)="buildAvailabilityOptions()"
            [minDate]="currentDate"
            [maxDate]="maxDate"
            firstDayOfWeek="1"
          ></p-calendar>
          <label for="datetime">Book Date</label>
        </span>
      </div>


      <div class="field col-12 md:col-12">
        <span class=" p-float-label">
          <p-dropdown
            id="bookTime"
            [options]="availabilityOptions"
            [(ngModel)]="bookTime"
            [autoDisplayFirst]="false"
          ></p-dropdown>
          <label for="bookTime">Book Time</label>
        </span>
      </div>


      <div class="field col-12 md:col-12">
        <span class=" p-float-label">
          <p-dropdown
            inputId="room"
            id="room"
            [options]="availableRoomsForAppointment"
            [(ngModel)]="bookRoom"
            optionLabel="name"
            optionValue="code"
          ></p-dropdown>
          <label for="room">Studio Room</label>
        </span>
      </div>


      <div class="field col-12 md:col-12">
        <span class=" p-float-label">
          <p-inputNumber
            id = "duration"
            [(ngModel)]="duration"
            [step]="1"
            [min]="1"
          ></p-inputNumber>
          <label for="duration">Duration</label>
        </span>
        <p-tag
          styleClass="mr-2 infoTag"
          icon="pi pi-info-circle"
        ></p-tag>
<span>Please select a duration up to 6 hours</span>
      </div>

    </div>


  </ng-template>

  <ng-template pTemplate="footer">

    <button
      pButton
      pRipple
      type="button"
      class=" p-button-danger p-button-text"
      label="Cancel"
      (click)="hideDialog()"
    ></button>

    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="addBooking()"></button>
  </ng-template>
</p-dialog>


<p-dialog
  [(visible)]="bookingDetailDialog"
  [style]="{width: '450px'}"
  header="Booking Details"
  [modal]="true"
  styleClass="p-fluid">

  <app-band-booking-overview
    style = "width: inherit"
    *ngIf="detailedBooking"
    (confirmBookingEvent)="confirmBooking($event.booking,$event.accept)"
    (reportBookingEvent)="reportBooking($event)"
    (newItemEvent)="removeOfflineBooking($event)"
    [detailedBooking] = "detailedBooking"
  ></app-band-booking-overview>

</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
