import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {StudiosService} from "../../studios/studios.service";
import {HelperService} from "../../common/helper.service";
import {ProfileService} from "../../common/profile.service";
import {map} from "rxjs/operators";
import {UserService} from "../../users/user/user.service";

@Component({
  selector: 'app-wizard-studio-rentals',
  templateUrl: './wizard-studio-rentals.component.html',
  styleUrls: ['./wizard-studio-rentals.component.scss']
})
export class WizardStudioRentalsComponent  implements OnInit {

  private userId;
  private studioId;

  public studio;
  studioEquipment: any;

  instruments;
  equipments;
  instrumentBrandMap = new Map<String, any[]>();
  equipmentBrandMap = new Map<String, any[]>();

  instrumentsAndEquipments = [
    {
      type: 'acoustic_&_classical_guitar',
      name: 'Acoustic & Classical Guitars',
      category: [
        'instrument'
      ],
      icon: 'flaticon-026-guitar',
      brands: [
        {
          type: 'yamaha',
          name: 'Yamaha',
        },
        {
          type: 'takamine',
          name: 'Takamine',
        },
        {
          type: 'fender',
          name: 'Fender',
        },
        {
          type: 'epiphone',
          name: 'Epiphone',
        },
        {
          type: 'cort',
          name: 'Cort',
        },
        {
          type: 'ibanez',
          name: 'Ibanez',
        },
        {
          type: 'prs',
          name: 'PRS',
        },
        {
          type: 'gretsch',
          name: 'Gretsch',
        },
        {
          type: 'gibson',
          name: 'Gibson',
        },
        {
          type: 'guild',
          name: 'Guild',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'acoustic_bass_guitar',
      name: 'Acoustic Bass Guitars',
      icon: 'flaticon-026-guitar',
      category: [
        'instrument'
      ],
      brands: [
        {
          type: 'fender',
          name: 'Fender',
        },
        {
          type: 'harley_benton',
          name: 'Harley Benton',
        },
        {
          type: 'warwick',
          name: 'Warwick',
        },
        {
          type: 'höfner',
          name: 'Höfner',
        },
        {
          type: 'ibanez',
          name: 'Ibanez',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'cymbals',
      name: 'Cymbals',
      icon: 'flaticon-009-cymbal',
      category: [
        'instrument'
      ],
      brands: [
        {
          type: 'zildjian',
          name: 'Zildjian',
        },
        {
          type: 'sabian',
          name: 'Sabian',
        },
        {
          type: 'zultan',
          name: 'Zultan',
        },
        {
          type: 'meinl',
          name: 'Meinl',
        },
        {
          type: 'istanbul_agop',
          name: 'Istanbul Agop',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'effects_pedals',
      name: 'Effects Pedals',
      icon: 'flaticon-027-effect',
      category: [
        'instrument'
      ],
      brands: [
        {
          type: 'behringer',
          name: 'Behringer',
        },
        {
          type: 'boss',
          name: 'Boss',
        },
        {
          type: 'dunlop',
          name: 'Dunlop',
        },
        {
          type: 'tc_electronic',
          name: 'TC Electronic',
        },
        {
          type: 'mxr',
          name: 'MXR',
        },
        {
          type: 'mooer',
          name: 'Mooer',
        },
        {
          type: 'electro_harmonix',
          name: 'Electro Harmonix',
        },
        {
          type: 'ibanez',
          name: 'Ibanez',
        },
        {
          type: 'strymon',
          name: 'Strymon',
        },
        {
          type: 'walrus_audio',
          name: 'Walrus Audio',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'electric_bass_guitar',
      name: 'Electric Bass Guitars',
      icon: 'flaticon-003-bass',
      category: [
        'instrument'
      ],
      brands: [
        {
          type: 'fender',
          name: 'Fender',
        },
        {
          type: 'ibanez',
          name: 'Ibanez',
        },
        {
          type: 'cort',
          name: 'Cort',
        },
        {
          type: 'squier',
          name: 'Squier',
        },
        {
          type: 'warwick',
          name: 'Warwick',
        },
        {
          type: 'yamaha',
          name: 'Yamaha',
        },
        {
          type: 'esp',
          name: 'ESP',
        },
        {
          type: 'gibson',
          name: 'Gibson',
        },
        {
          type: 'epiphone',
          name: 'Epiphone',
        },
        {
          type: 'rickenbacker',
          name: 'Rickenbacker',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'electric_guitar',
      name: 'Electric Guitars',
      icon: 'flaticon-018-electric-guitar',
      category: [
        'instrument'
      ],
      brands: [
        {
          type: 'fender',
          name: 'Fender',
        },
        {
          type: 'gibson',
          name: 'Gibson',
        },
        {
          type: 'ibanez',
          name: 'Ibanez',
        },
        {
          type: 'squier',
          name: 'Squier',
        },
        {
          type: 'yamaha',
          name: 'Yamaha',
        },
        {
          type: 'esp',
          name: 'ESP',
        },
        {
          type: 'epiphone',
          name: 'Epiphone',
        },
        {
          type: 'gretsch',
          name: 'Gretsch',
        },
        {
          type: 'harley_benton',
          name: 'Harley Benton',
        },
        {
          type: 'cort',
          name: 'Cort',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'keyboards',
      name: 'Keyboards',
      icon: 'flaticon-037-keyboard',
      category: [
        'instrument'
      ],
      brands: [
        {
          type: 'yamaha',
          name: 'Yamaha',
        },
        {
          type: 'akai_professional',
          name: 'AKAI Professional',
        },
        {
          type: 'kawai',
          name: 'Kawai',
        },
        {
          type: 'korg',
          name: 'Korg',
        },
        {
          type: 'roland',
          name: 'Roland',
        },
        {
          type: 'startone',
          name: 'Startone',
        },
        {
          type: 'hammond',
          name: 'Hammond',
        },
        {
          type: 'rhodes',
          name: 'Rhodes',
        },
        {
          type: 'miditech',
          name: 'Miditech',
        },
        {
          type: 'casio',
          name: 'Casio',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'pianos',
      name: 'Pianos',
      icon: 'flaticon-039-music-instrument',
      category: [
        'instrument'
      ],
      brands: [
        {
          type: 'bluther',
          name: 'Bluther',
        },
        {
          type: 'kawai',
          name: 'Kawai',
        },
        {
          type: 'sauter',
          name: 'Sauter',
        },
        {
          type: 'schimmel',
          name: 'Schimmel',
        },
        {
          type: 'seiler',
          name: 'Seiler',
        },
        {
          type: 'steiway_&_sons',
          name: 'Steiway & Sons',
        },
        {
          type: 'yamaha',
          name: 'Yamaha',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },

    {
      type: 'bass_amps',
      name: 'Bass Amps',
      category: [
        'equipment'
      ],
      icon: 'amp',
      brands: [
        {
          type: 'ampeg',
          name: 'Ampeg',
        },
        {
          type: 'fender',
          name: 'Fender',
        },
        {
          type: 'laney',
          name: 'Laney',
        },
        {
          type: 'orange',
          name: 'Orange',
        },
        {
          type: 'vox',
          name: 'Vox',
        },
        {
          type: 'markbass',
          name: 'Markbass',
        },
        {
          type: 'hartke',
          name: 'Hartke',
        },
        {
          type: 'glockenklang',
          name: 'Glockenklang',
        },
        {
          type: 'ebs',
          name: 'EBS',
        },
        {
          type: 'darkglass',
          name: 'Darkglass',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },

    {
      type: 'drum_sets',
      name: 'Drum Sets',
      category: [
        'equipment'
      ],
      icon: 'drums',
      brands: [
        {
          type: 'pearl',
          name: 'Pearl',
        },
        {
          type: 'tama',
          name: 'Tama',
        },
        {
          type: 'yamaha',
          name: 'Yamaha',
        },
        {
          type: 'gretsch',
          name: 'Gretsch',
        },
        {
          type: 'startone',
          name: 'Startone',
        },
        {
          type: 'ludwig',
          name: 'Ludwig',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },

    {
      type: 'guitar_amps',
      name: 'Guitar Amps',
      category: [
        'equipment'
      ],
      icon: 'amp',
      brands: [
        {
          type: 'marshall',
          name: 'Marshall',
        },
        {
          type: 'vox',
          name: 'Vox',
        },
        {
          type: 'fender',
          name: 'Fender',
        },
        {
          type: 'laney',
          name: 'Laney',
        },
        {
          type: 'peavey',
          name: 'Peavey',
        },
        {
          type: 'line6',
          name: 'Line6',
        },
        {
          type: 'orange',
          name: 'Orange',
        },
        {
          type: 'boss',
          name: 'Boss',
        },
        {
          type: 'prs',
          name: 'PRS',
        },
        {
          type: 'darkglass',
          name: 'Darkglass',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },


    {
      type: 'microphones',
      name: 'Microphones',
      category: [
        'equipment'
      ],
      icon: 'mic',
      brands: [
        {
          type: 'akg',
          name: 'AKG',
        },
        {
          type: 'rode',
          name: 'Rode',
        },
        {
          type: 'sennheiser',
          name: 'Sennheiser',
        },
        {
          type: 'neumann',
          name: 'Neumann',
        },
        {
          type: 'shure',
          name: 'Shure',
        },
        {
          type: 'other',
          name: 'Other'
        }
      ]
    },

    {
      type: 'mixboards',
      name: 'Mixboards',
      category: [
        'equipment'
      ],
      icon: 'flaticon-026-guitar',
      brands: [
        {
          type: 'digital',
          name: 'Digital',
        },
        {
          type: 'analogue',
          name: 'Analogue',
        }
      ]
    },

    {
      type: 'other',
      name: 'Other',
      icon: 'track_changes',
      category: [
        'instrument',
        'equipment'
      ],
      brands: [
        {
          type: 'other',
          name: 'Other'
        }
      ]
    }
  ];

  newInstrument;
  newInstrumentBrand;
  newInstrumentName;
  newInstrumentPrice;
  newInstrumentAvailability = true;

  public businessCurrency: string;

  env = environment;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private studiosService: StudiosService,
    private userService: UserService,
    private profileService: ProfileService,
  ) {
    this.userId = this.profileService.getUserData().id;
    this.studio = this.studiosService.studio$;
    this.businessCurrency = this.profileService.getOwnerStudio().businessCurrency ? this.profileService.getOwnerStudio().businessCurrency : 'EUR';

    this.studioEquipment = this.studiosService.rentalEquipment$.pipe(
      map((equipments) => {
        return equipments.map(eq => {
          eq.editAvailable = eq.available;
          return eq;
        });
      })
    );
  }

  ngOnInit(): void {

    this.instruments = this.instrumentsAndEquipments.filter(a => a.category.includes('instrument'));
    this.equipments = this.instrumentsAndEquipments.filter(a => a.category.includes('equipment'));
    this.instruments.forEach(inst => {
      this.instrumentBrandMap.set(inst.type, inst.brands);
    });
    this.equipments.forEach(eq => {
      this.equipmentBrandMap.set(eq.type, eq.brands);
    });

    this.studioId = this.profileService.getOwnerStudio().id;
    this.studiosService.fetchStudio(this.studioId, true)
      .then((studioData) => {
      });

    this.studiosService.fetchStudioRentalEquipment(this.studioId);

  }


  resetNewInstrumentBrand(){
    if (!this.newInstrument?.brands.some(brand => brand.type === this.newInstrumentBrand?.type)){
      this.newInstrumentBrand = null;
    }
  }

  addNewInstrument() {
    const newInstrument: any = {};
    newInstrument.name = this.newInstrumentName;
    newInstrument.category = this.newInstrument?.type;
    newInstrument.price = this.newInstrumentPrice;
    newInstrument.available = this.newInstrumentAvailability;
    newInstrument.brand = this.newInstrumentBrand?.type;
    if (!newInstrument.category) {
      this.messageService.add({
        key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Instrument Type is required!', life: 3000});
    } else if (!newInstrument.brand) {
      this.messageService.add({
        key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Instrument Brand is required!', life: 3000});
    } else if (!newInstrument.name) {
      this.messageService.add({
        key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Instrument Name is required!', life: 3000});
    } else if (!newInstrument.price && newInstrument.price !== 0) {
      this.messageService.add({
        key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Instrument Price is required!', life: 3000});
    } else {
      this.studiosService.createInstrument(this.studioId , newInstrument)
        .then((data) => {
          this.newInstrumentPrice = null;
          this.newInstrument = null;
          this.newInstrumentName = null;
          this.newInstrumentBrand = null;
          this.newInstrumentAvailability = true;
          this.messageService.add({
            key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Instrument Added', life: 3000});
        });
    }

  }

  previousPage() {
    this.router.navigate(['studio-rooms'], { relativeTo: this.route.parent });
  }

  nextPage( skip = false) {
    if (skip) {
      this.router.navigate(['dashboard/partner']);
    } else {
      this.userService.removeRegistrationByType(this.userId, 'partner_wizard')
        .then((data) => {
          this.router.navigate(['dashboard/partner']);
        });
    }
  }
}
