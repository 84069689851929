<p-dataView #dv
            [value]="(bookings | async)"
            [paginator]="true"
            [rows]="9"
            filterBy="invite.user.name"
            [loading]="loading"
            layout="list">
  <ng-template pTemplate="header">

  </ng-template>

  <ng-template let-booking pTemplate="listItem">
    <div class="col-12">
      <div class="product-list-item">

        <img
          [src]="booking.studio.studioLogo ? booking.studio.studioLogo['link'] : 'assets/images/sample_studio.png'"
          [alt]="booking.label"
        />
        <div class="product-list-detail">
          <div class="product-name">{{booking.studio.label}}</div>
          <div>
            <span>Type : {{booking.service.type}}</span>
<!--            <i style="margin-left: 0.5em;" class="pi pi-phone product-category-icon"></i><span class="product-category">{{booking.studio.label}}</span>-->
<!--            <i style="margin-left: 0.5em;" class="pi pi-at product-category-icon"></i><span class="product-category">{{booking.studio.address}}</span>-->
            <br>
            <i style="margin-left: 0.5em;" class="pi pi-calendar-plus product-category-icon"></i><span class="product-category">Booked at {{booking.bookingDate | date : 'dd/MM/yyyy'}}</span>
            <br>
<!--            <p-tag [value]="'Booked at '+booking.bookingDate" icon="pi pi-calendar"></p-tag>-->

          </div>

          <p-avatarGroup label="bookingMembers" class="product-price">
            <ng-template
              ngFor
              let-bookingMember
              [ngForOf]="booking.members"
              let-i="index"
            >
              <p-avatar
                [image]="bookingMember.member.avatar ? bookingMember.member.avatar['link'] : 'assets/images/sample_member.png'"
                shape="circle"
                size="xlarge"
              ></p-avatar>
              <span>
                {{bookingMember.member.name}}
              </span>
            </ng-template>
          </p-avatarGroup>

        </div>

        <div class="product-list-action">

          <span *ngIf="booking.status === 'planning'" [class]="'product-badge status-lowstock '">PLANNING</span>
          <span *ngIf="booking.status === 'planning-complete'" [class]="'product-badge status-stock '">PLANNING COMPLETE</span>
          <span *ngIf="booking.status === 'pending-confirmation'" [class]="'product-badge status-stock'">TO BE CONFIRMED</span>
          <span *ngIf="booking.status === 'studio-confirmed'" [class]="'product-badge status-instock'">CONFIRMED</span>
          <span *ngIf="booking.status === 'studio-declined'" [class]="'product-badge status-outofstock'">REJECTED</span>


          <br>


          <p-button
            *ngIf="
            booking.status === 'planning' ||
            booking.status === 'planning-complete' ||
            booking.status === 'planning-confirmation'
            "
            pRipple
            styleClass="p-button-danger mr-2 mb-2"
            icon="pi pi-minus-circle"
            label="Delete Booking"
            (click)="deleteBooking(booking)"
          ></p-button>


          <p-button
            pRipple
            styleClass="p-button-info mr-2 mb-2"
            icon="pi pi-chevron-circle-right"
            label="See Details"
            (click)="seeDetails(booking)"
          ></p-button>



        </div>


      </div>
    </div>
  </ng-template>



</p-dataView>




