<p-table [value]="instruments" responsiveLayout="scroll">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">

      <p-button icon="pi pi-plus" styleClass="p-button-success" (onClick)="instrumentDialog = true"></p-button>
      <p-button icon="pi pi-refresh" (onClick)="refresh()"></p-button>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Image</th>
      <th>Type</th>
      <th>Category</th>
      <th>Level</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-instrument>
    <tr>
      <td>{{instrument.name}}</td>
      <td><img [src]="instrument.image ? instrument.image : 'assets/images/instruments/sample_' + instrument.instrument.name.toLowerCase() + '.png'" [alt]="instrument.name" width="100" class="shadow-4" /></td>
      <td>{{instrument.instrument.name }}</td>
      <td>{{instrument.instrument.category }}</td>
      <td><p-rating [ngModel]="instrument.level" [readonly]="true" [cancel]="false" ></p-rating></td>
      <td>
        <p-button  icon="pi pi-minus" styleClass="p-button-danger" (onClick)="removeInstrument(instrument)"></p-button>
        <p-button icon="pi pi-pencil" styleClass="p-button-secondary" (onClick)="editInstrument(instrument)"></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="flex align-items-center justify-content-between">
      In total there are {{instruments ? instruments.length : 0 }} instruments.
    </div>
  </ng-template>
</p-table>


  <p-dialog [(visible)]="instrumentDialog" [style]="{width: '450px'}" header="Instrument" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
      <img [src]="'assets/images/instruments/sample_guitar.png'" [alt]="'new instrument'" style="width:100%;height:auto;" class="product-image" >
      <div class="field">
        <label for="name">Name</label>
        <input type="text" pInputText id="name" [(ngModel)]="instrument.name" required autofocus />
        <small class="p-invalid" *ngIf="submitted && !instrument.name">Name is required.</small>
      </div>
      <div class="field">
        <label for="level">Level</label>
        <p-rating id="level" [(ngModel)]="instrument.level" required></p-rating>
        <small class="p-invalid" *ngIf="submitted && !instrument.level">Level is required.</small>
      </div>
      <div class="field">
        <label for="instrumentId">Type</label>
        <p-dropdown
          [options]="instrumentTypes"
          id="instrumentId"
          [(ngModel)]="instrument.instrumentId"
          required
          optionLabel="name"
          optionValue="code"
        ></p-dropdown>

        <small class="p-invalid" *ngIf="submitted && !instrument.instrumentId">Type is required.</small>
      </div>

    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
      <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveInstrument()"></button>
    </ng-template>
  </p-dialog>

<!--  <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>-->
