<div class="grid">
  <div class="col-12" *ngIf="studio | async as studioData">
    <div class="formgrid grid">
      <div class="field col-12 lg:col-6">
        <label for="studioName">Studio Name</label>
        <input
          inputTrim
          id="studioName"
          [(ngModel)]="studioData.name"
          type="text"
          pInputText
          class="w-full"
          (ngModelChange)="textChange('studioName', studioData.name)"
        />
      </div>
    </div>

    <div class="formgrid grid">
      <div class="col-6">
        <div class="formgrid grid">
          <div class="field col-12">
            <label for="addressField">Full Address</label>
            <input
              #addressField
              [(ngModel)]="streetAddress"
              pInputText
              id="addressField"
              type="text"
              class="w-full"
            />
          </div>

          <div class="field col-12">
            <label for="street">Street</label>
            <input
              inputTrim
              pInputText
              [(ngModel)]="studioData.address.street"
              id="street"
              type="text"
              class="w-full"
              (ngModelChange)="textChange('street', studioData.address.street)"
            />
          </div>

          <div class="field col-12">
            <label for="number">Number</label>
            <input
              inputTrim
              pInputText
              [(ngModel)]="studioData.address.number"
              id="number"
              type="text"
              class="w-full"
              (ngModelChange)="textChange('number', studioData.address.number)"
            />
          </div>

          <div class="field col-12">
            <label for="zip">Zip Code</label>
            <input
              inputTrim
              pInputText
              [(ngModel)]="studioData.address.zip"
              id="zip"
              type="text"
              class="w-full"
              (ngModelChange)="textChange('zip', studioData.address.zip)"
            />
          </div>

          <div class="field col-12">
            <label for="city">City</label>
            <input
              inputTrim
              pInputText
              [(ngModel)]="studioData.address.city"
              id="city"
              type="text"
              class="w-full"
              (ngModelChange)="textChange('city', studioData.address.city)"
            />
          </div>

          <div class="field col-12">
            <label style="color: #b1ede8 !important" for="city">Country</label>
            <p-dropdown
              inputId="country"
              id="country"
              required="true"
              optionValue="label"
              [options]="countries"
              [(ngModel)]="studioData.address.country"
              (onChange)="selectCountry($event)"
              placeholder="Select a Country"
            >
              <ng-template let-country pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>
                    <span>{{ country.emoji }}</span> {{ country.name }}
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="formgrid grid">
      <div class="col-12">
        <p style="padding-bottom: 1rem">Customer Contact</p>
      </div>
      <div class="field col-12 lg:col-3">
        <label for="phoneNumber">Telephone</label>
        <p-inputNumber
          id="phoneNumber"
          inputId="phoneNumber"
          placeholder="Phone Number"
          class="w-full"
          styleClass="number-inputs"
          [prefix]="phonePrefix"
          [useGrouping]="false"
          [(ngModel)]="studioData.landLine"
          (ngModelChange)="textChange('landLine', studioData.landLine)"
        ></p-inputNumber>
      </div>
      <div class="field col-12 lg:col-3">
        <label for="mobileNumber">Mobile phone</label>
        <p-inputNumber
          id="mobileNumber"
          inputId="mobileNumber"
          class="w-full"
          placeholder="Mobile Number"
          styleClass="number-inputs"
          [prefix]="phonePrefix"
          [useGrouping]="false"
          [(ngModel)]="studioData.phoneNumber"
          (ngModelChange)="textChange('phoneNumber', studioData.phoneNumber)"
        ></p-inputNumber>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="grid grid-nogutter justify-content-around">
      <!-- <p-button
        label="Cancel"
        (onClick)="previousPage()"
        icon="pi pi-angle-left"
        iconPos="left"
      ></p-button> -->
      <button
        pButton
        class="secondary-button"
        label="Skip"
        (click)="nextPage(true)"
        icon="pi pi-angle-right"
        iconPos="right"
      ></button>
      <p-button
        label="Next"
        (onClick)="nextPage()"
        icon="pi pi-angle-right"
        iconPos="right"
        [disabled]="!disableNext"
      ></p-button>
    </div>
  </div>
</div>
