import {Component, Input, OnInit} from '@angular/core';
import {StudiosService} from '../../studios.service';
import {BookingsService} from '../../../bookings/bookings.service';
import {filter, map, tap} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-studio-rooms',
  templateUrl: './studio-rooms.component.html',
  styleUrls: ['./studio-rooms.component.scss']
})
export class StudioRoomsComponent implements OnInit {

  @Input() studioId: string;

  rooms: any;
  loading = true;

  roomName = '';
  roomDescription = '';

  roomDialog = false;
  submitted = false;

  selectedRoom;
  instrumentDialog = false;
  instrumentName = '';
  instrumentDescription = '';
  instrumentCategory = 'string';
  instrumentCategories = [
    {
      name : 'Other',
      code : 'other'
    },
    {
      name : 'String',
      code : 'string'
    },
    {
      name : 'Wind',
      code : 'wind'
    },
    {
      name : 'Membrane',
      code : 'membrane'
    },
    {
      name : 'Brass',
      code : 'brass'
    },
    {
      name : 'Percussion',
      code : 'percussion'
    },
    {
      name : 'Electronic',
      code : 'electronic'
    },
  ];

  env = environment;
  enableActions = false;

  constructor(
    private studiosService: StudiosService,
    private bookingsService: BookingsService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.rooms = this.studiosService.rooms$.pipe(
      map((rooms) => {
        rooms = rooms.map((room) => {
          room.avatar = room.media[0];
          return room;
        })
        return rooms
      }),
      tap( rooms => this.loading = false),
    );
  }


  refresh() {
    this.studiosService.getRooms(this.studioId)
      .then((data) => {
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Refreshed!', life: 3000});
      });

  }

  removeRoom(room) {
    this.studiosService.removeRoom(this.studioId , room.id)
      .then((data) => {
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Room Deleted', life: 3000});
      });
  }

  openNew() {
    this.roomName = '';
    this.roomDescription = '';
    this.submitted = false;
    this.roomDialog = true;
  }

  addRoom() {
    const newRoom: any = {
      name : this.roomName
    };
    if (this.roomDescription && this.roomDescription.length > 0) {
      newRoom.description = this.roomDescription;
    }
    this.studiosService.createRoom(this.studioId , newRoom)
      .then((data) => {
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Room Added', life: 3000});
        this.hideDialog();
      });

  }

  addInstrument() {
    const equipment = this.selectedRoom.equipment;
    equipment.push({
      name : this.instrumentName,
      description : this.instrumentDescription,
      category : this.instrumentCategory
    });
    this.studiosService.editRoom(this.studioId , this.selectedRoom.id ,
      {
        equipment
      }).then((data) => {
        this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Instrument Added', life: 3000});
        this.hideDialog();
    });
  }

  removeInstrument(room: any , instrumentIndex: number) {
    const equipment = room.equipment;
    equipment.splice(instrumentIndex , 1);
    this.studiosService.editRoom(this.studioId , room.id ,
      {
        equipment
      }).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'danger', summary: 'Successful', detail: 'Instrument Deleted', life: 3000});
      this.hideDialog();
    });
  }

  openNewInstrument(room) {
    this.selectedRoom = room;
    this.submitted = false;
    this.instrumentDialog = true;
    this.instrumentCategory = 'other';
    this.instrumentName = '';
    this.instrumentDescription = '';
  }

  hideDialog() {
    this.roomName = '';
    this.roomDescription = '';
    this.roomDialog = false;

    this.instrumentDialog = false;
    this.instrumentCategory = 'other';
    this.instrumentName = '';
    this.instrumentDescription = '';

    this.submitted = false;
  }

}
