import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../../authentication/authentication.service";
import { LocalStorageService } from "ngx-webstorage";
import { ProfileService } from "../profile.service";

@Injectable({
  providedIn: 'root'
})
export class LandingGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private localSt: LocalStorageService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.localSt.retrieve('user')) {
      if (this.profileService.isStudio()) {
        this.router.navigate(['dashboard/partner']);
      } else if (this.profileService.isAdmin()) {
        this.router.navigate(['dashboard/admin']);
      }
      return false;
    } else {
      return true;
    }

  }

}
