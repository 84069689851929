import {Component, Input, OnInit} from '@angular/core';
import {InstrumentAndEquipmentService} from '../../instruments.equipments.service';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit {

  @Input() equipment;
  equipmentMap;
  constructor(private instrumentService: InstrumentAndEquipmentService) {
  }

  ngOnInit(): void {
    this.equipmentMap = this.instrumentService.getEquipmentMap();
  }

}
