import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as mammoth from 'mammoth/mammoth.browser';
import { Router } from "@angular/router";
import { NotificationsService } from "../../notifications/notifications.service";
import { AuthenticationService } from "../authentication.service";
import { UserService } from "../../users/user/user.service";
import { MessageService } from "primeng/api";
import { HelperService } from "../../common/helper.service";
import { ContractsService } from "./contracts.service";
import {filter, map, skip, tap} from "rxjs/operators";
@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {


  @Input() studio_name: string;
  @Input() business_name: string;
  @Input() billing_country: string;
  @Input() billing_city: string;
  @Input() billing_street: string;
  @Input() billing_street_number: string;
  @Input() billing_zip_code: string;
  @Input() billing_tin_number: string;
  @Input() billing_email: string;
  @Input() studio_owner_name: string;
  @Input() individual_name: string;
  @Input() individual_capacity: string;



  @Output() acceptContract = new EventEmitter<any>();
  @Output() backTo = new EventEmitter<any>();

  contractHTML;

  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private contractsService: ContractsService,
    private messageService: MessageService,
    private helperService: HelperService
  ) {
    this.contractHTML = this.contractsService.contract$.pipe(
      skip(1),
      filter(data => data.value),
    );

  }

  ngOnInit(): void {

    this.contractsService.fetchLatestTemplate(
      {
        studio_name : this.studio_name,
        business_name : this.business_name,
        billing_country : this.billing_country,
        billing_city : this.billing_city,
        billing_street : this.billing_street,
        billing_street_number : this.billing_street_number,
        billing_zip_code : this.billing_zip_code,
        billing_tin_number : this.billing_tin_number,
        billing_email : this.billing_email,
        studio_owner_name : this.studio_owner_name,
        individual_name : this.individual_name,
        individual_capacity : this.individual_capacity,
      }
    );
  }

  acceptAndSignupContract() : void {
    this.acceptContract.emit(true);
  }

  public goBack() {
    this.backTo.emit('company');
  }



}
