import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {AuthenticationService} from '../../authentication/authentication.service';
import {BookingsService} from '../../bookings/bookings.service';

@Injectable()
export class NewbookingGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private bookingService: BookingsService,
    private router: Router,
  ) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if ( Object.keys(this.bookingService.getNewBookingData()).length > 0 ) {
      return true;
    } else {
      this.router.navigate(['dashboard', 'newbooking', 'type']);
      return false;
    }

  }
}

