import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudiosService } from '../../studios/studios.service';
import { HelperService } from '../../common/helper.service';
import { ProfileService } from '../../common/profile.service';
import { first, map, take } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

declare var google: any;

@Component({
  selector: 'app-wizard-studio-info',
  templateUrl: './wizard-studio-info.component.html',
  styleUrls: ['./wizard-studio-info.component.scss'],
})
export class WizardStudioInfoComponent implements OnInit {
  private studioId;

  public studio;
  public phonePrefix = '(+) ';
  public countries: any[];
  public streetAddress: string;
  autocomplete: google.maps.places.Autocomplete;
  @ViewChild('addressField') addressField: ElementRef<HTMLInputElement>;

  disableNext = true;

  public completedFields = {
    name: false,
    street: false,
    number: false,
    zip: false,
    city: false,
    country: false,
    landLine: false,
    phoneNumber: false,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private studiosService: StudiosService,
    private helperService: HelperService,
    private profileService: ProfileService
  ) {
    this.streetAddress = '';
    this.countries = this.helperService.getCountriesData();
    this.studio = this.studiosService.studio$.pipe(
      map((studioData) => {
        if (!studioData.hasOwnProperty('address')) {
          studioData.address = {
            street: null,
            number: null,
            zip: null,
            city: null,
            country: null,
          };
        } else if (
          studioData.address.hasOwnProperty('number') &&
          studioData.address.number
        ) {
          studioData.address.number = studioData.address.number + '';
        }
        if (studioData?.owner?.address?.country) {
          this.selectCountry({ value: studioData.owner.address.country });
          studioData.address.country = studioData.owner.address.country
        }
        return studioData;
      })
    );
  }

  ngOnInit(): void {
    this.studioId = this.profileService.getOwnerStudio().id;
    this.studiosService.fetchStudio(this.studioId, true).then((studioData) => {
      this.initAutoComplete();
      this.checkStudioRequiredFields(studioData);
    });
  }

  public selectCountry(event) {
    const selected = this.countries.find(
      (country) => event.value === country.label
    );
    this.phonePrefix = '(+' + selected.phone + ') ';
    this.completedFields['country'] = true;
    this.disableNextLogic();
  }

  initAutoComplete() {
    if (!this.addressField || !this.autocomplete) {
      setTimeout(() => {
        if (this.addressField || !this.autocomplete) {
          this.autocomplete = new google.maps.places.Autocomplete(
            this.addressField.nativeElement,
            {
              language: 'en',
              fields: ['address_components', 'geometry'],
              types: ['address'],
            }
          );
          this.autocomplete.addListener('place_changed', () => {
            const place = this.autocomplete.getPlace();
            const street = place.address_components.find((el) =>
              el.types.includes('route')
            );
            const roadNumber = place.address_components.find((el) =>
              el.types.includes('street_number')
            );
            const zip = place.address_components.find((el) =>
              el.types.includes('postal_code')
            );
            const city = place.address_components.find((el) =>
              el.types.includes('locality')
            );
            const country = place.address_components.find((el) =>
              el.types.includes('country')
            );

            if (street && roadNumber && zip && city && country) {
              this.studio.pipe(first()).subscribe((studioData: any) => {
                studioData.address = {
                  street: street.long_name,
                  number: roadNumber.long_name,
                  zip: zip.long_name,
                  city: city.long_name,
                  country: country.long_name,
                };

                studioData.coordinates = {
                  x: place.geometry.location.lat(),
                  y: place.geometry.location.lng(),
                };

                if (studioData.address.country) {
                  this.selectCountry({ value: studioData.address.country });
                }
                this.checkStudioRequiredFields(studioData);
              });
            } else {
              this.messageService.add({
                key: 'globalToast',
                severity: 'error',
                summary: 'Error',
                detail: 'Select more specific address!',
                life: 3000,
              });
              this.streetAddress = '';
            }
          });
        }
      }, 1000);
    }
  }

  previousPage() {
    this.router.navigate(['dashboard', 'partner']);
  }

  nextPage(skip = false) {
    if (!skip) {
      this.studio
        .pipe(take(1))
        .toPromise()
        .then((studioData) => {
          let editedStudio = Object.assign({}, studioData);
          editedStudio = (({
            id,
            name,
            phoneNumber,
            landLine,
            coordinates,
          }) => ({ id, name, phoneNumber, landLine, coordinates }))(
            editedStudio
          );
          const addressKeys = Object.keys(studioData.address);
          editedStudio.address = {};
          addressKeys.forEach((key) => {
            if (studioData.address[key]) {
              editedStudio.address[key] = studioData.address[key];
            }
          });
          if(studioData.coordinates === undefined){
            this.messageService.add({
              key: 'globalToast',
              severity: 'error',
              summary: 'Address Error',
              detail: 'The address you have entered is not valid',
              life: 3000,
            });
          }
          const coordinateKeys = Object.keys(studioData.coordinates); //breaks
          editedStudio.coordinates = {};
          coordinateKeys.forEach((key) => {
            if (studioData.coordinates[key]) {
              editedStudio.coordinates[key] = studioData.coordinates[key];
            }
          });
          return this.studiosService.editStudio(editedStudio);
        })
        .then((data) => {
          this.updateLocalData(data);
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Changes saved',
            life: 3000,
          });
          this.router.navigate(['studio-media'], {
            relativeTo: this.route.parent,
          });
        })
        .catch((err) => {
          this.messageService.add({
            key: 'globalToast',
            severity: 'error',
            summary: 'Error',
            detail: err.error.message,
            life: 3000,
          });
          throw err;
        });
    } else {
      this.router.navigate(['studio-media'], { relativeTo: this.route.parent });
    }
  }

  updateLocalData(studio) {
    this.helperService.saveLocalStorage('studio', studio);
    this.profileService.setOwnerStudio(studio);
  }

  checkStudioRequiredFields(studioData) {
    Object.keys(studioData).forEach((el) => {
      if (
        this.completedFields[el] !== undefined &&
        studioData[el] !== null &&
        studioData[el] !== ''
      ) {
        this.completedFields[el] = true;
      }
    });

    Object.keys(studioData.address).forEach((el) => {
      if (
        this.completedFields[el] !== undefined &&
        studioData.address[el] !== null &&
        studioData.address[el] !== ''
      ) {
        this.completedFields[el] = true;
      }
    });
    this.disableNextLogic();
  }

  textChange(field, data) {
    if (data && data !== '') this.completedFields[field] = true;
    else this.completedFields[field] = false;

    this.disableNextLogic();
  }

  public disableNextLogic() {
    for (let key in this.completedFields) {
      if (!this.completedFields[key]) return (this.disableNext = false);
    }

    return (this.disableNext = true);
  }
}
