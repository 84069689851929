import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BookingsService} from '../../bookings.service';
import {ProfileService} from '../../../common/profile.service';

@Component({
  selector: 'app-booking-confirm',
  templateUrl: './booking-confirm.component.html',
  styleUrls: ['./booking-confirm.component.scss']
})
export class BookingConfirmComponent implements OnInit {

  bookingData: any;

  memberDialog = false;
  availableMembers;
  selectedMembers;

  userId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private bookingsService: BookingsService
  ) {

  }
  ngOnInit(): void {

    this.userId = this.profileService.getUserData().id;

    this.bookingData = this.bookingsService.getNewBookingData();

  }

  previousPage() {
    this.router.navigate(
      ['studio'],
      { relativeTo: this.route.parent}
    );
  }

  confirm() {
    const bookingObject: any = {
      label : this.bookingData.info.bookLabel,
      studio : this.bookingData.studio.selectedStudio.id,
      bookingType : this.bookingData.type.bookingType,
      bookingDate : this.bookingData.info.bookDate,
      duration : this.bookingData.info.bookDuration,
      notes : [this.bookingData.info.bookNote],
      service : this.bookingData.studio.selectedService.id,
      room : this.bookingData.studio.selectedRoom.id,
    };
    if (this.bookingData.type.bookingType === 'band') {
      bookingObject.team = this.bookingData.type.selectedBand.id;
      bookingObject.members = this.bookingData.type.selectedMembers.map(mbr => mbr.id);
    } else {
      bookingObject.user = this.userId;
    }
    if (this.bookingData.info.bookVoting === true) {
      bookingObject.status = 'planning';
    } else {
      bookingObject.status = 'pending-confirmation';
    }

    if (this.bookingData.restartBookingId) {
      const restartBookingId = this.bookingData.restartBookingId;
      delete this.bookingData.restartBookingId;
      this.bookingsService.restartBooking(restartBookingId , bookingObject)
        .then((data) => {
          this.router.navigate(['dashboard/users/' + this.userId + '/bookings']);
        });
    } else {
      this.bookingsService.createBooking(bookingObject)
        .then((data) => {
          this.router.navigate(['dashboard/']);
        });
    }

  }

  removeMember(member) {
    this.bookingData.type.selectedMembers = this.bookingData.type.selectedMembers.filter(mbr => mbr.id !== member.id);
    this.bookingsService.setNewBookingData(this.bookingData.type , 'type');
  }

  addMembers() {
    this.bookingData.type.selectedMembers.push(...this.selectedMembers);
    this.bookingsService.setNewBookingData(this.bookingData.type , 'type');
    this.hideDialog();
  }
  hideDialog() {
    this.memberDialog = false;
    this.availableMembers = [];
    this.selectedMembers = [];
  }
  showDialog() {
    this.memberDialog = true;
    this.availableMembers = this.bookingData.type.selectedBand.band.members
      .filter(mbr => !this.bookingData.type.selectedMembers.find( selectedMember => mbr.user.id === selectedMember.id ) )
      .map((member) => {
        return {name: member.user.name , id: member.id, user: member.user};
      });
    this.selectedMembers = [];
  }
}
