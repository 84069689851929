import {Component, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LocalStorageService } from "ngx-webstorage";
import { NotificationsService } from "./notifications/notifications.service";
import {StudiosService} from "./studios/studios.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  inputStyle = 'outlined';

  ripple: boolean;

  theme = 'indigo';

  layoutColor = 'white';

  colorScheme = 'light';

  menuMode = 'slim';

  constructor(
    private localSt: LocalStorageService,
    private notificationsService: NotificationsService,
    private studiosService: StudiosService,
    private primengConfig: PrimeNGConfig
  ) {
    if (this.localSt.retrieve('user')) {
      this.notificationsService.startPolling();
    }
    if (this.localSt.retrieve('studio')) {
      this.studiosService.fetchContractsValidity(this.localSt.retrieve('studio').id);
    }
  }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.ripple = true;
    }
}
