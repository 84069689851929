import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {BandsService} from '../bands.service';
import {ConfirmationService} from 'primeng/api';
import {filter, map} from 'rxjs/operators';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-band',
  templateUrl: './band.component.html',
  styleUrls: ['./band.component.scss'],
  providers: [ConfirmationService]
})
export class BandComponent implements OnInit {

  public env = environment;
  public band: any;
  public bandId: string;

  constructor(
    private route: ActivatedRoute,
    private bandsService: BandsService,
  ) { }

  ngOnInit(): void {

    this.band = this.bandsService.band$.pipe(filter(bnd => Object.keys(bnd).length !== 0) ,
      map((band) => {
        band.avatar = band.media ? band.media.find(el => el.metadata.type === 'avatar') : undefined;
        return band;
      })
    );

    this.route.params.subscribe((params: Params) => {
      this.bandId = params.id;
      this.bandsService.fetchBand(this.bandId);
    });



  }

  protected readonly environment = environment;
}
