<h1>
  Payments
</h1>
<p-table
  #dt
  [value]="transactions | async "
  [columns]="cols"
  [exportFilename]="exportFileName"
  [tableStyle]="{'min-width': '70rem'}"
>
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      <h3 style="margin-right: 2em !important;" class="m-0">Pick Month</h3>
      <p-calendar
        [(ngModel)]="pickedDate"
        view="month"
        dateFormat="mm/yy"
        [readonlyInput]="true"
        (onSelect)="changeDate($event)"
      ></p-calendar>
      <button type="button" pButton pRipple icon="pi pi-file" (click)="dt.exportCSV()" class="p-button-secondary mr-2 ml-auto" pTooltip="CSV" tooltipPosition="bottom"></button>
      <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel(dt.value)" class="p-button-secondary mr-2 ml-0" pTooltip="EXCEL" tooltipPosition="bottom"></button>
      <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf(dt.value)" class="p-button-secondary mr-2 ml-0" pTooltip="PDF" tooltipPosition="bottom"></button>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns"
        style="width:20%"
      >
        {{col.header}}
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-transaction let-columns="columns">

    <tr>
      <td>
        <span
          [routerLink]="'/dashboard/partner/bookings/' + transaction.bookingId"
          class="keyword cursor-pointer"
        >#{{transaction.shortId}}</span>
      </td>
      <td>
        {{transaction.date | date:'dd/MM/yyyy'}}
      </td>
      <td>
        {{transaction.service}}
      </td>
      <td>
        {{transaction.paymentMethod}}
      </td>
      <td>
        {{transaction.bookingStatus}}
      </td>
      <td>
        <span
          [ngClass]="{
          'plus-return' : transaction.paymentMethod === 'Online' ,
          'equal-return' : transaction.paymentMethod === 'Offline' ,
          'minus-return' : transaction.booking.status === 'studio-canceled'
          }"
        >
          {{transaction.returnPrice}} {{getCurrencySymbol()}}
        </span>

      </td>
    </tr>
  </ng-template>


  <ng-template pTemplate="footer">
    <tr>
      <td colspan="5" style="text-align: right">Total Booking</td>
      <td>{{dt.totalRecords}}</td>
    </tr>
    <tr>
      <td colspan="5" style="text-align: right">Total Return</td>
      <td>{{calculateTotalEarnings(dt.value)}} {{getCurrencySymbol()}}</td>
    </tr>
  </ng-template>
</p-table>
