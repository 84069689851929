import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-band-simple',
    templateUrl: './band-simple.component.html',
    styleUrls: ['./band-simple.component.scss']
})
export class BandSimpleComponent implements OnInit {

  @Input() item;
  public env = environment;

  constructor() {
  }

  ngOnInit(): void {
    this.item.avatar = this.item.media.reverse().find((media => media.metadata.type === 'avatar'));
  }
}
