import { Component } from '@angular/core';
import { AppComponent} from '../app.component';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
  styles: [`a {color: #FFFFFF}`],
  styleUrls: ['./app.footer.component.scss']
})
export class AppFooterComponent {
    year = new Date().getFullYear().toString();
    constructor(public app: AppComponent) {}

  redirectToFaceBook() {
    window.open('https://www.facebook.com/bandbeat.official/', '_blank');
  }

  redirectToInstagram() {
    window.open('https://www.instagram.com/bandbeat.official/', '_blank');
  }
}
