<div class="grid">

  <div class="col-12">
    <p-toast></p-toast>
    <div class="card">
      <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
          <button
            pButton
            pRipple
            label="New"
            icon="pi pi-plus"
            class="p-button-success mr-2 mb-2"
            (click)="openNew()"
          ></button>
        </ng-template>
      </p-toolbar>
      <h5>Bands</h5>
      <p-dataView #dv
                  [value]="(bands | async)?.results"
                  [paginator]="true"
                  [rows]="9"
                  filterBy="name"
                  [sortField]="sortField"
                  [sortOrder]="sortOrder"
                  [loading]="loading"
                  layout="list">
        <ng-template pTemplate="header">
          <div class="flex p-flex-column md:flex-row md:justify-content-between">
            <p-dropdown
              [options]="sortOptions"
              [(ngModel)]="sortKey"
              placeholder="Sort By Name"
              (onChange)="onSortChange($event)"
              styleClass="mb-2 md:mb-0"
            ></p-dropdown>
            <span class="p-input-icon-left mb-2 md:mb-0">
                    <i class="pi pi-search"></i>
                    <input type="search" pInputText placeholder="Search by Name" (input)="dv.filter($event.target.value)">
                </span>
            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
          </div>
        </ng-template>
        <ng-template let-band pTemplate="listItem">
          <div class="col-12">
            <div class="product-list-item">
              <img [src]="'assets/images/sample_band.png'" [alt]="band.name"/>
              <div class="product-list-detail">
                <div class="product-name">{{band.name}}</div>
                <div class="product-description">{{band.description}}</div>
                <p-rating [ngModel]="band.rating ? band.rating : 3"  [readonly]="true" [cancel]="false"></p-rating>
                <i style="margin-left: 0.5em;" class="pi pi-user product-category-icon"></i><span class="product-category" >{{band.members.length}}</span>
                <i style="margin-left: 0.5em;" class="pi pi-ticket product-category-icon"></i><span class="product-category">{{band.bookings ? band.bookings.length : 0}}</span>
              </div>
              <div class="product-list-action">
                <span *ngIf="band.status === 'active'" [class]="'product-badge status-instock'">ACTIVE</span>
                <span *ngIf="band.status === 'inactive'" [class]="'product-badge status-lowstock'">INACTIVE</span>
                <span *ngIf="band.status === 'banned'" [class]="'product-badge status-outofstock'">BANNED</span>

                <p-button icon="pi pi-arrow-circle-right" label="See Details" (onClick)="seeBand(band)"></p-button>

                <button
                  pButton
                  pRipple
                  type="button"
                  class="p-button-danger mr-2 mb-2"
                  icon="pi pi-arrow-circle-right"
                  label="Delete Band"
                  (click)="deleteBand(band)"
                ></button>

              </div>
            </div>
          </div>
        </ng-template>

        <ng-template let-band pTemplate="gridItem">
          <div class="col-12 md:col-4">
            <div class="product-grid-item card">
              <div class="product-grid-item-top">
                <div>
                </div>
                <span *ngIf="band.status === 'active'" [class]="'product-badge status-instock'">ACTIVE</span>
                <span *ngIf="band.status === 'inactive'" [class]="'product-badge status-lowstock'">INACTIVE</span>
                <span *ngIf="band.status === 'banned'" [class]="'product-badge status-outofstock'">BANNED</span>
              </div>
              <div class="product-grid-item-content">
                <img [src]="'assets/images/sample_band.png'" style="width:100%;height:auto;" [alt]="band.name"/>
                <div class="product-name">{{band.name}}</div>
                <div class="product-description">{{band.description}}</div>
                <p-rating [ngModel]="band.rating ? band.rating : 3" [readonly]="true" [cancel]="false"></p-rating>
                <i style="margin-left: 0.5em;" class="pi pi-user product-category-icon"></i><span class="product-category" >{{band.members.length}}</span>
                <i style="margin-left: 0.5em;" class="pi pi-ticket product-category-icon"></i><span class="product-category">{{band.bookings ? band.bookings.length : 0}}</span>

              </div>
              <div class="product-grid-item-bottom" style="margin-top: 0.5em">


                <button
                  pButton
                  pRipple
                  type="button"
                  class="p-button-danger mr-2 mb-2"
                  icon="pi pi-arrow-circle-right"
                  label="Delete"
                  (click)="deleteBand(band)"
                ></button>

                <p-button icon="pi pi-arrow-circle-right" label="Details" (onClick)="seeBand(band)"></p-button>
              </div>
            </div>
          </div>
        </ng-template>

      </p-dataView>
    </div>

    <p-dialog [(visible)]="bandDialog" [style]="{width: '450px'}" header="Band Details" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="content">
        <img [src]="'assets/images/sample_band.png'" style="width:100%;height:auto;" [alt]="band.image" class="product-image" >
        <div class="field">
          <label for="name">Name</label>
          <input type="text" pInputText id="name" [(ngModel)]="band.name" required autofocus />
          <small class="p-invalid" *ngIf="submitted && !band.name">Name is required.</small>
        </div>
        <div class="field">
          <label for="description">Description</label>
          <input type="text" pInputText id="description" [(ngModel)]="band.description" required autofocus />
          <small class="p-invalid" *ngIf="submitted && !band.label">Description is required.</small>
        </div>



      </ng-template>

      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveBand()"></button>
      </ng-template>
    </p-dialog>

    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
  </div>




</div>
