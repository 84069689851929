<div class="grid">
  <!-- RENTAL INSTRUMENTS  -->
  <div class="col-12" *ngIf="studio | async as studioData">
    <div class="grid">
      <div class="col-2">
        <h5>Instruments for Rental</h5>
      </div>
    </div>

    <div style="margin-bottom: 2em">
      <div class="formgrid grid">
        <div class="field col-2 md:col-2">
          <span class="p-float-label">
            <p-dropdown
              #instrumentType
              id="instrumentType"
              [autoDisplayFirst]="false"
              [options]="instruments"
              [(ngModel)]="newInstrument"
              (onChange)="resetNewInstrumentBrand()"
              optionLabel="name"
            >
              <ng-template let-instrument pTemplate="item">
                <div class="instrument-item">
                  <div
                    *ngIf="instrument.type !== 'other'"
                    class="flaticon {{ instrument.icon }}"
                  ></div>
                  <div
                    *ngIf="instrument.type === 'other'"
                    class="material-icons-outlined"
                  >
                    track_changes
                  </div>
                  <div>{{ instrument.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <label for="instrumentType">Instrument Type</label>
          </span>
        </div>

        <div class="field col-2 md:col-2">
          <span class="p-float-label">
            <p-dropdown
              #instrumentBrand
              id="instrumentBrand"
              [autoDisplayFirst]="
                newInstrument && newInstrument.brands.length === 1
              "
              [options]="newInstrument ? newInstrument.brands : []"
              [(ngModel)]="newInstrumentBrand"
              optionLabel="name"
            >
              <ng-template let-brand pTemplate="item">
                <div class="instrument-item">
                  <img
                    src="{{ 'assets/images/brands/' + brand.type + '.png' }}"
                    [class]="'icon icon-' + brand.type.toLowerCase()"
                  />
                  <div>{{ brand.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <label for="instrumentBrand">Brand</label>
          </span>
        </div>
        <div class="field col-2 md:col-2">
          <span class="p-float-label mb-3">
            <input
              inputTrim
              id="instrumentDescription"
              inputId="instrumentDescription"
              type="text"
              class="w-full"
              pInputText
              [(ngModel)]="newInstrumentName"
            />
            <label for="instrumentDescription">Name</label>
          </span>
        </div>
        <div class="field col-4 md:col-2">
          <span class="p-float-label mb-3">
            <p-inputNumber
              [(ngModel)]="newInstrumentPrice"
              id="instrumentPrice"
              mode="currency"
              [currency]="businessCurrency"
              suffix=" /hr"
            ></p-inputNumber>
            <label for="instrumentPrice">Price</label>
          </span>
        </div>
        <div class="field col-2 md:col-2">
          <span class="mr-3">
            <label for="instrumentPrice">Available :</label>
          </span>
          <p-inputSwitch
            [(ngModel)]="newInstrumentAvailability"
          ></p-inputSwitch>
        </div>
        <div class="field col-2 md:col-1">
          <p-button
            label="Add"
            styleClass="p-button-primary"
            (click)="addNewInstrument()"
          ></p-button>
        </div>
      </div>
    </div>

    <div *ngIf="studioEquipment | async as studioInstruments">
      <div class="formgrid grid" *ngFor="let instrument of studioInstruments">
        <div class="field col-2 md:col-2">
          <span class="p-float-label">
            <p-dropdown
              #instrumentType
              class="w-full"
              [autoDisplayFirst]="false"
              [options]="instruments"
              [(ngModel)]="instrument.equipment.category"
              optionLabel="name"
              optionValue="type"
              [disabled]="true"
            ></p-dropdown>
            <label for="instrumentType">Instrument Type</label>
          </span>
        </div>
        <div class="field col-2 md:col-2">
          <span class="p-float-label mb-3">
            <p-dropdown
              #instrumentBrand
              class="w-full"
              [autoDisplayFirst]="false"
              [options]="instrumentBrandMap.get(instrument.equipment.category)"
              [(ngModel)]="instrument.equipment.brand"
              optionLabel="name"
              optionValue="type"
              [disabled]="true"
            ></p-dropdown>
            <label for="instrumentBrand">Brand</label>
          </span>
        </div>
        <div class="field col-2 md:col-2">
          <span class="p-float-label mb-3">
            <input
              inputTrim
              inputId="instrumentDescription"
              type="text"
              class="w-full"
              pInputText
              [(ngModel)]="
                !instrument.edit
                  ? instrument.equipment.name
                  : instrument.equipment.editName
              "
              [disabled]="!instrument.edit"
            />
            <label for="instrumentDescription">Name</label>
          </span>
        </div>
        <div class="field col-4 md:col-2">
          <span class="p-float-label mb-3">
            <p-inputNumber
              [(ngModel)]="
                !instrument.edit ? instrument.price : instrument.editPrice
              "
              inputId="instrumentPrice"
              mode="currency"
              [currency]="businessCurrency"
              suffix=" /hr"
              [disabled]="!instrument.edit"
            ></p-inputNumber>
            <label for="instrumentPrice">Price</label>
          </span>
        </div>
        <div class="field col-2 md:col-2">
          <span class="mr-3">
            <label for="instrumentPrice">Available :</label>
          </span>
          <p-inputSwitch
            [(ngModel)]="
              !instrument.edit ? instrument.available : instrument.editAvailable
            "
            [disabled]="!instrument.edit"
          ></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="grid grid-nogutter justify-content-around">
      <p-button
        label="Back"
        (onClick)="previousPage()"
        icon="pi pi-angle-left"
        iconPos="left"
      ></p-button>
      <button
        pButton
        class="secondary-button"
        label="Skip"
        (click)="nextPage(true)"
        icon="pi pi-angle-right"
        iconPos="right"
      ></button>
      <p-button
        label="Finish"
        (onClick)="nextPage()"
        icon="pi pi-angle-right"
        iconPos="right"
      ></p-button>
    </div>
  </div>
</div>
