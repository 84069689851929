<div class=" grid bandbeatPage" >
  <div class="col-12" style="background: #252525;">
    <p-steps
      #wizardSteps
      name="setupWizard"
      [(activeIndex)]="activeIndex"
      [model]="items"
      [readonly]="false"
    ></p-steps>
  </div>
  <div class="col-12">
    <router-outlet></router-outlet>
  </div>
</div>
