import { Component, Input, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReviewsService } from "../../../reviews/reviews.service";
import { filter, map } from "rxjs/operators";
import { UserService } from "../user.service";

@Component({
  selector: 'app-user-reviews',
  templateUrl: './user-reviews.component.html',
  styleUrls: ['./user-reviews.component.scss']
})
export class UserReviewsComponent {

  @Input() userId: string;

  public reviews;
  public enableActions = false;
  public cols = [
    {field: 'bookingId', header: 'Booking Id'},
    {field: 'bookingShortId', header: 'Booking ShortId'},
    {field: 'studioName', header: 'Studio Name'},
    {field: 'bookingDate', header: 'Booking Date'}
  ];
  public loading = true;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.reviews = this.userService.reviews$
      .pipe(
        filter(reviews => reviews.results),
        map((reviews) => {
          const groupedBy = reviews.results.reduce((groups, item) => ({
            ...groups,
            [item.booking.id]: [...(groups[item.booking.id] || []), item]
          }), {});

          const mappedBookings = Object.values(groupedBy).map((booking: any[]) => {
            return {
              bookingId : booking[0].booking.id,
              bookingShortId : booking[0].booking.shortId,
              studioName : booking[0].studio.name,
              bookingDate: booking[0].booking.bookingDate,
              reviews : booking,
              userReview : booking.find(rev => rev.category === 'user'),
              partnerReview : booking.find(rev => rev.category === 'partner'),
            };
          })


          return mappedBookings;
        })
      );

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userId = changes.userId.currentValue;
    if (changes.hasOwnProperty('userId') && changes.userId.currentValue) {
      this.userService.fetchUserReviews( this.userId )
        .then((bookings) => {
          this.loading = false;
        });
    }
  }


}
