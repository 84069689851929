<div class="grid">
  <div class="col-12" *ngIf="studio | async as studioData">
    <div class="formgrid grid">
      <div class="col-12">
        <h5>Available Hours</h5>
      </div>

      <ng-template ngFor let-day [ngForOf]="availabilityDays" let-i="index">
        <div class="col field">
          <div class="mb-2 mt-2">{{ day.toUpperCase() }}</div>
          <p-toggleButton
            onLabel="Open"
            offLabel="Closed"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            class="mb-2 w-full"
            [(ngModel)]="studioData.availability[day].available"
          ></p-toggleButton>
          <ng-template [ngIf]="studioData.availability[day].available">
            <br />
            <label class="mb-2 mt-2" for="{{ day }}AvailabilityFrom"
              >From</label
            >
            <br />
            <p-calendar
              id="{{ day }}AvailabilityFrom"
              class="w-full"
              showTime="true"
              [(ngModel)]="studioData.availability[day].from"
              [timeOnly]="true"
              [hourFormat]="'24'"
              [stepMinute]="30"
            ></p-calendar>
            <br />
            <label class="mb-2 mt-2" for="{{ day }}AvailabilityTo">To</label>
            <br />
            <p-calendar
              id="{{ day }}AvailabilityTo"
              class="w-full"
              showTime="true"
              [(ngModel)]="studioData.availability[day].to"
              [timeOnly]="true"
              [hourFormat]="'24'"
              [stepMinute]="30"
            ></p-calendar>
          </ng-template>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="col-12">
    <div class="grid grid-nogutter justify-content-around">
      <p-button
        label="Back"
        (onClick)="previousPage()"
        icon="pi pi-angle-left"
        iconPos="left"
      ></p-button>
      <button
        pButton
        class="secondary-button"
        label="Skip"
        (click)="nextPage(true)"
        icon="pi pi-angle-right"
        iconPos="right"
      ></button>
      <p-button
        label="Next"
        (onClick)="nextPage()"
        icon="pi pi-angle-right"
        iconPos="right"
      ></p-button>
    </div>
  </div>
</div>
