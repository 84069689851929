<div class="grid bandbeatPage">
  <div class="col-12">




    <p-table
      #dt
      [value]="reviews | async"
      dataKey="bookingId"
      [globalFilterFields]="['studioName', 'bookingShortId', 'bookingId']"
      [columns]="cols"
    >
      <ng-template pTemplate="caption">
        <div class="flex p-flex-column md:flex-row md:justify-content-between table-header">
          <h5 style="margin: auto">Manage Reviews</h5>
          <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                          pInputText
                          type="text"
                          (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search..."
                        />
                    </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th style="width: 5rem"></th>
          <ng-template ngFor let-col [ngForOf]="cols" >
            <th [pSortableColumn]="col.field">{{col.header}} <p-sortIcon [field]="col.field"></p-sortIcon></th>
          </ng-template>
          <th></th>
        </tr>
      </ng-template>


      <ng-template pTemplate="body" let-booking let-expanded="expanded">
        <tr>
          <td>
            <button type="button" pButton pRipple [pRowToggler]="booking" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td>{{booking.bookingId}}</td>
          <td>{{booking.bookingShortId}}</td>
          <td>{{booking.studioName}}</td>
          <td>{{booking.bookingDate | date: 'dd/MM/yy hh:mm'}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-booking>
        <tr>
          <td colspan="7">
            <div class="p-p-3">

              <p-dataView [value]="booking.reviews" layout="grid">
                <ng-template let-review pTemplate="gridItem">

                  <div class="col-4 md:col-3 p-5">
                    <app-comment
                      [review]="review"
                    >
                    </app-comment>
                  </div>
                  <div
                    class="col-2 md:col-1 p-5"
                  >
                    <h4>Type : {{review.category}}</h4>
                    <br>
                    <button
                      *ngIf="review.visibility === true"
                      pButton
                      pRipple
                      icon="pi pi-times"
                      class="p-button-rounded p-button-danger mr-2"
                      (click)="changeVisibility(review)"
                    ></button>
                    <button
                      *ngIf="review.visibility === false"
                      pButton
                      pRipple
                      icon="pi pi-check"
                      class="p-button-rounded p-button-success mr-2"
                      (click)="changeVisibility(review)"
                    ></button>
                  </div>

                </ng-template>
              </p-dataView>



            </div>
          </td>
        </tr>
      </ng-template>
  </p-table>








  </div>
</div>
