<div
  class="card p-0 widget-overview-box"
  [ngClass]="colorClass"
>
  <div class="flex align-items-center justify-content-start pt-3 pb-2 px-4">
    <i class="pi pi-{{icon}} text-6xl"></i>
    <div class="pl-3 overflow-hidden">
      <span class="header block white-space-nowrap overflow-hidden">{{title}}</span>
      <span
        class="block text-4xl font-bold"
        [style]="{'color': colorStyle}"
      >{{subtitle}}</span>
    </div>
  </div>
  <img
    [src]="backgroundSVG"
    class="w-full" alt="users">
</div>
