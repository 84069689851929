import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BandsService} from '../../bands.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {filter, map} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-band-invites',
  templateUrl: './band-invites.component.html',
  styleUrls: ['./band-invites.component.scss']
})
export class BandInvitesComponent implements OnInit, OnChanges {

  @Input() bandId: string;
  public env = environment;

  enableActions = false;

  invites: any;
  loading = true;

  constructor(private bandsService: BandsService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.invites = this.bandsService.invites$.pipe(
      filter(el => el.results),
      map((invites) => invites.results.filter(el => el.status !== 'accepted') ),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.bandId = changes.bandId.currentValue;
    if (changes.hasOwnProperty('bandId') && changes.bandId.currentValue) {
      this.bandsService.fetchBandInvites(this.bandId)
        .then((invites) => {
          this.loading = false;
        });
    }
  }

  deleteInvite(invite) {
    this.bandsService.deleteInvite(invite.id )
      .then((data) => {
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Deleted', life: 3000});
        return this.bandsService.fetchBandInvites(this.bandId);
      });

  }

    protected readonly environment = environment;
}
