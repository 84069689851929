<div >
  <p-card>
    <ng-template pTemplate="title">
      Booking Type
    </ng-template>
    <ng-template pTemplate="subtitle">
      Review your Booking
    </ng-template>
    <ng-template pTemplate="content">

      <div class="grid">

        <div
          [ngClass]="{'col-12 md:col-12 lg:col-12' : bookingData.type.bookingType === 'band' , 'col-6 md:col-6 lg:col-6' : bookingData.type.bookingType !== 'band' }"
        >
          <div class="card widget-blog p-0">
            <div class="card-header relative">
            </div>
            <div class="card-content p-4">
              <div class="grid">
                <div
                  [ngClass]="{'col-12' : bookingData.type.bookingType !== 'band' , 'col-6' : bookingData.type.bookingType === 'band' }"
                >
                  <img
                    src="assets/images/sample_booking.png"
                    alt="blog-bg"
                    [ngClass]="{'w-6' : bookingData.type.bookingType === 'band', 'w-12' : bookingData.type.bookingType !== 'band'}"
                    class="h-full"
                  >
                </div>
                <div
                  [ngClass]="{'col-12' : bookingData.type.bookingType !== 'band' , 'col-6' : bookingData.type.bookingType === 'band' }"
                >
                  <div >
                    <p class="font-bold"><i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i> Label : {{bookingData.info.bookLabel}} </p>
                    <p class="font-bold"><i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i> Date : {{bookingData.info.bookDate}} </p>
                    <p class="font-bold"><i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i> Duration : {{bookingData.info.bookDuration}} </p>
                    <p class="font-bold"><i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i> Note : {{bookingData.info.bookNote}} </p>
                    <p *ngIf="bookingData.type.bookingType === 'band'" class="font-bold"><i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i> Required Voting : {{bookingData.info.bookVoting}} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="col-6" *ngIf="bookingData.type.bookingType === 'band'">
          <div class="grid">
            <div class="col-12 md:col-12 lg:col-12 ">
              <div class="card widget-blog p-0">
                <div class="card-header relative">
                  <img
                    [src]="bookingData.type.selectedBand.avatar ? bookingData.type.selectedBand.avatar.link : 'assets/images/sample_band.png'"
                    alt="blog-bg"
                    class="w-full h-full"
                  >
                  <div class="header-right-section flex align-items-center absolute right-0 top-0 p-3">
                    <button pButton type="button" icon="pi pi-angle-left" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold text-white border-white mr-2 p-2"></button>
                    <button pButton type="button" icon="pi pi-angle-right" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold text-white border-white mr-3 p-2"></button>
                    <span class="font-bold text-white">{{bookingData.type.selectedBand.band.media.length + 1}}</span><span class="text-white">/{{bookingData.type.selectedBand.band.media.length + 1}}</span>
                  </div>
                </div>
                <div class="card-content p-4">
                  <div class="flex align-items-center justify-content-between mb-3">
                    <span class="font-bold">{{bookingData.type.selectedBand.band.name}} <i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i></span>
                  </div>
                  <p class="mb-5">{{bookingData.type.selectedBand.band.description}} </p>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-12 lg:col-12">
              <div class="card widget-teamstatus">
                <div class="card-header font-bold pl-2">Bandmates</div>
                <div class="flex flex-wrap align-items-start justify-content-around pt-5">
                  <div class="flex flex-column align-items-center justify-content-center mb-2">
                    <a
                      (click)="showDialog()"
                      style="cursor: pointer"
                      class="new-button border-circle flex align-items-center justify-content-center mb-2">
                      <i class="pi pi-plus"></i>
                    </a>
                    <span class="font-bold text-sm">Add New</span>
                  </div>
                  <div
                    *ngFor="let member of bookingData.type.selectedMembers"
                    class="flex flex-column align-items-center justify-content-center mb-2"
                  >
                    <div class="member border-solid border-circle flex align-items-center justify-content-center relative">
                      <img
                        [src]="member.avatar ? member.avatar.link : 'assets/images/bandbeat/sample_user_logo.png'"
                        width="44"
                        height="44"
                        class="border-circle"
                      >
                      <span class="member-badge absolute">
                    <i
                      *ngIf="member.id !== userId"
                      class="pi pi-times text-sm"
                      style="color:red;background-color:black"
                      (click)="removeMember(member)"
                    ></i>
                    <i
                      *ngIf="member.id === userId"
                      style="color:#27AB83"
                      class="pi pi-check text-sm"
                    ></i>
                  </span>
                    </div>
                    <span class="font-bold">{{member.name}}</span>
                    <span class="subtext text-sm">{{member.email}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="grid">

            <div class="col-12 md:col-12 lg:col-12 ">
              <div class="card widget-blog p-0">
                <div class="card-header relative">
                  <img
                    [src]="bookingData.studio.selectedStudio.studio.avatar ? bookingData.studio.selectedStudio.studio.avatar.link : 'assets/images/sample_studio.png'"
                    alt="blog-bg"
                    class="w-full h-full"
                  >
                  <div class="header-right-section flex align-items-center absolute right-0 top-0 p-3">
                    <button pButton type="button" icon="pi pi-angle-left" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold text-white border-white mr-2 p-2"></button>
                    <button pButton type="button" icon="pi pi-angle-right" class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold text-white border-white mr-3 p-2"></button>
                    <span class="font-bold text-white">{{bookingData.studio.selectedStudio.studio.media?.length + 1}}</span><span class="text-white">/{{bookingData.studio.selectedStudio.studio.media?.length + 1}}</span>
                  </div>
                </div>
                <div class="card-content p-4">
                  <div class="flex align-items-center justify-content-between mb-3">
                    <span class="font-bold">{{bookingData.studio.selectedStudio.studio.label}} <i class="pi pi-check-circle ml-3 text-xl" style="color: #27AB83"></i></span>
                  </div>
                  <p class="mb-5">{{bookingData.studio.selectedStudio.studio.description}} </p>

                  <div
                    *ngIf="bookingData.studio.selectedService && bookingData.studio.selectedService.service"
                    class="flex align-items-center justify-content-between mb-3"
                  >
                    <span class="font-bold">{{bookingData.studio.selectedService.service.name}} - {{bookingData.studio.selectedService.service.price}}<i class="pi pi-money-bill ml-3 text-xl" style="color: #27AB83"></i></span>
                  </div>
                  <div
                    *ngIf="bookingData.studio.selectedService && bookingData.studio.selectedRoom && bookingData.studio.selectedRoom.room"
                    class="flex align-items-center justify-content-between mb-3"
                  >
                    <span class="font-bold">{{bookingData.studio.selectedRoom.room.name}}<i class="pi pi-home ml-3 text-xl" style="color: #27AB83"></i></span>
                  </div>

                  <span
                    *ngIf="bookingData.studio.selectedService && bookingData.studio.selectedRoom && bookingData.studio.selectedRoom.room && bookingData.studio.selectedRoom.room.equipment"
                    class="font-bold mb-5">Room Equipment :  </span>

                  <div
                    *ngIf="bookingData.studio.selectedService && bookingData.studio.selectedRoom && bookingData.studio.selectedRoom.room && bookingData.studio.selectedRoom.room.equipment"
                    class="flex align-items-center justify-content-between mb-3"
                  >
                    <span
                    *ngFor="let equipment of bookingData.studio.selectedRoom.room.equipment"
                    >{{equipment.name}} - {{equipment.description}}</span>
                  </div>

                  <div class="flex align-items-center justify-content-between">
                    <div class="subtext">
                      <i class="pi pi-envelope mr-1"></i>
                      <span>{{bookingData.studio.selectedStudio.studio.email}}</span>
                      <i class="pi pi-phone ml-4 mr-1"></i>
                      <span>{{bookingData.studio.selectedStudio.studio.phoneNumber}}</span>
                      <i class="pi pi-map ml-4 mr-1"></i>
                      <span>{{bookingData.studio.selectedStudio.studio.address}}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>



      </div>




    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-around">
        <p-button label="Previous" (onClick)="previousPage()" icon="pi pi-angle-left" iconPos="left"></p-button>
        <p-button label="Confirm" (onClick)="confirm()" icon="pi pi-angle-right" iconPos="right"></p-button>
      </div>
    </ng-template>
  </p-card>
</div>




<p-dialog [(visible)]="memberDialog" [style]="{width: '450px'}" header="Add Another bandmate" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <label >Members</label>
      <div
        *ngFor="let availableMember of availableMembers"
        class="field-checkbox"
      >
        <p-checkbox
          name="availableMembers"
          [value]="availableMember.user"
          [(ngModel)]="selectedMembers"
          [inputId]="availableMember.id"
        ></p-checkbox>
        <label for="{{availableMember.id}}">{{availableMember.name}}</label>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="addMembers()"></button>
  </ng-template>
</p-dialog>
