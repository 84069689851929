import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-overview-box',
  templateUrl: './overview-box.component.html',
  styleUrls: ['./overview-box.component.scss']
})
export class OverviewBoxComponent implements OnInit {

  @Input() title = '';
  @Input() subtitle = '';
  @Input() color = 'blue';
  @Input() icon = 'users';



  colorClass = 'widget-overview-box-1';
  colorStyle = (this.app.colorScheme === 'light' ? '#035388' : '#1992D4');
  backgroundSVG = 'assets/layout/images/dashboard/users' + (this.app.colorScheme === 'light' ? '' : '-dark') + '.svg';

  constructor(public app: AppComponent) { }

  ngOnInit(): void {
    if (this.color === 'blue') {
      this.colorClass = 'widget-overview-box-1';
      this.colorStyle = (this.app.colorScheme === 'light' ? '#035388' : '#1992D4');
      this.backgroundSVG = 'assets/layout/images/dashboard/users' + (this.app.colorScheme === 'light' ? '' : '-dark') + '.svg';
    } else if (this.color === 'yellow') {
      this.colorClass = 'widget-overview-box-2';
      this.colorStyle = (this.app.colorScheme === 'light' ? '#CB6E17' : '#DE911D');
      this.backgroundSVG = 'assets/layout/images/dashboard/locations' + (this.app.colorScheme === 'light' ? '' : '-dark') + '.svg';
    } else if (this.color === 'green') {
      this.colorClass = 'widget-overview-box-3';
      this.colorStyle = (this.app.colorScheme === 'light' ? '#147D64' : '#27AB83');
      this.backgroundSVG = 'assets/layout/images/dashboard/rate' + (this.app.colorScheme === 'light' ? '' : '-dark') + '.svg';
    } else {
      this.colorClass = 'widget-overview-box-4';
      this.colorStyle = (this.app.colorScheme === 'light' ? '#6551B7' : '#A896FF');
      this.backgroundSVG = 'assets/layout/images/dashboard/interactions' + (this.app.colorScheme === 'light' ? '' : '-dark') + '.svg';
    }
  }

}
