<div class="card">
  <p-toast></p-toast>
  <p-steps
    #bookingSteps
    [model]="items"
    [readonly]="true"
  ></p-steps>
</div>
<router-outlet></router-outlet>

