<p-card
        [style]="{border: '1px solid #938F99', borderRadius : 12}" styleClass="p-card-shadow">
        <div class="grid grid-nogutter align-items-center">
            <div *ngIf = "service.type === 'recording'" class="col-2"><app-filled-icon [icon] = "'keyboard_voice'"></app-filled-icon></div>
            <div *ngIf = "service.type === 'rehearsal'" class="col-2"><app-filled-icon [tnIcon] = "'tnicon-Drums--Percussion'"></app-filled-icon></div>
            <div class="col-6"><h5 style="margin: auto">{{service.type | titlecase}}</h5>
                <div><p>{{room.name}}</p></div>
            </div>
            <div class="col-4" style="color:#FFFFFF; text-align: right;"><strong>€{{service.price}} / hour</strong></div>
        </div>
</p-card>
