import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {StudiosService} from './studios.service';
import {Observable} from 'rxjs';
import {HelperService} from '../common/helper.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-studios',
  templateUrl: './studios.component.html',
  styleUrls: ['./studios.component.scss'],
  providers: [ConfirmationService]
})
export class StudiosComponent implements OnInit {

  enableActions = false;

  studios: Observable<any[]>;

  studio: any;

  submitted: boolean;

  studioDialog: boolean;

  loading = true;

  cols: any[];

  statuses: any[] = [
    {label: 'Active', value: 'active'},
    {label: 'Inactive', value: 'inactive'},
  ]

  constructor(private studiosService: StudiosService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private helperService: HelperService,
              private router: Router ,
              private route: ActivatedRoute
  ) {


    this.cols = [
      {field: 'id', header: 'Id'},
      {field: 'name', header: 'Name'},
      {field: 'owner', header: 'Owner'},
      {field: 'status', header: 'Status'},
      {field: 'phoneNumber', header: 'Phone Number'},
      // {field: 'lastLogin', header: 'Last Login'},
      {field: 'createdAt', header: 'Created'}
    ];
  }

  ngOnInit(): void {
    this.studios = this.studiosService.studios$;
    this.studiosService.fetchStudios({populate : 'owner', limit : 10000}).then((data) => {
      this.loading = false;
    });
  }


  openNew() {
    this.studio = {
      coordinates : {

      }
    };
    this.submitted = false;
    this.studioDialog = true;
  }

  deleteStudio(studio) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected studio?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.studiosService.deleteStudio(studio.id)
          .then((result) => {
            this.messageService.add({
              key: 'globalToast',severity: 'success', summary: 'Successful', detail: 'Studio Deleted', life: 3000});
          });
      }
    });
  }

  saveStudio() {

    this.submitted = true;
    if (this.studio.name.trim()) {
      this.studiosService.addStudio(this.studio)
        .then((result) => {
          this.messageService.add({
            key: 'globalToast',severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000});
          this.studioDialog = false;
          this.studio = {};
        });
    }
  }


  hideDialog() {
    this.studioDialog = false;
    this.submitted = false;
  }

  seeDetails(studio) {
    this.router.navigate([studio.id], { relativeTo: this.route });
  }

  editStudio(studio) {

  }

  enableStudio(studio) {
    let action = '';
    const studioData: any = {
      id : studio.id
    };
    if (studio.status === 'active') {
      studioData.status = 'inactive';
      action = 'disable';
    } else {
      studioData.status = 'active';
      action = 'enable';
    }


    this.confirmationService.confirm({
      message: 'Are you sure you want to ' + action + ' the selected studio?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        this.studiosService.editStudio(studioData)
          .then((result) => {
            this.messageService.add({
              key: 'globalToast',severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000});
          });
      }
    });
  }

}
