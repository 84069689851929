import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../authentication.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import { NotificationsService } from "../../notifications/notifications.service";
import {StudiosService} from "../../studios/studios.service";
import {UserService} from "../../users/user/user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userEmail = '';
  userPassword = '';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private notificationsService: NotificationsService,
    private studiosService: StudiosService,
    private userService: UserService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  public login() {
    this.authenticationService.loginUser(this.userEmail, this.userPassword)
      .then((data) => {

        this.notificationsService.startPolling();
        if (data.studio) {
          this.studiosService.fetchContractsValidity(data.studio.id);
          this.userService.fetchUserRegistrations(data.user.id);
        }
        this.router.navigate(['']);

        setTimeout(() => {
          this.messageService.add(
            {
              key: 'globalToast',
              severity: 'success',
              summary: 'Successful login',
              detail: 'Welcome back ' + data.user.name + '!'
            });
        }, 1000);
      }).catch((err) => {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'Invalid login', detail: 'Wrong email or password'});
    });
  }
}
