import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReviewsService } from "./reviews.service";
import { filter, map } from "rxjs/operators";

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  public reviews;
  public enableActions = true;
  public cols = [
    {field: 'bookingId', header: 'Booking Id'},
    {field: 'bookingShortId', header: 'Booking ShortId'},
    {field: 'studioName', header: 'Studio Name'},
    {field: 'bookingDate', header: 'Booking Date'}
  ];
  constructor(
    private route: ActivatedRoute,
    private reviewsService: ReviewsService,
  ) { }

  ngOnInit(): void {
    this.reviews = this.reviewsService.reviews$
      .pipe(
        filter(reviews => reviews.results),
        map((reviews) => {
          const groupedBy = reviews.results.reduce((groups, item) => {
            if(item.booking === null) return {...groups}
            return {
            ...groups,
            [item.booking.id]: [...(groups[item.booking.id] || []), item]
          }}, {});

          const mappedBookings = Object.values(groupedBy).map((booking: any[]) => {
            return {
              bookingId : booking[0].booking.id,
              bookingShortId : booking[0].booking.shortId,
              studioName : booking[0].studio.name,
              bookingDate: booking[0].booking.bookingDate,
              reviews : booking,
              userReview : booking.find(rev => rev.category === 'user'),
              partnerReview : booking.find(rev => rev.category === 'partner'),
            };
          })


          return mappedBookings;
        })
      );

    this.reviewsService.fetchReviews( true );
  }


  changeVisibility(review) {
    this.reviewsService.editReview(review.id , {visibility : !review.visibility})
  }

}
