<div>
  <p-card *ngIf="!loading">
    <ng-template pTemplate="title">
      Booking Info
    </ng-template>
    <ng-template pTemplate="subtitle">
      Select the Date and Time
    </ng-template>
    <ng-template pTemplate="content">

      <div class="p-fluid field">
        <label for="label">Label</label>
        <input
          id="label"
          type="text"
          pInputText
          [(ngModel)]="bookLabel"
          placeholder="Session Label"
        >
      </div>

      <div class="p-fluid field">
        <label for="datetime">Date & Time</label>
        <p-calendar
          id="datetime"
          [(ngModel)]="bookDate"
          showTime="true"
          [hourFormat]="'24'"
        ></p-calendar>
      </div>

      <div class="p-fluid field">
        <label for="duration">Duration</label>
        <p-inputNumber
          id="duration"
          [(ngModel)]="bookDuration"
          [showButtons]="true"
          [step]="1"
          [min]="1"
          [max]="8"
          buttonLayout="horizontal"
          spinnerMode="horizontal"
          decrementButtonClass="p-button-danger"
          incrementButtonClass="p-button-success"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          mode="decimal"
        ></p-inputNumber>
      </div>

      <div class="p-fluid field"
        *ngIf="bookingData.type.bookingType === 'band'"
      >
        <label for="voting">Require Member Voting</label>
        <p-inputSwitch
          id="voting"
          [(ngModel)]="bookVoting"
        ></p-inputSwitch>

      </div>

      <div class="p-fluid field">
        <label for="notes">Notes</label>
        <textarea
          id="notes"
          pInputTextarea
          [(ngModel)]="bookNote"
        ></textarea>

      </div>


    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-around">
        <p-button label="Previous" (onClick)="previousPage()" icon="pi pi-angle-left" iconPos="left"></p-button>
        <p-button label="Next" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
      </div>
    </ng-template>
  </p-card>
</div>
