<div >
  <p-card>
    <ng-template pTemplate="title">
      Booking Type
    </ng-template>
    <ng-template pTemplate="subtitle">
      Select if you want to book as a band or solo
    </ng-template>
    <ng-template pTemplate="content">
      <div class="p-fluid field">
        <label for="bookingtype">Booking Type</label>
        <p-selectButton
          id="bookingtype"
          [options]="bookingTypes"
          [(ngModel)]="bookingType"
          optionValue="code"
          optionLabel="name"
        ></p-selectButton>
      </div>
      <ng-template [ngIf]="bookingType === 'band'">

        <span>Pick one of your Bands : </span>
        <div class="field">
          <label for="band">Band</label>
          <p-dropdown
            id="band"
            required
            [options]="availableBands"
            [(ngModel)]="selectedBand"
            (onChange)="selectBand($event)"
            optionLabel="name"
          ></p-dropdown>
        </div>

        <ng-template [ngIf]="selectedBand">

          <span>Pick one or more of your BandsM8s : </span>
          <div class="field">
            <label >Members</label>

            <div
              *ngFor="let availableMember of availableMembers"
              class="field-checkbox"
            >

              <p-checkbox
                *ngIf="userId === availableMember.user.id"
                [id]="availableMember.id"
                disabled="true"
                name="availableMembers"
                [value]="availableMember.user"
                [(ngModel)]="selectedMembers"
                [inputId]="availableMember.id"
              ></p-checkbox>

              <p-checkbox
                *ngIf="userId !== availableMember.user.id"
                [id]="availableMember.id"
                name="availableMembers"
                [value]="availableMember.user"
                [(ngModel)]="selectedMembers"
                [inputId]="availableMember.id"
              ></p-checkbox>
              <label for="{{availableMember.id}}">{{availableMember.name}}</label>
            </div>


          </div>

        </ng-template>

      </ng-template>



</ng-template>
<ng-template pTemplate="footer">
  <div class="grid grid-nogutter justify-content-around">
    <p-button label="Next" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
  </div>
</ng-template>
</p-card>
</div>
