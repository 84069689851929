<div class="grid"  *ngIf="contractValidity | async as studioContract">

  <div class="col-12">
    <h1>
      Contracts Agreement
    </h1>
  </div>

  <div class="col-6">

    <p-messages
      [(value)]="contractMessage"
      [closable]="false"
    ></p-messages>

  </div>

  <div class="col-3" style="margin:auto;">

    <ng-template
      [ngIf]="studioContract.valid"
    >
      <button
        pButton
        pRipple
        label="Download"
        icon="pi pi-download"
        class="p-button-success mr-2 mb-2"
        (click)="downloadContract()"
      ></button>
    </ng-template>
    <ng-template
      [ngIf]="!studioContract.valid"
    >
      <button
        pButton
        pRipple
        label="View"
        icon="pi pi-eye"
        class="p-button-success mr-2 mb-2"
        (click)="showContract()"
      ></button>
    </ng-template>
  </div>

</div>


<p-dialog
  header="Header"
  [(visible)]="contractSignModal"
  [style]="{ width: '70vw', overflow: 'auto' }"
  maximizable="true"
  baseZIndex="10000"
  modal="true"
  (onMaximize)="contractModalMaximize($event)"
>
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Bandbeat - New Contract</span>
  </ng-template>


  <div class="grid" >

    <div
      class="col-12"
      [ngStyle]="contractSignModelMaximized ? {
                  height:'80vh'
                }: {height:'40vh'}"
         style=" overflow-y: scroll;">
      <div [innerHTML]="contractData"></div>
    </div>

  </div>

  <ng-template pTemplate="footer">

    <button
      pButton
      style="width:100%"
      pRipple
      label="Agree"
      type="button"
      class="p-button-raised p-button-rounded p-button-lg"
      (click)="signContract()"
    ></button>

  </ng-template>
</p-dialog>
