import {Component, Input, OnInit} from '@angular/core';
import {StudiosService} from '../../studios.service';
import {BookingsService} from '../../../bookings/bookings.service';
import {MessageService} from 'primeng/api';
import {tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';

@Component({
  selector: 'app-studio-services',
  templateUrl: './studio-services.component.html',
  styleUrls: ['./studio-services.component.scss']
})
export class StudioServicesComponent implements OnInit {


  @Input() studioId: string;

  services: any;
  rooms: any;
  loading = true;

  serviceRoomsDialog = false;
  serviceDialog = false;
  serviceName = '';
  servicePrice = 0;
  selectedRooms = [];
  availableRooms = [];
  submitted = false;

  selectedService;
  availableServiceRooms = [];
  selectedServiceSelectedRooms = [];

  constructor(
    private studiosService: StudiosService,
    private bookingsService: BookingsService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.services = combineLatest(
      this.studiosService.services$,
      this.studiosService.rooms$,
      (one: any[], two: any[]) => {
        const serviceWithMappedRooms = one.map((service) => {
          const mappedService = Object.assign({} , service);
          mappedService.rooms = service.rooms.map((serviceRoom) => {
            return two.find((room) => room.id === serviceRoom);
          });
          return mappedService;
        });
        this.availableRooms = two.map((room) => {
          return {
            name : room.name,
            code : room.id,
            room
          };
        });
        this.availableServiceRooms = two.map((room) => {
          return {
            name : room.name,
            key : room.id ,
            room
          };
        });
        return serviceWithMappedRooms;
      }
    ).pipe(
      tap( rooms => this.loading = false),
    );
  }


  refresh() {
    this.studiosService.getServices(this.studioId)
      .then((data) => {
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Refreshed!', life: 3000});
      });

  }

  openNew() {

    this.serviceDialog = true;
    this.serviceName = '';
    this.servicePrice = 0;
    this.selectedRooms = [];
  }

  openNewChangeRooms(service) {
    this.selectedService = service;
    this.selectedServiceSelectedRooms = service.rooms.map(room => room.id);
    this.serviceRoomsDialog = true;
  }

  removeService( service) {
    // this.studiosService.removeService( this.studioId, service.id )
    //   .then((data) => {
    //     this.messageService.add({
    //       key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Service Deleted', life: 3000});
    //   });
  }

  changeRooms(  ) {
    const newRooms = { rooms : this.selectedServiceSelectedRooms};
    // this.studiosService.editService(this.studioId, this.selectedService.id , newRooms)
    //   .then((data) => {
    //     this.messageService.add({
    //       key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Rooms Changed', life: 3000});
    //     this.hideDialog()
    //   });
  }

  addService(){
    const newService = {
      name : this.serviceName,
      price : this.servicePrice,
      rooms : [ ...this.selectedRooms]
    };
    // this.studiosService.createService(this.studioId , newService)
    //   .then((data) => {
    //     this.messageService.add({
    //       key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Service Created', life: 3000});
    //     this.hideDialog();
    //   });
  }
  hideDialog(){
    this.serviceName = '';
    this.servicePrice = 0;
    this.selectedRooms = [];
    this.selectedServiceSelectedRooms = [];
    this.serviceDialog = false;
    this.serviceRoomsDialog = false;

    this.submitted = false;
  }

}
