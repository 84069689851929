<div class="grid bandbeatPage" *ngIf="reports | async as allReports">
  <div class="col-12">


    <p-toolbar>
      <div class="p-toolbar-group-start">
        <p-button
          label="Capture Current State"
          icon="pi pi-plus"
          class="mr-2"
          (onClick)="captureNewReport()"
        ></p-button>
      </div>
      <div
        class="p-toolbar-group-end speeddial-linear"
      >


        <p-speedDial
          [model]="items"
          direction="left"
          showIcon="pi pi-download"
        ></p-speedDial>

        <p-dropdown
          [options]="allReports"
          [(ngModel)]="selectedReport"
          optionLabel="name"
          class="ml-2"
        ></p-dropdown>
      </div>
    </p-toolbar>

    <span>Amount of Reports : {{allReports.length}}</span>





  </div>

  <div class="col-12">
    <p-chart
      type="bar"
      [data]="chartData"
      [options]="chartOptions"
    ></p-chart>

  </div>

</div>
