import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-studio-deletion-complete',
  templateUrl: './studio-deletion-complete.component.html',
  styleUrls: ['./studio-deletion-complete.component.scss']
})
export class StudioDeletionCompleteComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToHomepage() {
    this.router.navigate( ['/' ] );
  }

}
