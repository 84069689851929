<div class="grid bandbeatPage">
  <div class="col-12">
    <h1>
      Change Password
    </h1>
  </div>
  <div class="col-12">
    <div class="grid">
      <div class="col-8 md:col-4">

        <div class="p-fluid formgrid grid">
          <div class="field col-12">
            <span class="p-float-label mb-3">
              <p-password
                [(ngModel)]="currentPassword"
                id="currentPassword"
                inputId="currentPassword"
                [toggleMask]="true"
                class="w-full"
              ></p-password>
              <label for="currentPassword">Current Password</label>
            </span>
          </div>

          <div class="field col-12">
            <span class="p-float-label mb-3">
              <p-password
                [(ngModel)]="newPassword"
                id="newPassword"
                inputId="newPassword"
                [toggleMask]="true"
                class="w-full"
              ></p-password>

              <label for="newPassword">New Password</label>
            </span>
          </div>

          <div class="field col-12">
            <span class="p-float-label mb-3">

                <p-password
                  [(ngModel)]="newPasswordRetry"
                  id="newPasswordRetry"
                  inputId="newPasswordRetry"
                  [toggleMask]="true"
                  class="w-full"
                ></p-password>
              <label for="newPasswordRetry">Retype new Password</label>
            </span>
          </div>

        </div>
      </div>


    </div>
  </div>

  <div class="col-12">
    <div class="grid">

      <div class="col-8 md:col-4">
        <button pButton type="button" class="mr-2 mb-2" label="Save" (click)="changePassword()"></button>
        <button pButton type="button" style="background-color: #1C1B1F ; color: #3CD1C5;" class="mr-2 mb-2" label="Back" (click)="goBack()"></button>
      </div>

    </div>
  </div>

</div>

