import { Injectable } from '@angular/core';
import {HelperService} from '../common/helper.service';
import {ProfileService} from '../common/profile.service';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private userTokens: any = {};

  constructor(
    private helperService: HelperService,
    private profileService: ProfileService,
    private localSt: LocalStorageService,
  ) {
    if (this.localSt.retrieve('tokens')) {
      Object.assign(this.userTokens, this.localSt.retrieve('tokens'));
    }
  }

  loginUser(email: string, password: string) {

    return this.helperService.postAction(
      '/auth/login',
      {email , password}
    ).toPromise()
      .then((data) => {
        if (data.user.role === 'user') {
          throw new Error('test');
        } else {
          this.storeData(data);
          return data;
        }
      });

  }

  registerUser(
    name: string,
    country: string,
    city: string,
    zip: string,
    street: string,
    number: string,
    email: string,
    password: string,
    phoneNumber: number,
    countryCode: string,
    role: string,
    termsAndConditionAccepted: boolean,
    businessName: string,
    studio: any
  ) {

    return this.helperService.postAction(
      '/auth/register',
      {name , businessName, address: {country, city, zip, street,number},  email, password, phoneNumber, countryCode, role, termsAndConditionAccepted, studio}
    ).toPromise()
      .then((data) => {
        this.storeData(data);
        return data;
      });

  }

  requestNewPass(email: string) {

    return this.helperService.postAction(
      '/auth/forgot-password',
      {email , type: 'partner'}
    ).toPromise();

  }

  resetPassword(token: string, password: string) {

    return this.helperService.postAction(
      '/auth/reset-password?token=' + token,
      {password}
    ).toPromise();
  }

  storeData(data) {
    Object.assign(this.userTokens, data.tokens);
    this.localSt.store('tokens', data.tokens);
    this.localSt.store('user', data.user);
    this.profileService.setUserData(data.user);
    if (data.studio) {
      this.helperService.saveLocalStorage('studio' , data.studio);
      this.profileService.setOwnerStudio(data.studio);
    }
    this.helperService.setApplicationState(data.user.role);
  }

  refreshToken() {
    return this.helperService.postAction(
      '/auth/refresh-tokens',
      {refreshToken : this.userTokens.refresh.token }
    ).toPromise()
      .then((data) => {
        Object.assign(this.userTokens, data);
        this.localSt.store('tokens', data);
        return data;
      });
  }

  getTokens() {
    return this.userTokens;
  }

  getToken( type = 'access') {
    return this.userTokens[type].token;
  }

  checkTokenExpiration( type = 'access') {
    return this.userTokens[type].expires;
  }

}
