<p-card
  [style]="{border: '1px solid #938F99', borderRadius : 12}" styleClass="p-card-shadow">

  <div class="grid grid-nogutter align-items-center">
    <div class="col-2"><app-filled-icon [tnIcon]="instrumentsMap.get(instrument.equipment.category)"></app-filled-icon></div>
    <div class="col-6"><h5 style="margin: auto">{{instrument.equipment.brand | titlecase}}</h5>
      <div>{{instrument.equipment.name}}</div>
    </div>
    <div class="col-4" style="color:#FFFFFF"><strong>{{getCurrencySymbol()}}{{instrument.price}} / hour</strong></div>
  </div>
</p-card>
