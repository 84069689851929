<div class="grid bandbeatPage">
  <div class="col-12">

      <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left"  *ngIf="enableActions">
          <button
            pButton
            pRipple
            label="New"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            (click)="openNew()"
          ></button>
          <button
            pButton
            pRipple
            label="Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            (click)="deleteSelectedUsers()"
            [disabled]="!selectedUsers || !selectedUsers.length"
          ></button>
        </ng-template>

        <ng-template pTemplate="right">
          <button
            pButton
            pRipple
            label="Export"
            icon="pi pi-upload"
            class="p-button-success"
            (click)="dt.exportCSV()"
          ></button>
        </ng-template>
      </p-toolbar>

  </div>
  <div class="col-12">
      <p-table
        #dt
        [value]="(users | async)?.results"
        [columns]="cols"
        [rows]="10"
        [paginator]="true"
        [globalFilterFields]="['name','email','phoneNumber']"
        [(selection)]="selectedUsers"
        [rowHover]="true"
        dataKey="id"
        styleClass="p-datatable-customers"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [showCurrentPageReport]="true"
      >
<!--        <ng-template pTemplate="caption">-->
<!--          <div class="flex p-flex-column md:flex-row md:justify-content-between table-header">-->
<!--            <h5 style="margin: auto">Manage Users</h5>-->
<!--            <span class="p-input-icon-left">-->
<!--                        <i class="pi pi-search"></i>-->
<!--                        <input-->
<!--                          pInputText-->
<!--                          type="text"-->
<!--                          (input)="dt.filterGlobal($event.target.value, 'contains')"-->
<!--                          placeholder="Search..."-->
<!--                        />-->
<!--                    </span>-->
<!--          </div>-->
<!--        </ng-template>-->
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem" *ngIf="enableActions">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <ng-template ngFor let-col [ngForOf]="cols" >
              <th [pSortableColumn]="col.field">{{col.header}} <p-sortIcon [field]="col.field"></p-sortIcon></th>

            </ng-template>
            <th></th>
          </tr>

          <tr>
            <th *ngIf="enableActions"></th>
            <th>
              <p-columnFilter type="text" field="id" matchMode="contains" [showMenu]="false"></p-columnFilter>
            </th>

            <th>
              <p-columnFilter type="text" field="name" matchMode="contains" [showMenu]="false"></p-columnFilter>
            </th>

            <th>
              <p-columnFilter field="role" matchMode="equals" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown [ngModel]="value" [options]="roles" (onChange)="filter($event.value)" placeholder="Any" [showClear]="true">
                    <ng-template let-option pTemplate="item">
                      <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>

            <th>
              <p-columnFilter type="text" field="email" matchMode="contains" [showMenu]="false"></p-columnFilter>
            </th>

            <th>
              <p-columnFilter type="text" field="phoneNumber" matchMode="contains" [showMenu]="false"></p-columnFilter>
            </th>
            <th>
<!--              <p-columnFilter type="date" field="lastLogin" ></p-columnFilter>-->
            </th>

            <th></th>
            <th></th>
          </tr>


        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td *ngIf="enableActions">
              <p-tableCheckbox [value]="user"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of cols">
              <span class="p-column-title">{{col.header}}</span>
              <ng-template [ngIf]="col.field === 'lastLogin' || col.field === 'createdAt'">
                {{user[col.field] | date : 'dd/MM/yyyy'}}
              </ng-template>
              <ng-template [ngIf]="col.field !== 'lastLogin' && col.field !== 'createdAt'">
                {{user[col.field]}}
              </ng-template>
            </td>

            <td>
              <button
                pButton
                pRipple
                icon="pi pi-eye"
                class="p-button-rounded p-button-info mr-2"
                (click)="seeUser(user)"
              ></button>
              <button
                pButton
                pRipple
                icon="pi pi-pencil"
                [disabled]="!enableActions"
                class="p-button-rounded p-button-success mr-2"
                (click)="editUser(user)"
              ></button>
              <button
                pButton
                pRipple
                icon="pi pi-trash"
                [disabled]="!enableActions"
                class="p-button-rounded p-button-warning"
                (click)="deleteUser(user)"
              ></button>
            </td>



          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="flex align-items-center justify-content-between">
            In total there are {{ (users | async)?.results ? (users | async)?.results.length : 0 }} users.
          </div>
        </ng-template>
      </p-table>
  </div>
</div>


<p-dialog [(visible)]="userDialog" [style]="{width: '450px'}" header="Product Details" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <img [src]="'assets/demo/images/product/' + user.image" [alt]="user.image" class="product-image" *ngIf="user.image">
    <div class="field">
      <label for="name">Name</label>
      <input type="text" pInputText id="name" [(ngModel)]="user.name" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !user.name">Name is required.</small>
    </div>
    <div class="field">
      <label for="email">Email</label>
      <input type="text" pInputText id="email" [(ngModel)]="user.email" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !user.email">Email is required.</small>
    </div>
    <div class="field">
      <label for="password">Password</label>
      <input type="text" pInputText id="password" [(ngModel)]="user.password" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !user.password">Password is required.</small>
    </div>
    <div class="field">
      <label for="phoneNumber">Phone Number</label>
      <p-inputNumber
        inputId="phoneNumber"
        placeholder="Phone Number"
        prefix="(+30) "
        [useGrouping]="false"
        [(ngModel)]="user.phoneNumber"
        [maxlength]="16"
      ></p-inputNumber>
      <small class="p-invalid" *ngIf="submitted && !user.phoneNumber">Phone Number is required.</small>
    </div>

    <div class="field">
      <label class="mb-3">Role</label>
      <div class="formgrid grid">
        <div class="field-radiobutton col-6">
          <p-radioButton id="category1" name="role" value="admin" [(ngModel)]="user.role"></p-radioButton>
          <label for="category1">Admin</label>
        </div>
        <div class="field-radiobutton col-6">
          <p-radioButton id="category2" name="role" value="user" [(ngModel)]="user.role"></p-radioButton>
          <label for="category2">User</label>
        </div>
        <div class="field-radiobutton col-6">
          <p-radioButton id="category3" name="role" value="partner" [(ngModel)]="user.role"></p-radioButton>
          <label for="category3">Partner</label>
        </div>
      </div>
    </div>


  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveUser()"></button>
  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
