import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {MessageService} from 'primeng/api';
import {NotificationsService} from '../../notifications/notifications.service';
import {UserService} from '../../users/user/user.service';
import {HelperService} from '../../common/helper.service';
import { ContractsService } from "../contract/contracts.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  userName: string;
  userEmail: string;
  userPassword: string;
  userConfirmPassword: string;
  userRole: string = 'partner';
  userPhoneNumber: number;
  termsAndConditionAccepted: boolean = false
  newsletterAccepted: boolean = true;

  studioName: string;
  studioVat: string;

  roles: any[] = [
    {name: 'Admin' , code: 'admin'},
    {name: 'Partner' , code: 'partner'},
    {name: 'User' , code: 'user'}
  ];

  phonePrefix = '';

  public businessCity: string;
  public businessCountry: string;
  public businessName: string;
  public businessNumber: string;
  public businessStreet: string;
  public businessZip: string;
  public businessCurrency: string;

  public showLogin = true;

  public registrationStep = 'user';

  public countries: any[];

  public env = environment;

  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private contractsService: ContractsService,
    private messageService: MessageService,
    private helperService: HelperService
  ) {
    this.countries = this.helperService.getCountriesData();
  }

  ngOnInit(): void {
  }

  public goBack( event ) {
    if (this.registrationStep === 'contract') {
      this.registrationStep = 'business';
    } else if (this.registrationStep === 'business') {
      this.registrationStep = 'user';
    }
  }

  public proceed( event? ) {
    if (event === 'business') {
      this.showLogin = false;
      if (this.userConfirmPassword !== this.userPassword) {
        if(!this.userPassword){
          this.messageService.clear();
          this.messageService.add(
            {
              severity: 'error',
              summary: 'No password',
              detail: 'Please enter a password to continue'
            });
        }else{
          this.messageService.clear();
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Wrong data',
              detail: 'Passwords do not match!'
            });
          }
      } else if (!this.phonePrefix) {
        this.messageService.clear();
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Wrong data',
            detail: 'Please select a country'
          });
      }else if (!this.termsAndConditionAccepted) {
        this.messageService.clear();
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Wrong data',
            detail: 'Please accept Terms & Conditions first to continue!'
          });
      } else if (
        !this.userPhoneNumber ||
        !this.userEmail ||
        !this.userName ||
        !this.studioName
      ) {
        this.messageService.clear();
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Wrong data',
            detail: 'All the fields are required!'
          });
      } else if (this.userPassword.length < 8 || ( !this.userPassword.match(/\d/) || !this.userPassword.match(/[a-zA-Z]/) ) ) {
        this.messageService.clear();
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Weak Password ',
            detail: 'Password must be at least 8 characters containing at least one letter and one number'
          });
      }else {
        this.userService.getUserExistence(this.userEmail).then((data) => {
            if(data.exists){
              this.messageService.clear();
              this.messageService.add(
                {
                  severity: 'error',
                  summary: 'User Exists',
                  detail: 'The email you have entered is already used'
                });
              }else{
                this.messageService.clear();
                this.registrationStep = event;
              }
          }).catch((error) => {
            if(error.error.message === `"usermail" must be a valid email`){
              this.messageService.clear();
              this.messageService.add(
                {
                  severity: 'error',
                  summary: 'Invalid Email',
                  detail: 'Please enter a valid email address'
                });
            }
          });;
        }
    } else if (event === 'contract') {
      if(!this.businessZip.match(/^[a-zA-Z0-9 ]+$/)){
        this.messageService.clear();
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Zip code is wrong',
            detail: 'Please enter only letters and numbers for Zip code'
          });
      }else{
        this.messageService.clear();
        this.registrationStep = event;
      }
    }
  }

  public signup( event ) {


    // if (this.userPassword.length < 8 || ( !this.userPassword.match(/\d/) || !this.userPassword.match(/[a-zA-Z]/) ) ) {
    //   this.messageService.clear();
    //   this.messageService.add(
    //     {
    //       severity: 'error',
    //       summary: 'Wrong data',
    //       detail: 'Password length must be at least 8 and contain at least one letter and one number'
    //     });
    // } else
    // if (!this.termsAndConditionAccepted) {
    //   this.messageService.clear();
    //   this.messageService.add(
    //     {
    //       severity: 'error',
    //       summary: 'Wrong data',
    //       detail: 'Please accept Terms & Conditions first to continue!'
    //     });
    // } else {

      const studioData = {
        name : this.studioName,
        vat : this.studioVat,
        businessCurrency: this.businessCurrency,
      }
      let userData;

      this.authenticationService.registerUser(
        this.userName,
        this.businessCountry,
        this.businessCity,
        this.businessZip,
        this.businessStreet,
        this.businessNumber,
        this.userEmail,
        this.userPassword,
        this.userPhoneNumber,
        this.phonePrefix,
        this.userRole,
        this.termsAndConditionAccepted,
        this.businessName,
        studioData
      )
        .then((data) => {
          if (this.env.contractsEnabled) {
            return this.contractsService.createNewContract()
              .then((contractData) => {
                return Promise.resolve(data);
              });
          } else {
            return Promise.resolve(data);
          }
        })
        .then((data) => {
          userData = data.user;
          if (this.newsletterAccepted) {
            return this.userService.addRegistration(userData.id, {event : 'partner_newsletter'})
          } else {
            return Promise.resolve(data);
          }
        })
        .then((data) => {
          if (userData.role === 'partner') {
            return this.userService.addRegistration(userData.id, {event : 'partner_wizard'})
          } else {
            return Promise.resolve(data);
          }
        })
        .then((data) => {

          this.notificationsService.startPolling();
          this.router.navigate(['verificationpending']);

          setTimeout(() => {
            this.messageService.add(
              {
                key: 'globalToast',
                severity: 'success',
                summary: 'Successful registration',
                detail: 'Welcome back ' + userData.name + ', dont forget to verify your email!'
              });
          }, 1000);
        }).catch((err) => {
        if (this.env.contractsEnabled) {
          this.registrationStep = 'user';
        }
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: err.error.message});
      });
    // }
  }

  public selectCountry(event) {
    console.log(event);
    const selected = this.countries.find(country => this.businessCountry === country.label);
    this.phonePrefix = event.value;
  }

  getUserExistence(){
    const user = this.userService.getUserExistence(this.userEmail);

    return user;
  }

}
