<div class="grid bandbeatPage" *ngIf="booking | async as bookingData">
    <div class="col-12">
      <h2>Booking Request Summary <span *ngIf="bookingData.shortId">#{{bookingData.shortId}}</span></h2>
    </div>
  <div class="col-12 md:col-8">
  <div class="grid">
            <div class="col-12 coverImage">
      <div>
        <img *ngIf="bookingData.bookingType === 'solo'"
             [src]="bookingData.user.hasOwnProperty('avatar') && bookingData.user.avatar ? bookingData.user.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
          alt="studioLogo"
          style=" float:left;border-radius: 50%; width: 100px; height: 100px;vertical-align:middle;"
        />


        <img *ngIf="band | async as bandData"
          [src]="bandData.hasOwnProperty('avatar') && bandData.avatar ? bandData.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
          alt="studioLogo"
          style=" float:left;border-radius: 50%; width: 100px; height: 100px;vertical-align:middle;"
        />


        <ul style="list-style-type: none; float:left;">
          <li>
            <span *ngIf="bookingData.bookingType === 'solo'" style="font-size: 30px; font-weight: bolder;">
                {{bookingData.user.name}}
            </span>

            <span *ngIf="bookingData.bookingType === 'band'" style="font-size: 30px; font-weight: bolder;">
                {{bookingData.team.name}}
            </span>

            <span
              class="option-info  cursor-pointer"
              *ngIf=" env.reviewEnabled && rating"
              (click)="op.toggle($event)"
            >
              <i
                pTooltip="{{bookingData.bookingType === 'band' ? 'Band Rating' : 'Artist Rating'}}"
                class="material-icons-outlined"
                style="padding-top : 10px;"
              >star</i>
              {{rating}} ({{allRatings}})
            </span>

          </li>

        </ul>
      </div>
    </div>
    <div class="col-6 md:col-2">
      <h3>Date{{(bookingData.recurringDates && bookingData.recurringDates.length > 0) ? 's' : ''}}</h3>
      <div>
        <ng-template
          [ngIf]="(bookingData.recurringDates && bookingData.recurringDates.length > 0) === false"
        >
                        <span>
                          {{bookingData.bookingDate  |  date}}
                        </span>
        </ng-template>
        <ng-template
          [ngIf]="(bookingData.recurringDates && bookingData.recurringDates.length > 0)"
        >
                        <span
                          *ngFor="let i of bookingData.recurringDates"
                        >
                            <span>{{i  |  date}}</span>
                            <br>
                        </span>
        </ng-template>
      </div>
    </div>
    <div class="col-6 md:col-2">
      <h3>Time</h3>
      <div>
        <span>
                    {{bookingData.bookingDate | durationParser:bookingData.duration}}
                </span>
      </div>
    </div>
    <div class="col-6 md:col-2">
      <h3>Service</h3>
      <div>
            <span>
                    {{bookingData.service.type | titlecase}}
                </span>
      </div>
    </div>
    <div class="col-6 md:col-2">
      <h3>Room</h3>
      <div>
            <span>
                    {{bookingData.room.name ? bookingData.room.name : 'Room'}}
                </span>
      </div>
    </div>
    <div class="col-6 md:col-2">
      <h3>Contact</h3>
      <div>
        <div>
                    {{bookingData.booker.name}}
                </div>
            <span>
                    {{ (bookingData.booker.phoneNumber && bookingData.booker.phoneNumber !== 6900000000) ? bookingData.booker.phoneNumber : ' N/A' }}
                </span>
      </div>
    </div>


<!--    <div class="col-12">-->
<!--    </div>-->
    <div
      class="col-12 md:col-6"
      *ngIf="userReview && env.reviewEnabled"
    >
      <h3>Review</h3>
      <app-comment
        [review]="userReview"
      >
      </app-comment>
    </div>

  </div>
</div>
<div class="col-12 md:col-4">
  <app-booking-overview
                        [totalPrice]=totalPrice
                        [servicePrice]=totalServicePrice
                        [bookingDiscount]=bookingDiscount
                        [promoCode]="selectedPromoCode"
                        [rentalPrice]=totalRentalPrice
                        [needResponseAction]="bookingData.status === 'pending-confirmation'"
                        [needReviewAction]="needReviewAction"
                        [needReplyAction]="needReplyAction"
                        [booking]="bookingData"
                        [recurringDates]="(bookingData.recurringDates && bookingData.recurringDates.length > 0) ? bookingData.recurringDates : []"
                        (addReviewEventEmitter)="showAddReview($event)"
  ></app-booking-overview>
</div>



<div class="col-12 md:col-3" *ngIf="band | async as bandData">
  <app-band-simple [item]="bandData"
                   ></app-band-simple>
</div>



    <div class="col-12" *ngIf="bookingData.members && bookingData.members.length > 0">
  <h3>Joined Members</h3>

  <p-selectButton [options]="bookingData.members" multiple="multiple" disabled="true">
    <ng-template let-member>
      <p-avatar
        [image]="member.member.avatar ? member.member.avatar['link'] : 'assets/images/sample_member.png'"
        shape="circle"
      ></p-avatar>

      <ng-template
        [ngIf]="member.user"
        [ngIfElse]="memberMember"
      >
        <span style="margin: 0 8px 0 7.5px;">{{member.user.name}}</span>
      </ng-template>
      <ng-template
        #memberMember
      >
        <span style="margin: 0 8px 0 7.5px;">{{member.member.name}}</span>
      </ng-template>

    </ng-template>
  </p-selectButton>
</div>

<div class="col-12" *ngIf="bookingData.instruments && bookingData.instruments.length > 0">

  <h3>Rented Instruments</h3>

  <p-dataView #dvListing
              [value]="bookingData.instruments"
              layout="grid">
    <ng-template let-rental pTemplate="gridItem">
      <div class="col-12 md:col-3">
        <div class="equipment-list-item">
          <app-instrument style="width: inherit"
                          [instrument]="rental"></app-instrument>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>

<div class="col-12 md:col-8" *ngIf="bookingData.notes.length > 0">
  <h3>Notes to the studio</h3>
  <textarea
    [(ngModel)]="bookingData.notes[0].message"
    [rows]="5"
    [cols]="30"
    style="width:100%"
    pInputTextarea
                disabled="true"
    [autoResize]="true"
  ></textarea>
</div>
</div>
<div class="grid grid-nogutter next-buttons bandbeatPage">
  <p-button label="Back" (onClick)="back()" styleClass="secondary-button" icon="pi pi-angle-left"
            iconPos="left"></p-button>
</div>







<p-dialog [(visible)]="reviewVisible" [style]="{width: '450px'}" header="Review your booking" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <!--        <img [src]="bandData.hasOwnProperty('avatar') && bandData.avatar ? env.uploadUrl + bandData.avatar.link : 'assets/images/bandbeat/sample_user_logo.png'" style="width:100%;height:auto;" alt="new member" class="product-image" >-->
    <div class="field">
      <label for="rating">Add a Rating</label>
      <p-rating [cancel]=false id="rating" [(ngModel)]="reviewRating"></p-rating>

      <small class="p-invalid" *ngIf="!reviewRating">Rating is required.</small>
    </div>
    <div class="field">
      <label for="reviewnote">Add a note</label>
      <textarea
        [(ngModel)]="reviewNote"
        [rows]="5"
        [cols]="30"
        style="width:100%"
        id="reviewnote"
        pInputTextarea
        [autoResize]="true"
      ></textarea>
    </div>

  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="reviewVisible = false"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="addReview()"></button>
  </ng-template>
</p-dialog>



<p-dialog
  [(visible)]="replyVisible"
  [style]="{width: '450px'}"
  header="Reply to User's Review"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <!--        <img [src]="bandData.hasOwnProperty('avatar') && bandData.avatar ? env.uploadUrl + bandData.avatar.link : 'assets/images/bandbeat/sample_user_logo.png'" style="width:100%;height:auto;" alt="new member" class="product-image" >-->
    <div class="field">
      <label for="reviewnote">Add a reply</label>
      <textarea
        [(ngModel)]="replyNote"
        [rows]="5"
        [cols]="30"
        style="width:100%"
        id="replyote"
        pInputTextarea
        [autoResize]="true"
      ></textarea>
    </div>

  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="replyVisible = false"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="addReply()"></button>
  </ng-template>
</p-dialog>



<p-overlayPanel #op>
  <ng-template pTemplate>
    <ul *ngIf="ratings && ratings.length > 0" style="padding: 0px;">
      <li *ngFor="let item of ratings; let i = index;" style="list-style: none;">
        <span style="display:block;">{{item.createdAt | dateParser: 'yyyy-MM-dd'}} : {{item.note}} </span>
      </li>
    </ul>
  </ng-template>
</p-overlayPanel>
