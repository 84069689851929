<div class="grid bandbeatPage"  *ngIf="user | async as userData">

  <div class="col-12 lg:col-4">

    <div class="card widget-list">
      <ng-template ngFor let-child let-i="index" [ngForOf]="settingsTypes">

        <div
          (click)="selectedSettingType = child.code"
          style="padding: 12px;cursor: pointer;"
          [ngClass]="{'widget-option' : selectedSettingType === child.code}"
          class="mb-4 px-3 flex align-items-center justify-content-start">
          <div class="option-title">
            <i
              class="material-icons-outlined"
            >{{child.icon}}</i> <span> {{child.name}} </span>
          </div>
          <div class="option-info" >
            <i pTooltip="{{child.info}}" class="material-icons-outlined">info</i>
          </div>
        </div>

      </ng-template>
    </div>

  </div>

  <div class="col-12 lg:col-8">
    <div class="grid">

      <ng-template
        [ngIf]="selectedSettingType === 'account'"
      >
        <div class="col-12">
          <h1>
            Account settings
          </h1>
        </div>
        <div class="col-12">

          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6">
            <span class="p-float-label mb-3">
              <input inputTrim
                [(ngModel)]="userData.name"
                id="userName"
                inputId="userName"
                type="text" pInputText
                class="w-full"
              >
            <label for="userName">Name</label>
            </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label mb-3">
                <input inputTrim
                  [(ngModel)]="userData.lastName"
                  id="userLastName"
                  inputId="userLastName"
                  type="text" pInputText
                  class="w-full"
                >
              <label for="userLastName">Last Name</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label mb-3">
                <p-inputNumber
                  id="userPhonenumber"
                  inputId="userPhonenumber"
                  placeholder="Phone Number"
                  [prefix]="phonePrefix"
                  class="w-full"
                  [useGrouping]="false"
                  [(ngModel)]="userData.phoneNumber"
                ></p-inputNumber>
                <label for="userPhonenumber">Phone Number</label>
              </span>

            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label mb-3">
                <input inputTrim
                  [(ngModel)]="userData.email"
                  id="userEmail"
                  inputId="userEmail"
                  type="text" pInputText
                  class="w-full"
                >
                <label for="userEmail">Email</label>
              </span>
            </div>


          </div>

        </div>

        <div class="field col-12">
          <span
            for="newsletter"
            style="padding-right: 5em;color: #B1EDE8 !important"
          >Subscribe to newsletter</span>
          <p-checkbox
            id="newsletter"
            inputId="newsletter"
            [binary]="true"
            [(ngModel)]="userNewsletter"
          ></p-checkbox>
        </div>

        <div class="col-12">

          <button
            pButton
            type="button"
            class="mr-2 mb-2 settingsButtons"
            label="Save"
            (click)="saveChanges()"></button>
          <button
            pButton type="button"
            class="mr-2 mb-2 secondary-button settingsButtons"
            label="Change Password"
            iconPos="left"
            icon="pi pi-unlock"
            (click)="toChangePassword()"></button>
          <button
            pButton
            type="button"
            class="mr-2 mb-2 p-button p-button-danger p-button-text settingsButtons" label="Delete my account"
            (click)="deleteAccount()"
          ></button>
        </div>



        <div class="col-12">
          <h1>
            Business Information
          </h1>
        </div>

        <div class="col-12">

          <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-12">
                  <label style="color: #B1EDE8 !important;" for="businessName">Business Name</label>
                  <input inputTrim
                    pInputText
                    disabled="true"
                    [(ngModel)]="userData.businessName"
                    id="businessName" type="text" class="w-full">
                </div>
                <div class="field col-12 md:col-6">
                  <label style="color: #B1EDE8 !important;" for="street">Street</label>
                  <input inputTrim
                    pInputText
                    disabled="true"
                    [(ngModel)]="userData.address.street"
                    id="street" type="text" class="w-full">
                </div>
                <div class="field col-6 md:col-2">
                  <label style="color: #B1EDE8 !important;" for="number">Number</label>
                  <input inputTrim
                    pInputText
                    disabled="true"
                    [(ngModel)]="userData.address.number"
                    id="number" type="text" class="w-full">
                </div>

                <div class="field col-6 md:col-4 ">
                  <label style="color: #B1EDE8 !important;" for="zip">Zip Code</label>
                  <input inputTrim
                    pInputText
                    disabled="true"
                    [(ngModel)]="userData.address.zip"
                    id="zip" type="text" class="w-full">
                </div>

                <div class="field col-12 md:col-6">
                  <label style="color: #B1EDE8 !important;" for="city">City</label>
                  <input inputTrim
                    pInputText
                    disabled="true"
                    [(ngModel)]="userData.address.city"
                    id="city" type="text" class="w-full">
                </div>

                <div class="field col-3">
                  <label style="color: #B1EDE8 !important;" for="country">Country</label>
                  <input inputTrim
                    pInputText
                    disabled="true"
                    [(ngModel)]="userData.address.country"
                    id="country" type="text" class="w-full">
                </div>

                <div class="field col-3">
                  <label style="color: #B1EDE8 !important;" for="businessCurrency">Currency</label>
                  <input inputTrim
                         pInputText
                         disabled="true"
                         [(ngModel)]="businessCurrency"
                         id="businessCurrency" type="text" class="w-full">
                </div>

                <div class="field col-12" style="padding:1rem;">
                  <span style="padding-right: 5em;" >
                    In case you wish to change the above information, you can <a style="color: #B1EDE8 !important;" href="mailto:studio.support@bandbeat.com">contact us</a> with the changes you want to request
                  </span>
                </div>
          </div>
        </div>






          <app-studio-contracts style="width:100%">

          </app-studio-contracts>


      </ng-template>

      <ng-template
        [ngIf]="selectedSettingType === 'payments'"
      >
        <div class="col-12" *ngIf="env.paymentEnabled">
          <app-studio-payments></app-studio-payments>
        </div>
      </ng-template>

      <ng-template
        [ngIf]="selectedSettingType === 'reports'"
      >
        <div class="col-12" *ngIf="env.paymentEnabled">
          <app-studio-monthly-reports></app-studio-monthly-reports>
        </div>
      </ng-template>



    </div>
  </div>

</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

