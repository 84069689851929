import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { HelperService } from "../../common/helper.service";

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  private contracts: BehaviorSubject<any>;
  public readonly contracts$: Observable<any>;

  private contract: BehaviorSubject<any>;
  public readonly contract$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private dataStore: {
    contracts: any;
    contract: any;
  };

  constructor( private helperService: HelperService ) {

    this.contracts = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.contracts$ = this.contracts.asObservable();

    this.contract = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.contract$ = this.contract.asObservable();


    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.dataStore = {
      contracts: [],
      contract: {},
    };

  }

  fetchContracts( ) {
    return this.helperService.getAction('/contracts' ,  )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.contracts, data);
        this.contracts.next(data);
        return data;
      });
  }

  fetchContract( contractId  ) {
    return this.helperService.getAction('/contracts/' + contractId , {type : 'content'} )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.contract, data);
        this.contract.next(data);
        return data;
      });
  }

  fetchLatestTemplate( tokens = {}  ) {
    return this.helperService.getAction('/contracts/template' , Object.assign(tokens , {type : 'content'} ) )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.contract, data);
        this.contract.next(data);
        return data;
      });
  }

  createNewContract() {
    return this.helperService.postAction('/contracts' , {category : 'partner'}  )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.contract, data);
        this.contract.next(data);
        return data;
      });
  }

}
