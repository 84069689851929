import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BandsService} from '../../../bands/bands.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {filter, map} from 'rxjs/operators';
import {UserService} from '../user.service';
import {BookingsService} from '../../../bookings/bookings.service';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-user-bookings',
  templateUrl: './user-bookings.component.html',
  styleUrls: ['./user-bookings.component.scss']
})
export class UserBookingsComponent implements OnInit, OnChanges {

  @Input() userId: string;
  public env = environment;

  bookings: any;
  loading = true;

  constructor(
              private userService: UserService,
              private bookingsService: BookingsService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private router: Router ,
              private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.bookings = this.userService.bookings$.pipe(
      filter( bookings => Object.keys(bookings).length !== 0),
      map((bookings) => {
        const results = bookings.results;
        results.forEach((booking) => {
          booking.studio.studioLogo = booking.studio.media.find(e => e.metadata.type === 'logo');
          booking.needsResponse = false;
          booking.needsConfirmation = false;
          booking.canBeDeleted = false;
          if (booking.bookingType === 'band') {
            const currentMember = booking.members.find(mbr => mbr.member.id === this.userId);
            booking.currentMember = currentMember;
            if (
              booking.status === 'planning' &&
              booking.booker.id !== this.userId &&
              booking.currentMember.vote === 'pending'
            ) {
              booking.needsResponse = true;
            }
            if (
              booking.status === 'planning-complete' &&
              booking.booker.id === this.userId
            ) {
              booking.needsConfirmation = true;
            }
          }
          if (
            booking.booker.id !== this.userId &&
            ( booking.status === 'pending' ||
              booking.status === 'planning-complete' ||
              booking.status === 'pending-confirmation'
            )
          ) {
            booking.canBeDeleted = true;
          }
        });
        return results;
      } )
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userId = changes.userId.currentValue;
    if (changes.hasOwnProperty('userId') && changes.userId.currentValue) {
      this.userService.fetchUserBookings(this.userId)
        .then((bookings) => {
          this.loading = false;
        });
    }
  }


  deleteBooking(booking): void {
    this.bookingsService.deleteBooking(booking.id);
  }

  addMember(): void {

  }

  acceptBooking(booking): void {
    this.bookingsService.acceptMemberBooking(booking.id)
      .then((data) => {
        const currentMember = data.members.find(mbr => mbr.member === this.userId);
        booking.currentMember.vote = currentMember.vote;
        booking.status = data.status;
        booking.needsResponse = false;
      });

  }

  declineBooking(booking): void {
    this.bookingsService.declineMemberBooking(booking.id)
      .then((data) => {
        const currentMember = data.members.find(mbr => mbr.member === this.userId);
        booking.currentMember.vote = currentMember.vote;
        booking.status = data.status;
        booking.needsResponse = false;
      });
  }

  confirmBooking(booking): void {
    this.bookingsService.editBooking(booking.id , {status : 'pending-confirmation'})
      .then((data) => {
        booking.status = data.status;
        booking.needsConfirmation = false;
      });
  }

  restartBooking(booking): void {
    this.router.navigate(
      ['dashboard/restartbooking'],
      {
        queryParams: { restartBooking: booking.id }
      });

  }

  seeDetails(booking) {
    this.router.navigate(['bookings', booking.id], { relativeTo: this.route });
  }

  protected readonly environment = environment;
}
