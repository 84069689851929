<div class="grid" *ngIf="studio | async as studioData">
  <div class="col-8 col-offset-2">

    <img
      [style]="{'max-height': '500px' , 'width' : '100%', 'height':'auto'} "
      class="m-5 border-round-3xl"
      [src]="studioData.hasOwnProperty('cover') && studioData.cover ? studioData.cover['link'] : 'assets/images/bandbeat/sample_studio_logo.png'"
      alt="User Image"
    >

  </div>
  <div class="col-8 col-offset-2">
    <div class="grid">
        <div class="col-4">
          Name : {{studioData.name}}
        </div>
        <div class="col-4">
          Label : {{studioData.label}}
        </div>
        <div class="col-4">
          Description : {{studioData.description}}
        </div>
      <div class="col-4">
        Address : {{studioData.address.street}} {{studioData.address.number}}, {{studioData.address.city}} {{studioData.address.zip}} - {{studioData.address.country}}
      </div>
      <div class="col-4">
        VAT : {{studioData.vat}}
      </div>
        <div class="col-4">
          Email : {{studioData.email}}
        </div>
        <div class="col-4">
          PhoneNumber : {{studioData.phoneNumber}}
        </div>
        <div class="col-4">
          Facebook : {{studioData.facebookHandle}}
        </div>
        <div class="col-4">
          Instagram : {{studioData.instagramHandle}}
        </div>


      <div class="col-12"  *ngIf="contractValidity | async as studioContract">
        Contracts Agreement

        <p-messages
          [(value)]="contractMessage"
          [closable]="false"
        ></p-messages>


      </div>


      </div>
  </div>

  <div class="col-12">
      <p-tabView styleClass="tabview-custom  m-5">
        <p-tabPanel>

          <ng-template pTemplate = "header">
            <i class="pi pi-user"></i>
            <span>Owner</span>
          </ng-template>

          <div class="grid m-5">
            <div class="col-4">
              Id : {{studioData.owner.id}}
            </div>
            <div class="col-4">
              Name : {{studioData.owner.name}} {{studioData.owner.lastName}}
            </div>
            <div class="col-4">
              Phone Number : {{studioData.owner.phoneNumber}}
            </div>
            <div class="col-4">
              Email : {{studioData.owner.email}}
            </div>
            <div class="col-4">
              Joined : {{studioData.owner.createdAt | date:'dd/MM/yyyy'}}
            </div>
            <div class="col-4">
              Last Logged in : {{studioData.owner.lastLogin | date:'dd/MM/yyyy'}}
            </div>
          </div>


        </p-tabPanel>
        <p-tabPanel>

          <ng-template pTemplate = "header">
            <i class="pi pi-calendar"></i>
            <span>Instruments</span>
          </ng-template>

          <p-dataView #dvListingInst
                      [value]="studioData.instruments"
                      layout="grid">
            <ng-template let-instrument pTemplate="gridItem">
              <div class="col-12 md:col-6 lg:col-3">
                <div class="equipment-list-item">

                  <app-instrument style="width: inherit"
                                  [instrument]="instrument" ></app-instrument>
                </div>
              </div>
            </ng-template>
          </p-dataView>


        </p-tabPanel>
        <p-tabPanel>

          <ng-template pTemplate = "header">
            <i class="pi pi-calendar"></i>
            <span>Rooms</span>
          </ng-template>

          <app-studio-rooms
            [studioId]="studioId"
          ></app-studio-rooms>

        </p-tabPanel>
        <p-tabPanel>

          <ng-template pTemplate = "header">
            <i class="pi pi-calendar"></i>
            <span>Bookings</span>
          </ng-template>

          <div class="grid">
            <div class="col-12">
              <p-table
                #dt
                [value]="(studioBookings | async)?.results"
                [columns]="bookingsCols"
                [rows]="10"
                [paginator]="true"
                [globalFilterFields]="['name','email','phoneNumber']"
                [rowHover]="true"
                dataKey="id"
                styleClass="p-datatable-customers"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true"
              >
                <ng-template pTemplate="caption">
                  <div class="flex p-flex-column md:flex-row md:justify-content-between table-header">
                    <h5 style="margin: auto">Manage Users</h5>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                          pInputText
                          type="text"
                          (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search..."
                        />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 3rem" *ngIf="enableActions">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <ng-template ngFor let-col [ngForOf]="bookingsCols" >
                      <th [pSortableColumn]="col.field">{{col.header}} <p-sortIcon [field]="col.field"></p-sortIcon></th>

                    </ng-template>
                    <th></th>
                  </tr>

                  <tr>
                    <th *ngIf="enableActions"></th>
                    <th>
                      <p-columnFilter type="text" field="id" matchMode="contains" [showMenu]="false"></p-columnFilter>
                    </th>

                    <th>
                      <p-columnFilter type="text" field="shordId" matchMode="contains" [showMenu]="false"></p-columnFilter>
                    </th>

                    <th>
                      <p-columnFilter type="text" field="status" matchMode="contains" [showMenu]="false"></p-columnFilter>

                    </th>

                    <th>
                      <p-columnFilter type="text" field="type" matchMode="contains" [showMenu]="false"></p-columnFilter>
                    </th>

                    <th>
                    </th>
                    <th>
                    </th>
                    <th></th>
                  </tr>


                </ng-template>
                <ng-template pTemplate="body" let-booking>
                  <tr>
                    <td *ngIf="enableActions">
                      <p-tableCheckbox [value]="booking"></p-tableCheckbox>
                    </td>
                    <td *ngFor="let col of bookingsCols">
                      <span class="p-column-title">{{col.header}}</span>
                      <ng-template [ngIf]="col.field === 'bookingDate' || col.field === 'createdAt'">
                        {{booking[col.field] | date : 'MM/dd/yyyy hh:mm'}}
                      </ng-template>
                      <ng-template [ngIf]="col.field !== 'bookingDate' && col.field !== 'createdAt'">
                        {{booking[col.field]}}
                      </ng-template>
                    </td>



                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="flex align-items-center justify-content-between">
                    In total there are {{ (studioBookings | async)?.results ? (studioBookings | async)?.results.length : 0 }} users.
                  </div>
                </ng-template>
              </p-table>
            </div>
          </div>

        </p-tabPanel>


        <p-tabPanel >

          <ng-template pTemplate = "header">
            <i class="pi pi-user"></i>
            <span>Reviews</span>
          </ng-template>



          <p-dataView #dvLViewReviews
                      [value]="(studioReviews | async)?.results"
                      layout="grid">
            <ng-template let-review pTemplate="gridItem">
              <div class="col-12 md:col-6 lg:col-3">
                <div class="equipment-list-item">

                  <div class="grid grid-nogutter">
                    <div class="col-12">
                      Booking Short Id : {{review.booking.shortId}}
                    </div>
                    <div class="col-12">
                      Booking Date : {{review.booking.bookingDate}}
                    </div>
                    <div class="col-12">
                      <app-comment
                        [review]="review"
                      >
                      </app-comment>
                    </div>
                    <div class="col-12">

                      <button
                        pButton
                        [label]="review.visibility ? 'Hide' : 'Show'"
                        (click)="toggleVisibility(review)"
                        class="mt-5"
                        [ngClass]="{
                        'p-button-success ' : review.visibility === false ,
                        'p-button-danger ' : review.visibility === true
                        }"
                        ></button>

                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-dataView>


        </p-tabPanel>


      </p-tabView>
  </div>


</div>


<!--<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>-->
