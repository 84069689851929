<p-dataView #dv
            [value]="(bookings | async)"
            [paginator]="true"
            [rows]="9"
            filterBy="invite.user.name"
            [loading]="loading"
            layout="list">
  <ng-template pTemplate="header">

  </ng-template>

  <ng-template let-booking pTemplate="listItem">
    <div class="col-12">
      <div class="product-list-item">

        <img [src]="booking.studio.studioLogo ? booking.studio.studioLogo['link'] : 'assets/images/sample_studio.png'"  [alt]="booking.label"/>
        <div class="product-list-detail">
          <div class="product-name">{{booking.studio.label}}</div>
          <div>
            <span>Type : {{booking.service.type}}</span>
<!--            <i style="margin-left: 0.5em;" class="pi pi-phone product-category-icon"></i><span class="product-category">{{booking.studio.label}}</span>-->
<!--            <i style="margin-left: 0.5em;" class="pi pi-at product-category-icon"></i><span class="product-category">{{booking.studio.address}}</span>-->
            <br>
            <i style="margin-left: 0.5em;" class="pi pi-calendar-plus product-category-icon"></i><span class="product-category">Booked at {{booking.bookingDate | date : 'dd/MM/yyyy'}}</span>
            <br>
<!--            <p-tag-->
<!--              [value]="'Booked at '+ (booking.bookingDate | date : 'dd/MM/yyyy') "-->
<!--              icon="pi pi-calendar"-->
<!--            ></p-tag>-->

            <p-tag
              severity="success"
              *ngIf="booking.bookingType === 'solo'"
              value="Solo Booked"
              icon="pi pi-user"
            ></p-tag>
            <p-tag
              severity="success"
              *ngIf="booking.bookingType === 'band'"
              [value]="'Band '+booking.team.name"
              icon="pi pi-users"
            ></p-tag>


            <p-tag
              severity="warning"
              *ngIf="booking.bookingType === 'band'"
              [value]="'Response '+booking.currentMember.vote"
              icon="pi pi-user"
            ></p-tag>

          </div>


        </div>

        <div class="product-list-action">

          <span *ngIf="booking.status === 'planning'" [class]="'product-badge status-lowstock '">PLANNING</span>
          <span *ngIf="booking.status === 'planning-complete'" [class]="'product-badge status-stock '">PLANNING COMPLETE</span>
          <span *ngIf="booking.status === 'pending-confirmation'" [class]="'product-badge status-stock'">TO BE CONFIRMED</span>
          <span *ngIf="booking.status === 'studio-confirmed'" [class]="'product-badge status-instock'">CONFIRMED</span>
          <span *ngIf="booking.status === 'studio-declined'" [class]="'product-badge status-outofstock'">REJECTED</span>


          <br>


          <p-button
            *ngIf="(
            booking.canBeDeleted === true
            )"

            pRipple
            styleClass="p-button-danger mr-2 mb-2"
            icon="pi pi-minus-circle"
            label="Delete Booking"
            (click)="deleteBooking(booking)"
          ></p-button>


          <p-button
            *ngIf="(booking.needsResponse === true)"
            pRipple
            styleClass="p-button-success mr-2 mb-2"
            icon="pi pi-plus-circle"
            label="Accept Booking"
            (click)="acceptBooking(booking)"
          ></p-button>



          <p-button
            *ngIf="(booking.needsResponse === true)"
            pRipple
            styleClass="p-button-danger mr-2 mb-2"
            icon="pi pi-minus-circle"
            label="Decline Booking"
            (click)="declineBooking(booking)"
          ></p-button>



          <p-button
            *ngIf="(booking.needsResponse === true)"
            pRipple
            styleClass="p-button-warning mr-2 mb-2"
            icon="pi pi-circle-off"
            label="Restart Booking"
            (click)="restartBooking(booking)"
          ></p-button>

          <p-button
            *ngIf="(booking.needsConfirmation === true)"
            pRipple
            styleClass="p-button-info mr-2 mb-2"
            icon="pi pi-plus-circle"
            label="Send to Studio"
            (click)="confirmBooking(booking)"
          ></p-button>

          <p-button
            styleClass="p-button-info mr-2 mb-2"
            icon="pi pi-arrow-circle-right"
            label="See Details"
            (onClick)="seeDetails(booking)"
          ></p-button>

          <!--          <p-button-->
          <!--            pRipple-->
          <!--            styleClass="p-button-danger mr-2 mb-2"-->
          <!--            icon="pi pi-minus-circle"-->
          <!--            label="Delete Booking"-->
          <!--            (click)="deleteInvite(invite)"-->
          <!--          ></p-button>-->

        </div>


      </div>
    </div>
  </ng-template>



</p-dataView>




