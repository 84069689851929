import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReportsService } from "./reports.service";
import { filter, map, tap } from "rxjs/operators";
import { HelperService } from "../common/helper.service";
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  public reports;
  public selectedReport;

  public chartData;
  public chartOptions;


  public items = [
    {
      icon: 'pi pi-calendar-plus',
      command: () => {
        this.downloadFile('bookings')
      }
    },
    {
      icon: 'pi pi-database',
      command: () => {
        this.downloadFile('studios')
      }
    },
    {
      icon: 'pi pi-user',
      command: () => {
        this.downloadFile('users')
      }
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private helperService: HelperService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {

    const textColor = '#E6E1E5';
    const textColorSecondary = '#E6E1E5';
    const surfaceBorder = '#252525';

    this.reports = this.reportsService.reports$
      .pipe(
        filter(reports => reports.results),
        tap((reports) => {
          this.selectedReport = reports.results[0];
        }),
        map((reports) => {

          this.chartData = {
            labels: [],
            datasets: [
            ]
          };

          const userDataset = {
            label : 'User' ,
            data : [],
            backgroundColor: '#3CD1C5',
            borderColor: '#3CD1C5',
          }
          const studioDataset = {
            label : 'Studio' ,
            data : [],
            backgroundColor: '#6c4796',
            borderColor: '#6c4796',
          }
          const bookingsDataset = {
            label : 'Booking' ,
            data : [],
            backgroundColor: '#61b23b',
            borderColor: '#61b23b',
          }
          reports.results.toReversed().slice(-5).forEach((report) => {
            this.chartData.labels.push( this.helperService.formatDate(report.metadata.reportDate) );
            userDataset.data.push(report.metadata.users);
            studioDataset.data.push(report.metadata.studios);
            bookingsDataset.data.push(report.metadata.bookings);
          })
          this.chartData.datasets = [userDataset , studioDataset , bookingsDataset];


          this.chartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
              legend: {
                labels: {
                  color: textColor
                }
              }
            },
            scales: {
              x: {
                ticks: {
                  color: textColorSecondary,
                  font: {
                    weight: 500
                  }
                },
                grid: {
                  color: surfaceBorder,
                  drawBorder: false
                }
              },
              y: {
                ticks: {
                  color: textColorSecondary
                },
                grid: {
                  color: surfaceBorder,
                  drawBorder: false
                }
              }

            }
          };



          return reports.results.map((report) => {
            return { "name": this.helperService.formatDate(report.metadata.reportDate), "value": report }
          })
        }),
      )

    this.reportsService.fetchReports();
  }

  downloadFile(type : string): void {
    this.reportsService.downloadCSVReport(this.selectedReport.value.report[type] , type)
    this.messageService.add(
      {
        key: 'globalToast',
        severity: 'success',
        summary: 'Update',
        detail: 'Data Updated '  + type
      });
  }

  captureNewReport() {
    this.reportsService.createBandbeatReport()
      .then((data) => {
        this.messageService.add(
          {
            key: 'globalToast',
            severity: 'success',
            summary: 'Success',
            detail: 'New Report Created'
          });
      })
  }

}
