import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  bookDate;

  featuresItems = [
    {
      image: '../../assets/images/landing/profile.png',
      title: 'Studio <br> <strong>profile page</strong>',
    },
    {
      image: '../../assets/images/landing/notes.png',
      title: 'Detailed <br> <strong>booking requests</strong>',
    },
    {
      image: '../../assets/images/landing/calendar.png',
      title: 'Standalone <br> <strong>automated calendar</strong>',
    },
    {
      image: '../../assets/images/landing/lock2.png',
      title: '<strong>Secure online payment</strong> <br> & <strong>cancellation</strong> policy feature',
    },
    {
      image: '../../assets/images/landing/rating.png',
      title: 'Internal <strong>review /<br> rating system</strong>',
    },
    {
      image: '../../assets/images/landing/analytics.png',
      title: 'Monthly <br><strong>analytics</strong>',
    },
    {
      image: '../../assets/images/landing/control.png',
      title: '<strong>Total control</strong> over bookings,<br>prices, discounts',
    },
  ];

  constructor(
      private router: Router
  ) {}

  ngOnInit(): void {}

  scrollToEl(target: HTMLElement){
    target.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
  }

}
