<p-card [style]="{borderRadius : 4}" header="{{header}}"
        styleClass="p-card-shadow">
  <p
    *ngIf="
    (detailedBooking.status === 'pending-confirmation' || detailedBooking.status === 'planning') &&
    detailedBooking.recurringDates &&
    detailedBooking.recurringDates.length > 0
  "
  >
    <strong>Recurring Dates | </strong>
    <span>{{ (detailedBooking.bookingDate | dateParser:'dd-MM-yyyy')}} , </span>
    <span
      *ngFor="let recurringDate of detailedBooking.recurringDates; last as isLast"
    >
      {{ (recurringDate | dateParser:'dd-MM-yyyy')}}
      <span
      *ngIf="!isLast">
        ,
      </span>
    </span>
  </p>

  <p><strong>Room | </strong>{{detailedBooking.room.name}}</p>

  <ng-template [ngIf]="detailedBooking.bookingType !== 'offline'">
    <p><strong>Service | </strong>{{detailedBooking.service.type}}</p>
    <p *ngIf="detailedBooking.bookingType === 'band'"><strong>Members | </strong>{{detailedBooking.members.length}}</p>
    <p><strong>Instruments | </strong>{{detailedBooking.primaryMusicRoles.join(', ')  | titlecase}}</p>
    <p><strong>Rented instruments | </strong>
      <ng-template
        [ngIf]="detailedBooking.instruments.length > 0"
      >
        {{detailedBooking.instrumentsLabels}}
      </ng-template>
      <ng-template
        [ngIf]="detailedBooking.instruments.length === 0"
      >
        {{detailedBooking.instruments.length}}
      </ng-template>
    </p>
  </ng-template>
  <ng-template [ngIf]="detailedBooking.bookingType === 'offline'">
    <p *ngIf = "detailedBooking.bookingType === 'offline'"><strong>Time | </strong>{{detailedBooking.bookingDate | dateParser:'dd/MM/yyyy - H:mm'}}</p>
    <p *ngIf = "detailedBooking.bookingType === 'offline'"><strong>Duration | </strong>{{detailedBooking.duration}} hrs</p>
  </ng-template>

    <ng-template pTemplate="footer">
        <div style="text-align:right">
            <button
              *ngIf="
              detailedBooking.bookingType !== 'offline' &&
              detailedBooking.status !== 'studio-declined' &&
              !(detailedBooking.status === 'studio-confirmed' && detailedBooking.hasPassed === true)
              "
              [style]="{color: '#F2B8B5', width: 'auto' }"
              class="p-button-text"
              [label]="detailedBooking.status === 'studio-confirmed' ? 'Cancel' : 'Decline'"
              (click)="confirmBooking(detailedBooking, false)"
              pButton
              pRipple
              type="button"></button>

            <button
              *ngIf="detailedBooking.bookingType !== 'offline' && detailedBooking.status !== 'studio-confirmed'"
              [style]="{ width: 'auto' }"
              class="p-button-text"
              label="Accept"
              (click)="confirmBooking(detailedBooking, true)"
              pButton
              pRipple
              type="button"
            ></button>

            <button
              *ngIf="detailedBooking.bookingType !== 'offline' && detailedBooking.status === 'studio-confirmed' && detailedBooking.hasPassed === true "
              [style]="{ width: 'auto' }"
              class="p-button-danger"
              label="Report"
              (click)="reportBooking(detailedBooking)"
              pButton
              pRipple
              type="button"
            ></button>

          <button
            *ngIf="detailedBooking.bookingType === 'offline'"
            [style]="{color: '#F2B8B5', width: 'auto' }"
            class="p-button-text" label="Remove"
            (click)="removeOfflineBooking(detailedBooking)"
            pButton
            pRipple
            type="button"
          ></button>

        </div>
    </ng-template>
</p-card>
