import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {BookingsService} from "../../../bookings/bookings.service";
import {PaymentsService} from '../../payments.service';
import { environment } from "../../../../environments/environment";
import {ConfirmationService} from "primeng/api";
import {ProfileService} from "../../profile.service";
import {HelperService} from "../../helper.service";

@Component({
    selector: 'app-booking-overview',
    templateUrl: './booking-overview.component.html',
    styleUrls: ['./booking-overview.component.scss'],
    providers: [ConfirmationService]
})
export class BookingOverviewComponent implements OnInit {

  @Input() totalPrice;
  @Input() servicePrice;
  @Input() bookingDiscount;
  @Input() rentalPrice;
  @Input() booking;
  @Input() selectedDiscount;
  @Input() promoCode;
  @Input() needResponseAction;
  @Input() needReviewAction;
  @Input() needReplyAction;
  @Input() recurringDates = [];

  @Output() addReviewEventEmitter = new EventEmitter();


  public env = environment;
  constructor(
    private bookingsService: BookingsService,
    private confirmationService: ConfirmationService,
    private helperService: HelperService,
    private profileService: ProfileService,
  ) {
  }

  ngOnInit(): void {

  }

  confirmBooking(booking, accept = true, force = false): void {
    let newStatus = 'studio-declined';
    if ( accept ) {
      newStatus = 'studio-confirmed';
    }
    this.bookingsService.editBooking(booking.id , { status : newStatus , force })
      .then((data) => {
      })
      .catch((err) => {
        if (err.error.message === "Booking slot is allready taken") {
          this.confirmationService.confirm({
            message: 'You have already accepted a conflicting booking request, are you sure you want to continue?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              return this.confirmBooking(booking, accept , true);
            }
          });
        }
      });
  }

  showAddReview() {
    this.addReviewEventEmitter.emit('review');
  }

  showAddReply() {
    this.addReviewEventEmitter.emit('reply');
  }

  getCurrency( symbol = true ) {
    if (symbol) {
      return this.helperService.getCurrencySymbol(this.booking.priceCurrency ? this.booking.priceCurrency : 'EUR')
    } else {
      return this.booking.priceCurrency ? this.booking.priceCurrency : 'EUR';
    }
  }

}
