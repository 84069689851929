<div class="grid">
  <div class="col-12" *ngIf="studio | async as studioData">
    <div class="grid">
      <div class="col-12">
        <h4>Add new Room</h4>

        <div class="formgrid grid">
          <div class="field col-4">
            <label for="roomName">Name</label>
            <input
              #roomName
              type="text"
              pInputText
              id="roomName"
              [(ngModel)]="newRoomName"
              class="w-full"
              required
              autofocus
            />
            <small class="p-invalid" *ngIf="!newRoomName"
              >Name is required.</small
            >
          </div>

          <div class="col-4" style="margin-top: auto; margin-bottom: auto">
            <button
              icon="pi pi-plus"
              iconPos="left"
              pButton
              pRipple
              type="button"
              label="Add Room"
              class="p-button-secondary secondary-button"
              (click)="addNewRoom()"
            ></button>
          </div>
        </div>
      </div>

      <!--ROOMS-->
      <div class="col-10 hidden-mobile">
        <h4>Available Rooms</h4>
      </div>

      <div
        class="col-12 hidden-mobile"
        style="padding-left: 0 !important; padding-right: 0 !important"
      >
        <p-accordion
          [multiple]="false"
          (onOpen)="buildAvailableServices($event)"
        >
          <p-accordionTab
            class="roomHeader"
            [(selected)]="activeState[i]"
            *ngFor="let room of studioData.rooms; let i = index"
          >
            <ng-template pTemplate="header">
              <span class="label">Room : {{ room.name }}</span>
            </ng-template>

            <!--ROOM IMAGES-->
            <div class="col-11 col-offset-1">
              <h5>Images</h5>
            </div>
            <div class="col-11 col-offset-1">
              <div class="formgrid grid">
                <div class="field col-8 md:col-8">
                  <p-carousel
                    *ngIf="room.media && room.media.length > 0"
                    [value]="room.media"
                    [numVisible]="3"
                    [numScroll]="3"
                    [circular]="false"
                    [responsiveOptions]="responsiveOptions"
                  >
                    <ng-template pTemplate="header"> </ng-template>
                    <ng-template let-roomImage pTemplate="item">
                      <div class="product-item">
                        <div class="product-item-content">
                          <div class="mb-3">
                            <img
                              src="{{ roomImage['link'] }}"
                              [alt]="roomImage.name"
                              class="product-image roomImage"
                            />
                          </div>
                          <div>
                            <div class="car-buttons mt-5">
                              <p-button
                                type="button"
                                styleClass="p-button-danger p-button-rounded mr-2"
                                icon="pi pi-times"
                                (onClick)="
                                  removeImage(roomImage, 'room', room.id)
                                "
                              ></p-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-carousel>

                  <div *ngIf="!room.media || room.media.length === 0">
                    <div
                      class="field"
                      style="display: flex; align-items: center"
                    >
                      <p-fileUpload
                        #roomUploader
                        mode="basic"
                        accept="image/*"
                        [customUpload]="true"
                        [auto]="true"
                        (uploadHandler)="
                          imageUpload(
                            $event,
                            'media',
                            'room',
                            room.id,
                            roomUploader
                          )
                        "
                      >
                      </p-fileUpload>
                    </div>
                    <span> No images uploaded for this room. </span>
                  </div>
                </div>

                <div
                  *ngIf="room.media && room.media.length > 0"
                  class="field col-2 md:col-2"
                  style="display: flex; align-items: center"
                >
                  <p-fileUpload
                    #roomUploader
                    mode="basic"
                    accept="image/*"
                    chooseLabel="Add Image"
                    [customUpload]="true"
                    [auto]="true"
                    (uploadHandler)="
                      imageUpload(
                        $event,
                        'media',
                        'room',
                        room.id,
                        roomUploader
                      )
                    "
                  >
                  </p-fileUpload>
                </div>
              </div>
            </div>

            <!--ROOM SERVICES-->
            <div class="col-11 col-offset-1">
              <h5>Available Services</h5>
            </div>
            <div class="col-11 col-offset-1">
              <div class="formgrid grid" *ngIf="availableServices.length > 0">
                <div class="field col-2 md:col-2">
                  <span class="p-float-label">
                    <p-dropdown
                      class="w-full"
                      #newServiceType
                      id="newServiceType"
                      [autoDisplayFirst]="false"
                      [options]="availableServices"
                      [(ngModel)]="room.newServiceType"
                      optionLabel="name"
                      optionValue="code"
                    ></p-dropdown>
                    <label for="newServiceType">Service Type</label>
                  </span>
                </div>
                <div class="field col-2 md:col-2">
                  <span class="p-float-label mb-3">
                    <p-inputNumber
                      [(ngModel)]="room.newServicePrice"
                      #newServicePrice
                      id="newServicePrice"
                      mode="currency"
                      class="w-full"
                      suffix=" /hr"
                      [currency]="businessCurrency"
                    ></p-inputNumber>
                    <label for="newServicePrice">Service Price</label>
                  </span>
                </div>

                <div class="field col-2 md:col-1">
                  <p-button
                    label="Add"
                    styleClass="p-button-primary"
                    (click)="addNewService(room)"
                  ></p-button>
                </div>
              </div>

              <ng-template [ngIf]="room.services && room.services.length > 0">
                <div
                  class="formgrid grid"
                  *ngFor="let service of room.services"
                >
                  <div class="field col-2 md:col-2">
                    <span class="p-float-label">
                      <p-dropdown
                        class="w-full"
                        #serviceType
                        id="serviceType"
                        [options]="services"
                        [(ngModel)]="service.type"
                        optionLabel="name"
                        optionValue="code"
                        [disabled]="true"
                      ></p-dropdown>
                      <label for="serviceType">Service Type</label>
                    </span>
                  </div>
                  <div class="field col-4 md:col-2">
                    <span class="p-float-label">
                      <p-inputNumber
                        [(ngModel)]="service.price"
                        mode="currency"
                        [currency]="businessCurrency"
                        class="w-full"
                        suffix=" /hr"
                        id="servicePrice"
                        [disabled]="!service.edit"
                      ></p-inputNumber>
                      <label for="servicePrice">Service Price</label>
                    </span>
                  </div>
                </div>
              </ng-template>
            </div>

            <!--ROOM EQUIPMENT-->
            <div class="col-11 col-offset-1">
              <h5>Room Equipment</h5>
            </div>
            <div class="col-11 col-offset-1">
              <div class="formgrid grid">
                <div class="field col-2 md:col-2">
                  <span class="p-float-label">
                    <p-dropdown
                      class="w-full"
                      #equipmentCategory
                      id="equipmentCategory"
                      [autoDisplayFirst]="false"
                      [options]="equipments"
                      [(ngModel)]="room.newEquipment"
                      (onChange)="resetNewEquipmentBrand(room)"
                      optionLabel="name"
                    >
                      <!--                    <ng-template let-equipment pTemplate="item">-->
                      <!--                      <div class="instrument-item">-->
                      <!--                     <img *ngIf="equipment.type !== 'other' && equipment.type !== 'mixboards'" src="{{'assets/images/equipments/'+equipment.icon+'.png'}}"-->
                      <!--                          [class]="'icon icon-' + equipment.type.toLowerCase()"/>-->
                      <!--                    <div *ngIf="equipment.type === 'other'" class="material-icons-outlined">track_changes</div>-->
                      <!--                        <div *ngIf="equipment.type === 'mixboards'" class="material-icons-outlined">tune</div>-->

                      <!--                      <div>{{equipment.name}}</div>-->
                      <!--                     </div>-->
                      <!--                    </ng-template>-->

                      <ng-template let-equipment pTemplate="item">
                        <div class="instrument-item">
                          <div
                            class="till-noon-icon {{ equipment.icon }}"
                          ></div>
                          <div>{{ equipment.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <label for="equipmentCategory">Equipment Category</label>
                  </span>
                </div>

                <div class="field col-2 md:col-2">
                  <span class="p-float-label mb-3">
                    <p-dropdown
                      #equipmentBrand
                      id="equipmentBrand"
                      [autoDisplayFirst]="
                        room.newEquipment &&
                        room.newEquipment.brands.length === 1
                      "
                      [options]="
                        room.newEquipment ? room.newEquipment.brands : []
                      "
                      [(ngModel)]="room.newEquipmentBrand"
                      optionLabel="name"
                    >
                      <ng-template let-equipment pTemplate="item">
                        <div class="instrument-item">
                          <img
                            src="{{
                              'assets/images/brands/' + equipment.type + '.png'
                            }}"
                            [class]="
                              'icon icon-' + equipment.type.toLowerCase()
                            "
                          />
                          <div>{{ equipment.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <label for="equipmentBrand">Brand</label>
                  </span>
                </div>

                <div class="field col-2 md:col-2">
                  <span class="p-float-label mb-3">
                    <input
                      inputTrim
                      #equipmentName
                      id="equipmentName"
                      type="text"
                      class="w-full"
                      pInputText
                      [(ngModel)]="room.newEquipmentName"
                    />
                    <label for="equipmentName">Name</label>
                  </span>
                </div>

                <div class="field col-2 md:col-2">
                  <span class="p-float-label mb-3">
                    <input
                      inputTrim
                      #equipmentDescription
                      id="equipmentDescription"
                      type="text"
                      class="w-full"
                      pInputText
                      [(ngModel)]="room.newEquipmentDescription"
                    />
                    <label for="equipmentDescription">Description</label>
                  </span>
                </div>

                <div class="field col-2 md:col-1">
                  <p-button
                    label="Add"
                    styleClass="p-button-primary"
                    (click)="addNewEquipment(room)"
                  ></p-button>
                </div>
              </div>

              <ng-template [ngIf]="room.equipment && room.equipment.length > 0">
                <div
                  class="formgrid grid"
                  *ngFor="let equipment of room.equipment"
                >
                  <div class="field col-4 md:col-2">
                    <span class="p-float-label">
                      <p-dropdown
                        class="w-full"
                        #equipmentCategory
                        [options]="equipments"
                        [(ngModel)]="equipment.category"
                        optionLabel="name"
                        optionValue="type"
                        [disabled]="true"
                      ></p-dropdown>
                      <label for="equipmentCategory">Equipment Category</label>
                    </span>
                  </div>
                  <div class="field col-2 md:col-2">
                    <span class="p-float-label mb-3">
                      <p-dropdown
                        #equipmentBrand
                        class="w-full"
                        [autoDisplayFirst]="false"
                        [options]="equipmentBrandMap.get(equipment.category)"
                        [(ngModel)]="equipment.brand"
                        optionLabel="name"
                        optionValue="type"
                        [disabled]="true"
                      ></p-dropdown>
                      <label for="equipmentBrand">Brand</label>
                    </span>
                  </div>
                  <div class="field col-2 md:col-2">
                    <span class="p-float-label mb-3">
                      <input
                        inputTrim
                        #equipmentName
                        type="text"
                        class="w-full"
                        pInputText
                        [(ngModel)]="
                          !equipment.edit ? equipment.name : equipment.editName
                        "
                        [disabled]="!equipment.edit"
                      />
                      <label for="equipmentName">Name</label>
                    </span>
                  </div>
                  <div class="field col-2 md:col-2">
                    <span class="p-float-label mb-3">
                      <input
                        inputTrim
                        #equipmentDescription
                        type="text"
                        class="w-full"
                        pInputText
                        [(ngModel)]="
                          !equipment.edit
                            ? equipment.description
                            : equipment.editDescription
                        "
                        [disabled]="!equipment.edit"
                      />
                      <label for="equipmentDescription">Description</label>
                    </span>
                  </div>
                </div>
              </ng-template>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="grid grid-nogutter justify-content-around">
      <p-button
        label="Back"
        (onClick)="previousPage()"
        icon="pi pi-angle-left"
        iconPos="left"
      ></p-button>
      <button
        pButton
        class="secondary-button"
        label="Skip"
        (click)="nextPage(true)"
        icon="pi pi-angle-right"
        iconPos="right"
      ></button>
      <p-button
        label="Next"
        (onClick)="nextPage()"
        icon="pi pi-angle-right"
        iconPos="right"
        [disabled]="disableNext"
      ></p-button>
    </div>
  </div>
</div>
