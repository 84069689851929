import {DEFAULT_CURRENCY_CODE, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocationStrategy, NgForOf, NgIf, PathLocationStrategy} from "@angular/common";
import {AppRoutingModule} from './app-routing.module';

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from '@fullcalendar/angular';
import {GalleriaModule} from 'primeng/galleria';
import {ImageModule} from 'primeng/image';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KnobModule} from 'primeng/knob';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
// import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SpeedDialModule} from "primeng/speeddial";
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

// Application Components
import {AppCodeModule} from './code/app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './theme/config/app.config.component';
import {AppMenuComponent} from './menu/app.menu.component';
import {AppMenuitemComponent} from './menu/app.menuitem.component';
import {AppTopbarMenuComponent} from './topbar/app.topbarmenu.component';
import {AppTopBarComponent} from './topbar/app.topbar.component';
import {AppFooterComponent} from './footer/app.footer.component';

// Demo pages
import {DashboardDemoComponent} from './theme/demo/view/dashboarddemo.component';
import {DashboardAnalyticsComponent} from './theme/demo/view/dashboardanalytics.component';

import {AppNotfoundComponent} from './theme/pages/app.notfound.component';
import {AppErrorComponent} from './theme/pages/app.error.component';
import {AppAccessdeniedComponent} from './theme/pages/app.accessdenied.component';
// import {AppLoginComponent} from './theme/pages/app.login.component';


// Application services
import {MenuService} from './menu/app.menu.service';
import {TopbarMenuService} from './topbar/app.topbarmenu.service';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { LoginComponent } from './authentication/login/login.component';
import { SignupComponent } from './authentication/signup/signup.component';
import {MessageService, SharedModule} from 'primeng/api';
import { OverviewBoxComponent } from './common/overview-box/overview-box.component';
import { UsersComponent } from './users/users.component';
import {httpInterceptorProviders} from './common/interceptors';
import { StudiosComponent } from './studios/studios.component';
import {NgxWebstorageModule} from 'ngx-webstorage';
import { BandsComponent } from './bands/bands.component';
import { UserComponent } from './users/user/user.component';
import { UserInstrumentsComponent } from './users/user/user-instruments/user-instruments.component';
import { UserBandsComponent } from './users/user/user-bands/user-bands.component';
import { BandComponent } from './bands/band/band.component';
import { BandMembersComponent } from './bands/band/band-members/band-members.component';
import { BandInvitesComponent } from './bands/band/band-invites/band-invites.component';
import { BandBookingsComponent } from './bands/band/band-bookings/band-bookings.component';
import { UserBookingsComponent } from './users/user/user-bookings/user-bookings.component';
import { NewBookingComponent } from './bookings/new-booking/new-booking.component';
import { BookingTypeComponent } from './bookings/new-booking/booking-type/booking-type.component';
import { BookingStudioComponent } from './bookings/new-booking/booking-studio/booking-studio.component';
import { BookingInfoComponent } from './bookings/new-booking/booking-info/booking-info.component';
import { BookingConfirmComponent } from './bookings/new-booking/booking-confirm/booking-confirm.component';
import { StudioComponent } from './studios/studio/studio.component';
import { StudioServicesComponent } from './studios/studio/studio-services/studio-services.component';
import { StudioRoomsComponent } from './studios/studio/studio-rooms/studio-rooms.component';
import { StudioBookingsComponent } from './studios/studio/studio-bookings/studio-bookings.component';
import { BookingsComponent } from './bookings/bookings.component';
import { BookingComponent } from './bookings/booking/booking.component';
import { ConsolePipe } from './common/pipes/console.pipe';
import { StudioCalendarComponent } from './studios/studio/studio-calendar/studio-calendar.component';
import { FilterBookingsPipe } from './common/pipes/filter-bookings.pipe';
import { FilterCalendarPipe } from './common/pipes/filter-calendar.pipe';
import { StudioProfileComponent } from './studios/studio/studio-profile/studio-profile.component';
import { StudioSettingsComponent } from './studios/studio/studio-settings/studio-settings.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { DateParserPipe } from './common/pipes/date-parser.pipe';

import { DurationParserPipe } from './common/pipes/duration-parser.pipe';
import { StudioAdvancedComponent } from './common/cards/studio-advanced/studio-advanced.component';
import { StudioSimpleComponent } from './common/cards/studio-simple/studio-simple.component';
import { BookingOverviewComponent } from './common/cards/booking-overview/booking-overview.component';
import { BandSimpleComponent } from './common/cards/band-simple/band-simple.component';
import { BandBookingOverviewComponent } from './common/cards/band-booking-overview/band-booking-overview.component';
import { InstrumentComponent } from './common/cards/instrument/instrument.component';
import { EquipmentComponent } from './common/cards/equipment/equipment.component';
import { WizardStepComponent } from './common/cards/wizard-step/wizard-step.component';
import { StudioPasswordComponent } from './studios/studio/studio-password/studio-password.component';
import { BookingSummaryComponent } from './bookings/booking-summary/booking-summary.component';
import { LandingComponent } from './landing/landing.component';
import { ContactComponent } from './footer/contact/contact.component';
import { TcComponent } from './footer/tc/tc.component';
import { PrivacyAndPolicyComponent } from './footer/privacy-and-policy/privacy-and-policy.component';
import { VerificationPendingComponent } from './authentication/verification-pending/verification-pending.component';
import { TimeDayPipe } from './common/pipes/time-day.pipe';
import { VerbalizeBookingStatusPipe } from './common/pipes/verbalize-booking-status';
import { WaveAnimationComponent } from './common/wave-animation/wave-animation.component';
import {StudioDeletionCompleteComponent} from './studios/studio/studio-deletion-complete/studio-deletion-complete.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import {FilledIconComponent} from './theme/utilities/filled-icon.component';
import { CommentComponent } from "./common/comment/comment.component";
import { TitleCaseExtendedPipePipe } from "./common/pipes/title-case-extended-pipe.pipe";
import {ImageCropperModule} from 'ngx-image-cropper';
import { StudioPaymentsComponent } from './studios/studio/studio-payments/studio-payments.component';
import { StudioMonthlyReportsComponent } from './studios/studio/studio-reports/studio-monthly-reports/studio-monthly-reports.component';
import { ServicesCardComponent } from "./common/cards/services-card/services-card.component";
import { ReviewsComponent } from './reviews/reviews.component';
import { UserReviewsComponent } from './users/user/user-reviews/user-reviews.component';
import { ReportsComponent } from './reports/reports.component';
import { ContractComponent } from './authentication/contract/contract.component';
import { CompanyComponent } from './authentication/company/company.component';
import { StudioContractsComponent } from './studios/studio/studio-settings/studio-contracts/studio-contracts.component';
import {TrimDirective} from './common/directives/TrimDirective';
import { GoogleMapsModule } from '@angular/google-maps';
import {GMapModule} from "primeng/gmap";
import { SetupWizardComponent } from './setup-wizard/setup-wizard.component';
import { WizardStudioInfoComponent } from './setup-wizard/wizard-studio-info/wizard-studio-info.component';
import { WizardStudioMediaComponent } from './setup-wizard/wizard-studio-media/wizard-studio-media.component';
import { WizardStudioHoursComponent } from './setup-wizard/wizard-studio-hours/wizard-studio-hours.component';
import { WizardStudioRoomsComponent } from './setup-wizard/wizard-studio-rooms/wizard-studio-rooms.component';
import { WizardStudioRentalsComponent } from './setup-wizard/wizard-studio-rentals/wizard-studio-rentals.component';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarGroupModule,
        AvatarModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        // MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SpeedDialModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        ScrollTopModule,
        ImageCropperModule,
        GoogleMapsModule,
        NgxWebstorageModule.forRoot(),
        NgIf,
        NgIf,
        GMapModule,
        NgIf,
        ButtonModule,
        ChipModule,
        DropdownModule,
        NgForOf,
        NgIf,
        SharedModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopbarMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DashboardDemoComponent,
        DashboardAnalyticsComponent,
        // FormLayoutDemoComponent,
        // InputDemoComponent,
        // FloatLabelDemoComponent,
        // InvalidStateDemoComponent,
        // ButtonDemoComponent,
        // TableDemoComponent,
        // ListDemoComponent,
        // TreeDemoComponent,
        // PanelsDemoComponent,
        // OverlaysDemoComponent,
        // MediaDemoComponent,
        // MenusDemoComponent,
        // MessagesDemoComponent,
        // MessagesDemoComponent,
        // MiscDemoComponent,
        // ChartsDemoComponent,
        // EmptyDemoComponent,
        // FileDemoComponent,
        // DocumentationComponent,
        // DisplayComponent,
        // ElevationComponent,
        // FlexboxComponent,
        // GridComponent,
        // IconsComponent,
        // WidgetsComponent,
        // SpacingComponent,
        // TypographyComponent,
        // TextComponent,
        // AppCrudComponent,
        // AppCalendarComponent,
        // AppTimelineDemoComponent,
        // AppLoginComponent,
        // AppInvoiceComponent,
        // AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        LoginComponent,
        SignupComponent,
        OverviewBoxComponent,
        UsersComponent,
        StudiosComponent,
        BandsComponent,
        UserComponent,
        UserInstrumentsComponent,
        UserBandsComponent,
        BandComponent,
        BandMembersComponent,
        BandInvitesComponent,
        BandBookingsComponent,
        UserBookingsComponent,
        NewBookingComponent,
        BookingTypeComponent,
        BookingStudioComponent,
        BookingInfoComponent,
        BookingConfirmComponent,
        StudioComponent,
        StudioServicesComponent,
        StudioRoomsComponent,
        StudioBookingsComponent,
        BookingsComponent,
        BookingComponent,
        ConsolePipe,
        StudioCalendarComponent,
        FilterBookingsPipe,
        FilterCalendarPipe,
        StudioProfileComponent,
        StudioSettingsComponent,
        NotificationsComponent,
        DateParserPipe,
        TimeDayPipe,
        VerbalizeBookingStatusPipe,
      DurationParserPipe,
      StudioAdvancedComponent,
      StudioSimpleComponent,
      BookingOverviewComponent,
      BandSimpleComponent,
      BandBookingOverviewComponent,
      InstrumentComponent,
      EquipmentComponent,
      WizardStepComponent,
      StudioPasswordComponent,
      BookingSummaryComponent,
      LandingComponent,
      BookingSummaryComponent,
      ContactComponent,
      TcComponent,
      PrivacyAndPolicyComponent,
      VerificationPendingComponent,
      WaveAnimationComponent,
      StudioDeletionCompleteComponent,
      ForgotPasswordComponent,
      ResetPasswordComponent,
      FilledIconComponent,
      CommentComponent,
      TitleCaseExtendedPipePipe,
      StudioPaymentsComponent,
      StudioMonthlyReportsComponent,
      ServicesCardComponent,
      ReviewsComponent,
      UserReviewsComponent,
      ReportsComponent,
      ContractComponent,
      CompanyComponent,
      StudioContractsComponent,
      TrimDirective,
      SetupWizardComponent,
      WizardStudioInfoComponent,
      WizardStudioMediaComponent,
      WizardStudioHoursComponent,
      WizardStudioRoomsComponent,
      WizardStudioRentalsComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy}, MenuService, TopbarMenuService,
        MessageService, {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
        httpInterceptorProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
