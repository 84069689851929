import { ProfileService } from './../../profile.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InstrumentAndEquipmentService} from '../../instruments.equipments.service';
import { HelperService } from '../../helper.service';

@Component({
    selector: 'app-instrument',
    templateUrl: './instrument.component.html',
    styleUrls: ['./instrument.component.scss']
})
export class InstrumentComponent implements OnInit {
  public businessCurrency: String;

  @Input() instrument;
  constructor(
    private instrumentService: InstrumentAndEquipmentService,
    private profileService: ProfileService,
    private helperService: HelperService
  ) {
  }
  instrumentsMap;
  ngOnInit(): void {
    this.instrumentsMap = this.instrumentService.getInstrumentsMap();
  }

  getCurrencySymbol(){
    const studioCurrency = this.profileService.getOwnerStudio().businessCurrency
      ? this.profileService.getOwnerStudio().businessCurrency
      : 'EUR';
    return this.helperService.getCurrencySymbol(studioCurrency);
  }
}
