import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HelperService} from '../common/helper.service';
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class StudiosService {

  private studios: BehaviorSubject<any>;
  public readonly studios$: Observable<any>;

  private studio: BehaviorSubject<any>;
  public readonly studio$: Observable<any>;

  private services: BehaviorSubject<any>;
  public readonly services$: Observable<any>;

  private discounts: BehaviorSubject<any>;
  public readonly discounts$: Observable<any>;

  private equipment: BehaviorSubject<any>;
  public readonly equipment$: Observable<any>;

  private rooms: BehaviorSubject<any>;
  public readonly rooms$: Observable<any>;

  private bookings: BehaviorSubject<any>;
  public readonly bookings$: Observable<any>;

  private rentalEquipment: BehaviorSubject<any>;
  public readonly rentalEquipment$: Observable<any>;

  private reviews: BehaviorSubject<any>;
  public readonly reviews$: Observable<any>;

  private payments: BehaviorSubject<any>;
  public readonly payments$: Observable<any>;

  private transactions: BehaviorSubject<any>;
  public readonly transactions$: Observable<any>;

  private monthlyReports: BehaviorSubject<any>;
  public readonly monthlyReports$: Observable<any>;

  private contracts: BehaviorSubject<any>;
  public readonly contracts$: Observable<any>;

  private contractValidity: BehaviorSubject<any>;
  public readonly contractValidity$: Observable<any>;



  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private dataStore: {
    studios: any,
    studio: any,
    services: any,
    equipment: any,
    rooms: any,
    discounts: any,
    rentalEquipment: any,
    bookings: any,
    reviews: any,
    payments: any,
    transactions: any,
    monthlyReports: any,
    contracts: any,
    contractValidity: any,
  };

  constructor( private helperService: HelperService ) {

    this.studios = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.studios$ = this.studios.asObservable();

    this.studio = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.studio$ = this.studio.asObservable();

    this.rooms = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.rooms$ = this.rooms.asObservable();

    this.services = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.services$ = this.services.asObservable();

    this.discounts = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.discounts$ = this.discounts.asObservable();

    this.equipment = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.equipment$ = this.equipment.asObservable();

    this.rentalEquipment = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.rentalEquipment$ = this.rentalEquipment.asObservable();

    this.bookings = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.bookings$ = this.bookings.asObservable();

    this.reviews = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.reviews$ = this.reviews.asObservable();

    this.payments = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.payments$ = this.payments.asObservable();

    this.transactions = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.transactions$ = this.transactions.asObservable();

    this.monthlyReports = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.monthlyReports$ = this.monthlyReports.asObservable();

    this.contracts = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.contracts$ = this.contracts.asObservable();

    this.contractValidity = new BehaviorSubject({valid : true}) as BehaviorSubject<any>;
    this.contractValidity$ = this.contractValidity.asObservable();


    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.dataStore = {
      studios: {
        results : []
      },
      studio: {},
      services: [],
      equipment: [],
      rooms: [],
      discounts: [],
      rentalEquipment: [],
      bookings: [],
      reviews: [],
      payments: [],
      transactions: [],
      monthlyReports: [],
      contracts: [],
      contractValidity: {},
    };
  }

  fetchStudios( params : any = {} ) {
    return this.helperService.getAction('/studio' , params  )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.studios, data);
        this.studios.next(data);
        return data;
      });
  }

  fetchStudio( studioId: string , populate = false ) {
    return this.helperService.getAction('/studio/' + studioId , {populate}  )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.studio, data);
        this.dataStore.rooms = this.dataStore.studio.rooms;
        this.dataStore.services = [];
        this.dataStore.studio.rooms.forEach((room) => {this.dataStore.services.push( ...room.services ) });
        this.studio.next(data);
        this.services.next(this.dataStore.services);
        this.rooms.next(this.dataStore.rooms);
        return data;
      });
  }

  fetchStudioReviews( studioId: string, populate = false ) {
    return this.helperService.getAction('/studio/' + studioId + '/reviews', {populate} )
      .toPromise()
      .then((data) => {
        this.dataStore.reviews = [ ...data.results ];
        this.reviews.next(data);
        return data;
      });
  }

  fetchStudioPayments( studioId: string, populate = false ) {
    return this.helperService.getAction('/studio/' + studioId + '/payments', {populate} )
      .toPromise()
      .then((data) => {
        this.dataStore.payments = [ ...data.results ];
        this.payments.next(this.dataStore.payments);
        return data;
      });
  }

  fetchStudioTransactions( studioId: string, filterMonth: string, filterYear: string ) {
    return this.helperService.getAction('/studio/' + studioId + '/transactions', {month: filterMonth , year: filterYear} )
      .toPromise()
      .then((data) => {
        data.results.forEach((booking) => {
          this.mapInternalData(booking);
        })
        this.dataStore.transactions = [ ...data.results ];
        this.transactions.next(this.dataStore.transactions);
        return data;
      });
  }

  fetchStudioReports( studioId: string ) {
    return this.helperService.getAction('/studio/' + studioId + '/reports', {category: 'monthly' } )
      .toPromise()
      .then((data) => {
        this.dataStore.monthlyReports = [ ...data.results ];
        this.monthlyReports.next(this.dataStore.monthlyReports);
        return data;
      });
  }

  fetchStudioBookings( studioId: string ) {
    return this.helperService.getAction('/studio/' + studioId + '/bookings' )
      .toPromise()
      .then((data) => {
        data.results.forEach((booking) => {
          this.mapInternalData(booking);
          // if (booking.bookingType === 'offline') {
          //   booking.totalPrice = 0;
          // } else {
          //   booking.totalPrice = booking.service.price * booking.duration;
          // }
          // if (booking.instruments && booking.instruments.length > 0 ) {
          //   booking.instruments.forEach((inst) => booking.totalPrice = booking.totalPrice + (inst.price * booking.duration));
          // }
        })
        this.dataStore.bookings = [ ...data.results ];
        this.bookings.next(data);
        return data;
      });
  }

  fetchStudioRentalEquipment( studioId: string ) {
    return this.helperService.getAction('/studio/' + studioId + '/instruments' )
      .toPromise()
      .then((data) => {
        this.dataStore.rentalEquipment = [ ...data];
        this.rentalEquipment.next(data);
        return data;
      });
  }

  fetchContracts( studioId: string ) {
    return this.helperService.getAction('/studio/' + studioId + '/contracts' ,  )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.contracts, data);
        this.contracts.next(data);
        return data;
      });
  }

  fetchContractsValidity( studioId: string ) {
    return this.helperService.getAction('/studio/' + studioId + '/contracts/valid' ,  )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.contractValidity, data);
        this.contractValidity.next(this.dataStore.contractValidity);
        return data;
      });
  }

  downloadLatestContract( studioId: string ) {
    return this.helperService.getAction('/studio/' + studioId + '/contracts/download' , {} ,'application/vnd.openxmlformats-officedocument.wordprocessingml.document' )
      .toPromise();
  }

  addStudio( newStudio: any ) {
    return this.helperService.postAction('/studio' , newStudio )
      .toPromise()
      .then((data) => {
        this.dataStore.studios.results = this.dataStore.studios.results.concat([data]);
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  deleteStudio( studioId: string ) {
    return this.helperService.deleteAction('/studio/' + studioId )
      .toPromise()
      .then((data) => {
        this.dataStore.studios.results = this.dataStore.studios.results.filter(std => std.id !== studioId);
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  editStudio( editedStudio: any) {
    const editBody: any = {};
    Object.assign(editBody, editedStudio);
    delete editBody.id;
    delete editBody.isEmailVerified;
    return this.helperService.patchAction('/studio/' + editedStudio.id , editBody )
      .toPromise()
      .then((data) => {
        const foundStudio = this.dataStore.studios.results.find(std => std.id === editedStudio.id);
        if (foundStudio) {
          Object.assign(foundStudio , data);
        }
        if (this.dataStore.studio && this.dataStore.studio.id === editedStudio.id) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  addStudioImage( studioId: string , image: any , type: string) {
    const formData = new FormData();
    formData.append('studioImage', image);
    formData.append('type' , type);
    return this.helperService.postAction('/studio/' + studioId + '/media' , formData )
      .toPromise()
      .then((data) => {
        const foundStudio = this.dataStore.studios.results.find(std => std.id === studioId);
        if (foundStudio) {
          Object.assign(foundStudio , data);
        }
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  deleteStudioImage( studioId: string, mediaId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/media/' + mediaId )
      .toPromise()
      .then((data) => {
        const foundStudio = this.dataStore.studios.results.find(std => std.id === studioId);
        if (foundStudio) {
          Object.assign(foundStudio , data);
        }
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  getRooms(studioId: string) {
    return this.helperService.getAction('/studio/' + studioId + '/rooms' )
      .toPromise()
      .then((data) => {
        this.dataStore.rooms = [...data];
        this.rooms.next(this.dataStore.rooms);
        return data;
      });
  }

  createRoom(studioId: string, newRoom: any) {
    return this.helperService.postAction('/studio/' + studioId + '/rooms' , newRoom )
      .toPromise()
      .then((data) => {
        this.dataStore.rooms = [...data.rooms];
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          this.dataStore.studio.rooms = [...data.rooms];
          this.studio.next(this.dataStore.studio);
        }
        this.rooms.next(this.dataStore.rooms);
        return data;
      });
  }

  removeRoom(studioId: string, roomId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/rooms/' + roomId )
      .toPromise()
      .then((data) => {
        this.dataStore.rooms = this.dataStore.rooms.filter(room => room.id !== roomId);
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          this.dataStore.studio.rooms = this.dataStore.studio.rooms.filter((room) => room.id !== roomId);
          this.studio.next(this.dataStore.studio);
        }
        this.rooms.next(this.dataStore.rooms);
        return this.dataStore.studio ? this.dataStore.studio : data;
      });
  }

  editRoom(studioId: string, roomId: string, roomData: any) {
    return this.helperService.patchAction('/studio/' + studioId + '/rooms/' + roomId , roomData )
      .toPromise()
      .then((data) => {
        this.dataStore.rooms = [...data.rooms];
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          this.dataStore.studio.rooms = [...data.rooms];
          this.studio.next(this.dataStore.studio);
        }
        this.rooms.next(this.dataStore.rooms);
        return data;
      });
  }

  addRoomImage( studioId: string , roomId: string, image: any , type: string) {
    const formData = new FormData();
    formData.append('roomImage', image);
    formData.append('type' , type);
    return this.helperService.postAction('/studio/' + studioId + '/rooms/' + roomId + '/media' , formData )
      .toPromise()
      .then((data) => {
        const foundStudio = this.dataStore.studios.results.find(std => std.id === studioId);
        if (foundStudio) {
          Object.assign(foundStudio , data);
        }
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  deleteRoomImage( studioId: string, roomId: string, mediaId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/rooms/' + roomId + '/media/' + mediaId )
      .toPromise()
      .then((data) => {
        const foundStudio = this.dataStore.studios.results.find(std => std.id === studioId);
        if (foundStudio) {
          Object.assign(foundStudio , data);
        }
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.studios.next(this.dataStore.studios);
        return data;
      });
  }

  createInstrument(studioId: string, newEquipment: any) {
    return this.helperService.postAction('/studio/' + studioId + '/instruments' , newEquipment )
      .toPromise()
      .then((data) => {
        this.dataStore.rentalEquipment = [...data.instruments];
        this.rentalEquipment.next(this.dataStore.rentalEquipment);
        return data;
      });
  }

  removeInstrument(studioId: string, equipmentId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/instruments/' + equipmentId )
      .toPromise()
      .then((data) => {
        this.dataStore.rentalEquipment = this.dataStore.rentalEquipment.filter(eq => eq.id !== equipmentId);
        this.rentalEquipment.next(this.dataStore.rentalEquipment);
        return data;
      });
  }

  editInstrument(studioId: string, equipmentId: string, equipmentData: any) {
    return this.helperService.patchAction('/studio/' + studioId + '/instruments/' + equipmentId , equipmentData )
      .toPromise()
      .then((data) => {
        this.dataStore.rentalEquipment = [...data.instruments];
        this.rentalEquipment.next(this.dataStore.rentalEquipment);
        return data;
      });
  }



  getServices(studioId: string) {
    return this.helperService.getAction('/studio/' + studioId + '/services' )
      .toPromise()
      .then((data) => {
        this.dataStore.services = [...data];
        this.services.next(this.dataStore.services);
        return data;
      });
  }

  createService(studioId: string, roomId: string,  newService: any) {
    return this.helperService.postAction('/studio/' + studioId + '/rooms/' + roomId + '/services' , newService )
      .toPromise()
      .then((data) => {
        this.dataStore.services = [...data.rooms.find((room) => room.id === roomId).services];
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.services.next(this.dataStore.services);
        return data;
      });
  }

  editService(studioId: string, roomId: string, serviceId: string, editedService: any) {
    return this.helperService.patchAction('/studio/' + studioId + '/rooms/' + roomId +  '/services/' + serviceId , editedService )
      .toPromise()
      .then((data) => {
        this.dataStore.services = [...data.rooms.find((room) => room.id === roomId).services];
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.services.next(this.dataStore.services);
        return data;
      });
  }

  removeService(studioId: string, roomId: string, serviceId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/rooms/' + roomId +  '/services/' + serviceId )
      .toPromise()
      .then((data) => {
        this.dataStore.services = this.dataStore.services.filter(service => service.id !== serviceId);
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          const foundRoom = this.dataStore.studio.rooms.find((room) => room.id === roomId);
          foundRoom.services = foundRoom.services.filter((service) => service.id !== serviceId);
          this.studio.next(this.dataStore.studio);
        }
        this.services.next(this.dataStore.services);
        return this.dataStore.studio ? this.dataStore.studio : data;
      });
  }



  getDiscounts(studioId: string, roomId: string, serviceId: string) {
    return this.helperService.getAction('/studio/' + studioId + '/rooms/' + roomId + '/services/' + serviceId + '/discounts' )
      .toPromise()
      .then((data) => {
        this.dataStore.discounts = [...data];
        this.discounts.next(this.dataStore.discounts);
        return data;
      });
  }

  createServiceDiscount(studioId: string, roomId: string, serviceId: string,  newDiscount: any) {
    return this.helperService.postAction('/studio/' + studioId + '/rooms/' + roomId + '/services/' + serviceId + '/discounts' , newDiscount )
      .toPromise()
      .then((data) => {
        this.dataStore.discounts = [...data.rooms
          .find((room) => room.id === roomId)
          .services
          .find((service) => service.id === serviceId)
          .discounts];
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.discounts.next(this.dataStore.discounts);
        return data;
      });
  }

  editServiceDiscount(studioId: string, roomId: string, serviceId: string, discountId: string, editedDiscount: any) {
    return this.helperService.patchAction('/studio/' + studioId + '/rooms/' + roomId +  '/services/' + serviceId +  '/discounts/' + discountId , editedDiscount )
      .toPromise()
      .then((data) => {
        this.dataStore.discounts = [...data.rooms
          .find((room) => room.id === roomId)
          .services
          .find((service) => service.id === serviceId)
          .discounts];
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.discounts.next(this.dataStore.discounts);
        return data;
      });
  }

  removeServiceDiscount(studioId: string, roomId: string, serviceId: string, discountId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/rooms/' + roomId +  '/services/' + serviceId +  '/discounts/' + discountId )
      .toPromise()
      .then((data) => {
        this.dataStore.discounts = this.dataStore.discounts.filter(discount => discount.id !== discountId);
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          const foundRoom = this.dataStore.studio.rooms.find((room) => room.id === roomId);
          const foundService = foundRoom.services.find((service) => service.id === serviceId);
          foundService.discounts = foundService.discounts.filter((discount) => discount.id !== discountId);
          this.studio.next(this.dataStore.studio);
        }
        this.discounts.next(this.dataStore.discounts);
        return this.dataStore.studio ? this.dataStore.studio : data;
      });
  }




  getAllEquipment(studioId: string, roomId: string) {
    return this.helperService.getAction('/studio/' + studioId + '/rooms/' + roomId + '/equipment' )
      .toPromise()
      .then((data) => {
        this.dataStore.equipment = [...data];
        this.equipment.next(this.dataStore.equipment);
        return data;
      });
  }

  createEquipment(studioId: string, roomId: string,  newEquipment: any) {
    return this.helperService.postAction('/studio/' + studioId + '/rooms/' + roomId + '/equipment' , newEquipment )
      .toPromise()
      .then((data) => {
        this.dataStore.equipment = [...data.rooms.find((room) => room.id === roomId).equipment];
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.equipment.next(this.dataStore.equipment);
        return data;
      });
  }

  editEquipment(studioId: string, roomId: string, equipmentId: string, editedEquipment: any) {
    return this.helperService.patchAction('/studio/' + studioId + '/rooms/' + roomId +  '/equipment/' + equipmentId , editedEquipment )
      .toPromise()
      .then((data) => {
        this.dataStore.equipment = [...data.rooms.find((room) => room.id === roomId).equipment];
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          Object.assign(this.dataStore.studio , data);
          this.studio.next(this.dataStore.studio);
        }
        this.equipment.next(this.dataStore.equipment);
        return data;
      });
  }

  removeEquipment(studioId: string, roomId: string, equipmentId: string) {
    return this.helperService.deleteAction('/studio/' + studioId + '/rooms/' + roomId +  '/equipment/' + equipmentId )
      .toPromise()
      .then((data) => {
        this.dataStore.equipment = this.dataStore.equipment.filter(equipment => equipment.id !== equipmentId);
        if (this.dataStore.studio && this.dataStore.studio.id === studioId) {
          const foundRoom = this.dataStore.studio.rooms.find((room) => room.id === roomId);
          foundRoom.equipment = foundRoom.equipment.filter((equipment) => equipment.id !== equipmentId);
          this.studio.next(this.dataStore.studio);
        }
        this.equipment.next(this.dataStore.equipment);
        return data;
      });
  }

  toggleReview( reviewId: string, visibility: boolean  ) {
    return this.helperService.patchAction('/reviews/' + reviewId , {visibility} )
      .toPromise()
      .then((data) => {
        const foundReview = this.dataStore.reviews.find(rev => rev.id === reviewId);
        if (foundReview) {
          foundReview.visibility = data.visibility;
        }
        this.reviews.next({results : this.dataStore.reviews });
        return data;
      });
  }


  mapInternalData( booking: any) {
    if (booking.hasOwnProperty('room') && typeof booking.room === 'string') {
      booking.room = booking.studio.rooms.find((room) => room.id === booking.room);
    }
    if (booking.hasOwnProperty('service') && typeof booking.service === 'string') {
      booking.service = booking.room.services.find((service) => service.id === booking.service);
    }
    if (booking.hasOwnProperty('discount') && typeof booking.discount === 'string') {
      booking.discount = booking.service.discounts.find((discount) => discount.id === booking.discount);
    }
    if (booking.hasOwnProperty('instruments') && booking.instruments.length > 0) {
      booking.instruments = booking.studio.instruments.filter((instrument) => {
        return booking.instruments.includes(instrument.id);
      });
    }
  }



}
