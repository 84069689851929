<p-card [style]="{border: '1px solid #938F99', borderRadius : 12}" styleClass="p-card-shadow">

    <div class="grid align-items-center">

      <div class="col-2">

        <p-avatar
          *ngIf="!review.team"
          shape="circle"
          size="large"
          [image]="review.user.hasOwnProperty('avatar') ? review.user.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
        >
        </p-avatar>

        <p-avatar
          *ngIf="review.team"
          shape="circle"
          size="large"
          [image]="review.team.hasOwnProperty('avatar') ? review.team.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
        >
        </p-avatar>

      </div>
      <div class="col-10">
        <h5 style="margin: auto">{{review.user.name | titleCaseExtendedPipe}}</h5>
        <div>{{review.createdAt | dateParser}}</div>
        <p-rating [(ngModel)]="review.rating" stars="5" [readonly]="true" [cancel]="false"></p-rating>
      </div>

      <div
        *ngIf="review.note"
        class="col-12"
      >
        <p>{{review.note}}</p>
      </div>

      <ng-template
        [ngIf]="review.response"
      >
        <div class="col-10" style="text-align: end;">
          <h5 style="margin: auto">{{review.studio.name | titleCaseExtendedPipe}}</h5>
          <div>{{review.createdAt | dateParser}}</div>
        </div>
        <div class="col-2">
          <p-avatar
            shape="circle"
            size="large"
            [image]="review.studio.avatar ? review.studio.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
          >
          </p-avatar>
        </div>
        <div
          *ngIf="review.response.note"
          class="col-12"
          style="text-align: end;"
        >
          <p>{{review.response.note}}</p>
        </div>
      </ng-template>

    </div>

</p-card>
