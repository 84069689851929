<p-dataView #dv
            [value]="(rooms | async)"
            [paginator]="true"
            [rows]="9"
            filterBy="room.name"
            [loading]="loading"
            layout="grid">
  <ng-template pTemplate="header">

    <div class="flex align-items-center justify-content-between">
      <p-button *ngIf="enableActions" icon="pi pi-plus" styleClass="p-button-success" (onClick)="openNew()"></p-button>
      <p-button icon="pi pi-refresh" (onClick)="refresh()"></p-button>
    </div>

  </ng-template>


  <ng-template let-room pTemplate="gridItem">
    <div class="col-12 md:col-4">
      <div class="product-grid-item ">
        <div class="product-grid-item-top">
        </div>
        <div class="product-grid-item-content">
          <img
            [src]="room.hasOwnProperty('avatar') && room.avatar ? room.avatar['link'] : 'assets/images/bandbeat/sample_studio_logo.png'"
            [alt]="room.name"/>
          <div class="product-name">{{room.name}}</div>
          <div class="product-description">{{room.description}}</div>
          <div class="product-description">Id : {{room.id}}</div>




          <div class="grid">

            <!--ROOM SERVICES-->

            <div class="col-12">
              <h5>
                Services
              </h5>

              <p-dataView #dvListingEq
                          [value]="room?.services"
                          layout="grid">
                <ng-template let-service pTemplate="gridItem">
                  <div class="col-12 ">
                    <div class="equipment-list-item">
                      <app-services-card style="width: inherit" [room]="room"
                                         [service]=service></app-services-card>
                    </div>
                  </div>
                </ng-template>
              </p-dataView>
            </div>



            <!--ROOM EQUIPMENT-->
            <div class="col-12">
              <h5>
                Equipment
              </h5>

              <p-dataView #dvListingEq
                          [value]="room?.equipment"
                          layout="grid">
                <ng-template let-equipment pTemplate="gridItem">
                  <div class="col-12 ">
                    <div class="equipment-list-item">
                      <app-equipment style="width: inherit"
                                     [equipment]=equipment></app-equipment>
                    </div>
                  </div>
                </ng-template>
              </p-dataView>
            </div>

          </div>

        </div>
        <div class="product-grid-item-bottom" style="margin-top: 0.5em">

          <button
            pButton
            pRipple
            type="button"
            class="p-button-danger mr-2 mb-2"
            icon="pi pi-times-circle"
            label="Delete"
            (click)="removeRoom(room)"
            *ngIf="enableActions"
          ></button>

          <button
            pButton
            pRipple
            type="button"
            class="p-button-info mr-2 mb-2"
            icon="pi pi-plus-circle"
            label="Add Instrument"
            (click)="openNewInstrument(room)"
            *ngIf="enableActions"
          ></button>

        </div>
      </div>
    </div>
  </ng-template>

</p-dataView>

<p-dialog
  [(visible)]="roomDialog"
  [style]="{width: '450px'}"
  header="New Room"
  [modal]="true"
  styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <label for="name">Name</label>
      <input type="text" pInputText id="name" [(ngModel)]="roomName" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !roomName">Name is required.</small>
    </div>
    <div class="field">
      <label for="name">Description</label>
      <input type="text" pInputText id="description" [(ngModel)]="roomDescription" required autofocus />
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="addRoom()"></button>
  </ng-template>
</p-dialog>



<p-dialog
  [(visible)]="instrumentDialog"
  [style]="{width: '450px'}"
  header="New Instrument"
  [modal]="true"
  styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <label for="name">Name</label>
      <input type="text" pInputText id="instrumentName" [(ngModel)]="instrumentName" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !instrumentName">Name is required.</small>
    </div>
    <div class="field">
      <label for="instdescription">Description</label>
      <input type="text" pInputText id="instdescription" [(ngModel)]="instrumentDescription" required autofocus />
    </div>
    <div class="field">
      <label for="category">Category</label>
      <p-dropdown
        id="category"
        [options]="instrumentCategories"
        [(ngModel)]="instrumentCategory"
        optionLabel="name" optionValue="code"
      ></p-dropdown>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="addInstrument()"></button>
  </ng-template>
</p-dialog>



