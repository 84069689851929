<div class="layout-footer">

  <div class="footerLogoMobile" style="width:100%">
    <div class="footer-left" style="justify-content:space-between">
<div style="display:flex">
        <i class="pi pi-facebook" style="margin-left: 1rem; font-size: 1.5rem; cursor: pointer;"
           (click)="redirectToFaceBook()"></i>

        <i class="pi pi-instagram" style="margin-left: 1rem; margin-right: 1rem; font-size: 1.5rem; cursor: pointer;"
           (click)="redirectToInstagram()"></i>
</div>
      <div style="float:right">
      <ul class="footer-links" style="padding:0px; align-items:center;">
        <li style="padding: 0 2vw;">
        <span>
          <a
            [routerLink]="['/dashboard/contact']">Contact
          </a>
        </span>
        </li>
        <li style="padding: 0 2vw;">
        <span>
          <a
            [routerLink]="['/dashboard/privacy&policy']">Privacy Policy
          </a>
        </span>
        </li>
        <li style="padding: 0 0.5vw;">
       <span>
          <a
            [routerLink]="['/dashboard/terms&conditions']">Terms & Conditions
          </a>
        </span>
        </li>
      </ul>
      </div>
    </div>
    <div style="text-align:center">© {{ year }} Bandbeat. All rights reserved.</div>
  </div>

  <div class="footer-left footerLogo">
    <img [src]="'assets/images/bandbeat/bb_logo_header.png'" style="width:60px;" alt="logo"/>
    <span class="app-name">Bandbeat</span>

    <i class="pi pi-facebook" style="margin-left: 2rem; font-size: 1.5rem; cursor: pointer;" (click)="redirectToFaceBook()"></i>
    <i class="pi pi-instagram" style="margin-left: 1rem; font-size: 1.5rem; cursor: pointer;" (click)="redirectToInstagram()"></i>
  </div>

  <div class="footer-right footerLogo">
    <ul class="footer-links" style="">
      <li style="padding: 0 10px;">
        <span>
          <a
            [routerLink]="['/dashboard/contact']">Contact
          </a>
        </span>
      </li>
      <li style="padding: 0 10px;">
        <span>
          <a
            [routerLink]="['/dashboard/privacy&policy']">Privacy Policy
          </a>
        </span>
      </li>
      <li style="padding: 0 10px;">
       <span>
          <a
            [routerLink]="['/dashboard/terms&conditions']">Terms & Conditions
          </a>
        </span>
      </li>
      <li style="padding: 0 10px;">
        <span>© {{ year }} Bandbeat. All rights reserved.</span>
      </li>
    </ul>

  </div>
</div>
