

<div class="grid"  *ngIf="contractHTML | async as contractData else loading">

  <div class="col-12" style="height:40vh !important; overflow-y: scroll;">
    <div [innerHTML]="contractData.value"></div>
  </div>

  <div class="col-6">

    <button
      pButton
      style="border-radius: 100px !important;width:100%"
      pRipple
      label="Back"
      type="button"
      class="secondary-button p-button-raised p-button-rounded p-button-lg"
      (click)="goBack()"
    ></button>
  </div>
  <div class="col-6">
    <button
      pButton
      style="width:100%"
      pRipple
      label="Agree & Sign up"
      type="button"
      class="p-button-raised p-button-rounded p-button-lg"
      (click)="acceptAndSignupContract()"
    ></button>

  </div>

</div>

<ng-template #loading>
  <div class="grid">
    <div class="col-12">
      <div class="bb-logo-loading ml-8 mt-8 mb-8" style="position: inherit;">
        <div class="l"></div>
        <div class="o"></div>
        <div class="waves">
          <div class="wave wave-short"></div>
          <div class="wave wave-long"></div>
          <div class="wave wave-short"></div>
          <div class="wave wave-long"></div>
          <div class="wave wave-short"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

