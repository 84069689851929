import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {filter, map} from 'rxjs/operators';
import {HelperService} from "../../common/helper.service";
import {StudiosService} from "../../studios/studios.service";
import {ProfileService} from "../../common/profile.service";
import {UserService} from "../../users/user/user.service";
import {BookingsService} from "../bookings.service";
import {BandsService} from "../../bands/bands.service";
import {Location} from '@angular/common';
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss']
})
export class BookingSummaryComponent implements OnInit {


  public env = environment;

  private bookingId;
  public booking;

  public needResponseAction = false;
  public needInviteAction = false;
  public needBookAction = false;


  public totalPrice = 0;
  public totalRentalPrice = 0;
  public totalServicePrice = 0;
  public bookingDiscount;
  public selectedPromoCode;

  public bookingDate;
  public bookingTime;

  private userId;

  band;

  public reviewVisible = false;
  public reviewRating;
  public reviewNote;
  public needReviewAction = false;

  public replyVisible = false;
  public replyNote;
  public needReplyAction = false;
  public userReview;

  public ratings;
  public allRatings;
  public rating;

  constructor(
      private helperService: HelperService,
      private studioService: StudiosService,
      private profileService: ProfileService,
      private userService: UserService,
      private route: ActivatedRoute,
      private router: Router,
      private bookingsService: BookingsService,
      private bandService: BandsService,
      private location: Location,
      private messageService: MessageService,
  ) {
      this.userId = this.profileService.getUserData().id;
      this.bookingId = this.route.snapshot.paramMap.get('bookingId');
      this.band = this.bandService.band$.pipe(filter(bnd => Object.keys(bnd).length !== 0));
  }

  ngOnInit(): void {

    this.booking = Promise.all([
      this.bookingsService.fetchBooking(this.bookingId),
      this.bookingsService.getReviews(this.bookingId)
    ])

        .then((data) => {
          const bookingData: any = data[0];
          const reviewsData: any = data[1];
          const bookDate = new Date(bookingData.bookingDate);
          this.bookingDate = bookDate.toString();
          this.bookingTime = bookDate.toString();
          if (bookingData.bookingType === 'band') {
            this.bandService.fetchBand(bookingData.team.id, true);
            this.bandService.fetchBandRatings(bookingData.team.id)
              .then((data) => {
                this.rating = Math.round(
                  (data.results.reduce( ( sum, { rating } ) => sum + rating , 0) / data.results.length) * 10
                ) / 10;
                this.allRatings = data.results.length;
                this.ratings = data.results.filter(a => a.note).reverse().slice(0,5);
              });
          } else if (bookingData.bookingType === 'solo') {
            this.userService.fetchUserRatings(bookingData.user.id)
              .then((data) => {
                this.rating = Math.round(
                  (data.results.reduce( ( sum, { rating } ) => sum + rating , 0) / data.results.length) * 10
                  ) / 10;
                this.allRatings = data.results.length;
                this.ratings = data.results.filter(a => a.note).reverse().slice(0,5);
              });
            this.band = undefined;
          }
          this.totalServicePrice = this.totalServicePrice + (bookingData.duration * bookingData.service.price);
          // this.totalPrice = this.totalPrice + this.totalServicePrice;
          if (bookingData.instruments && bookingData.instruments.length > 0) {
            bookingData.instruments.forEach((rental) => {
              this.totalRentalPrice = this.totalRentalPrice + rental.price;
              });
            this.totalRentalPrice = bookingData.duration * this.totalRentalPrice;
            // this.totalPrice = this.totalPrice + this.totalRentalPrice;
          }
          this.totalPrice = bookingData.totalPrice;
          this.bookingDiscount = bookingData.discount;
          this.selectedPromoCode = bookingData.promoCode;
          if ( bookingData.status === 'planning'  ) {
                const memberUser = bookingData.members.find((member) => member.member.id === this.userId);
                if (memberUser && memberUser.vote === 'pending') {
                    this.needResponseAction = true;
                }
          }

          if (
            bookingData.status === 'studio-confirmed' &&
            !this.helperService.isAfter(new Date(bookingData.bookingDate)) &&
            this.env.reviewEnabled &&
            this.env.reviewEnabled === true
          ) {
            const userReview = reviewsData.results.find(rev => rev.category === 'user');
            const partnerReview = reviewsData.results.find(rev => rev.category === 'partner');
            if (userReview && !userReview.response) {
              this.needReplyAction = true;
            }
            if (!partnerReview) {
              this.needReviewAction = true;
            }
            this.userReview = userReview;
          } else {
            this.needReviewAction = false;
          }

          return bookingData;
        });
  }

  back() {
      this.location.back();
    }



  showAddReview(event) {
    if (event === 'reply') {
      this.replyVisible = true;
    } else if (event === 'review') {
      this.reviewVisible = true;
    }
  }

  addReview() {
    this.bookingsService.addReview(this.bookingId, {rating : this.reviewRating, note : this.reviewNote})
      .then((data) => {
        this.reviewVisible = false;
        this.needReviewAction = false;
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Review Sent', life: 3000});
      });
  }

  addReply() {
      return this.bookingsService.addReply(this.userReview.id, { note : this.replyNote})
        .then((data) => {
          this.replyVisible = false;
          this.needReplyAction = false;
          this.messageService.add({
            key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Reply Sent', life: 3000});
        });
  }
}
