import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { HelperService } from "../common/helper.service";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private reports: BehaviorSubject<any>;
  public readonly reports$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private dataStore: {
    reports: any;
  };



  constructor( private helperService: HelperService ) {

    this.reports = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.reports$ = this.reports.asObservable();


    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.dataStore = {
      reports: {}
    };

  }



  fetchReports( category = 'BANDBEAT' , limit = 10000) {
    return this.helperService.getAction('/reports' , {category , limit} )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.reports, data);
        this.reports.next(data);
        return data;
      });
  }



  createBandbeatReport(  ) {
    return this.helperService.postAction('/reports' , {"type": "BANDBEAT"} )
      .toPromise()
      .then((data) => {
        this.dataStore.reports.results.unshift(data);
        this.reports.next(this.dataStore.reports);
        return data;
      });
  }

  downloadCSVReport( dataToDownload , type ) {
    let csvData = '';
    const csvHeaders = Object.keys(dataToDownload[0]);
    csvData = csvData + csvHeaders.join(',') + '\n';
    dataToDownload.forEach(data => csvData = csvData + csvHeaders.map(header => data[header]).join(',') + '\n' );
    this.helperService.saveToFile(csvData , type + '.csv' )
  }


}
