import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import {AppComponent} from '../app.component';
import {AppMainComponent} from '../app.main.component';
import {ProfileService} from '../common/profile.service';
import { Router } from "@angular/router";
import {LocalStorageService} from 'ngx-webstorage';
import { environment } from "../../environments/environment";
import { NotificationsService } from "../notifications/notifications.service";
import { map } from "rxjs/operators";
import { StudiosService } from "../studios/studios.service";
import {UserService} from "../users/user/user.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit {

    menu: any[];
    public studio: any;
    public user: any;
    public userMenu: any[];

  public loggedIn = false;
  public isAdmin = false;

  public env = environment;

  public studioLogo;
  public notifications;

  public isContractValid;

  public shouldShowWizard;

    @ViewChild('input1') inputElement1: ElementRef;

    @ViewChild('input2') inputElement2: ElementRef;


  scrolled: boolean = false;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 20;
  }

  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    private profileService: ProfileService,
    private notificationsService: NotificationsService,
    private studiosService: StudiosService,
    private userService: UserService,
    private localSt: LocalStorageService,
    private router: Router
  ) {
    this.localSt.observe('user')
      .subscribe((user) => {
        this.loggedIn = true;
        this.isAdmin = this.profileService.isAdmin();
      });

    this.localSt.observe('studio')
      .subscribe((studio) => {
        this.studioLogo = studio.media.find((media) => media.metadata.type === 'logo');

        if (this.isContractValid === undefined) {
          this.studiosService.fetchContractsValidity(studio.id);
        }

      })
    this.user = this.profileService.getUserData();
    if (Object.keys(this.user).length === 0) {
      this.loggedIn = false;
    } else {
      this.loggedIn = true;
      this.isAdmin = this.profileService.isAdmin();
    }
    this.studio = this.profileService.getOwnerStudio();
    if (Object.keys(this.studio).length > 0) {
      this.studioLogo = this.studio.media.find((media) => media.metadata.type === 'logo');
    }


    this.notifications = this.notificationsService.notifications$.pipe(map((notifs) => {
      return notifs
        .filter(
          (notif) => !notif.read_by.find(user => user.readerId === this.profileService.getUserData().id)
        )
    }));

    this.isContractValid = this.studiosService.contractValidity$.pipe(map((contract) => {
      return contract.valid;
    }))

    this.shouldShowWizard = this.userService.registrations$.pipe(map((userRegistrations) => {
      return userRegistrations.filter(el => el.event === 'partner_wizard').length === 1
    }))
  }

    ngOnInit() {
        this.menu = [{
            label: 'Menu',
            items: [
                {
                    label: 'UI Kit', icon: 'pi pi-align-left',
                    items: [
                        {label: 'Form Layout', icon: 'pi pi-id-card', routerLink: ['/dashboard/uikit/formlayout']},
                        {label: 'Input', icon: 'pi pi-check-square', routerLink: ['/dashboard/uikit/input']},
                    ]
                },
                {
                    label: 'Hierarchy', icon: 'pi pi-align-left',
                    items: [
                        {
                            label: 'Submenu 1', icon: 'pi pi-align-left',
                            items: [
                                {label: 'Submenu 1.1', icon: 'pi pi-align-left'},
                                {label: 'Submenu 1.2', icon: 'pi pi-align-left'},
                            ]
                        },
                        {
                            label: 'Submenu 2', icon: 'pi pi-align-left',
                            items: [
                                {label: 'Submenu 2.1', icon: 'pi pi-align-left'},
                            ]
                        },
                    ]
                }
            ]
        }
        ];

      if (this.profileService.isAdmin()) {

        this.userMenu = [
          {
            label: 'Reports',
            link: ['/dashboard/admin/reports' ]
          },
          {
            label: 'Users',
            link: ['/dashboard/admin/users' ]
          },
          {
            label: 'Bookings',
            link: ['/dashboard/admin/bookings' ]
          },
          {
            label: 'Studios',
            link: ['/dashboard/admin/studios' ]
          },
          {
            label: 'Reviews',
            link: ['/dashboard/admin/reviews' ]
          },
        ];
      } else if (this.profileService.isStudio()) {

        this.userMenu = [
          {
            label: 'Account',
            link: ['/dashboard/partner/profile' ]
          },
          {
            label: 'My Studio',
            link: ['/dashboard/partner/studio' ]
          },
          // {
          //   label: 'Notifications',
          //   link: ['/dashboard/partner/notifications']
          // },
        ];
      } else if (this.profileService.isUser()) {

        this.userMenu = [
          {
            label: 'Profile',
            link: ['/dashboard/users' , this.user.id]
          },
          {
            label: 'Create a Booking',
            link: ['/dashboard/newbooking']
          },
          {
            label: 'Notifications',
            link: ['/dashboard/notifications']
          },
        ];
      }


    }

    searchFocus(event) {
        if (this.appMain.search) {
            setTimeout(() => {
                this.inputElement1.nativeElement.focus();
                this.inputElement2.nativeElement.focus();
            }, 100);
        }
    }

    logoutUser() {
        this.profileService.logOutUser();
      this.loggedIn = false;
        this.router.navigate(['login']);
    }
}
