<div>
  <p-card *ngIf="!loading">
    <ng-template pTemplate="title">
      Booking Studio
    </ng-template>
    <ng-template pTemplate="subtitle">
      Select the Studio and its Services
    </ng-template>
    <ng-template pTemplate="content">
      <div class="p-fluid field">
        <label for="studio">Studio</label>

        <p-listbox
          id="studio"
          [options]="availableStudios"
          [(ngModel)]="selectedStudio"
          (onChange)="selectStudio($event)"
          optionLabel="name"
        ></p-listbox>


      </div>
      <ng-template [ngIf]="selectedStudio">

        <span>Pick one of Studios Services : </span>
        <div class="field">
          <label for="services">Services</label>
          <p-dropdown
            id="services"
            required
            [options]="availableServices"
            [(ngModel)]="selectedService"
            (onChange)="selectService($event)"
            optionLabel="name"
          ></p-dropdown>
        </div>

      </ng-template>

      <ng-template [ngIf]="
      selectedService &&
      selectedService.service &&
      selectedService.service.rooms &&
      selectedService.service.rooms.length > 0
">

        <span>Pick one of the offered Rooms : </span>
        <div class="field">
          <label >Rooms</label>
          <p-dropdown
            id="rooms"
            required
            [options]="availableRooms"
            [(ngModel)]="selectedRoom"
            (onChange)="selectRoom($event)"
            optionLabel="name"
          ></p-dropdown>


        </div>

      </ng-template>

    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-around">
        <p-button label="Previous" (onClick)="previousPage()" icon="pi pi-angle-left" iconPos="left"></p-button>
        <p-button label="Next" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
      </div>
    </ng-template>
  </p-card>
</div>
