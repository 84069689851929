<div class="grid bandbeatPage" *ngIf="studio | async as studioData">
  <div class="col-12" style="text-align: right">
    <p style="text-align: left">Cover Image</p>
    <img [src]="studioData.cover" class="cover-image" style="" />
    <button
      pButton
      id="coverButton"
      pRipple
      type="button"
      icon="edit"
      (click)="coverUpload.click()"
      class="p-button-rounded material-icons-outlined uploadButton hidden-mobile"
    >
      <div class="material-icons-outlined">edit</div>
    </button>

    <input #coverUpload hidden type="file" (change)="fileSelected($event)" />
  </div>
  <div class="col-8">
    <p>Logo Image</p>
    <p-avatar
      shape="circle"
      [image]="
        studioData.logo ? studioData.logo : 'assets/images/sample_member.png'
      "
    >
    </p-avatar>
    <button
      pButton
      id="logoButton"
      pRipple
      type="button"
      icon="edit"
      (click)="logoUpload.choose()"
      class="p-button-rounded p-button-icon-only material-icons-outlined uploadButton hidden-mobile"
    >
      <div class="material-icons-outlined">edit</div>
    </button>

    <p-fileUpload
      #logoUpload
      class="logoUploader hidden-mobile"
      [customUpload]="true"
      [auto]="true"
      (uploadHandler)="imageUpload($event, 'logo', 'studio')"
    ></p-fileUpload>
  </div>

  <div class="field col-12">
    <label for="about">About</label>
    <textarea
      [(ngModel)]="studioData.description"
      (ngModelChange)="textChange(studioData.description)"
      [rows]="5"
      [cols]="30"
      style="width: 100%"
      pInputTextarea
      id="about"
      type="text"
      class="w-full"
      [autoResize]="true"
      placeholder="Describe your Studio in English..."
    ></textarea>
  </div>

  <div class="col-12">
    <div class="grid grid-nogutter justify-content-around">
      <p-button
        label="Back"
        (onClick)="previousPage()"
        icon="pi pi-angle-left"
        iconPos="left"
      ></p-button>
      <button
        pButton
        class="secondary-button"
        label="Skip"
        (click)="nextPage(true)"
        icon="pi pi-angle-right"
        iconPos="right"
      ></button>
      <p-button
        label="Next"
        (onClick)="nextPage()"
        icon="pi pi-angle-right"
        iconPos="right"
        [disabled]="disableNext"
      ></p-button>
    </div>
  </div>
</div>

<p-dialog
  header="Upload Image"
  [(visible)]="uploadImageDialog"
  [style]="{ width: '50vw' }"
  [modal]="true"
  styleClass="p-fluid"
>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="true"
    [aspectRatio]="16 / 9"
    format="jpeg"
    [imageQuality]="70"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>

  <ng-template pTemplate="footer">
    <button
      icon="pi pi-check"
      iconPos="left"
      pButton
      type="button"
      label="Upload Cover Image"
      class="p-button-success w-full"
      (click)="imageUpload($event, 'cover', 'studio')"
    ></button>
  </ng-template>
</p-dialog>
