import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from '../app.main.component';
import { ProfileService } from "../common/profile.service";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(
      private profileService: ProfileService,
      public app: AppMainComponent
    ) {}

    ngOnInit() {
      if (this.profileService.isAdmin()) {
        this.model = [
          {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
              {label: 'Sales', icon: 'pi pi-desktop', routerLink: ['/dashboard']},
              {label: 'Analytics', icon: 'pi pi-chart-bar', routerLink: ['/dashboard/analytics']}
            ]
          },
          {
            label: 'Reports', icon: 'pi pi-users', routerLink: ['/dashboard/reports']
          },
          {
            label: 'Users', icon: 'pi pi-users', routerLink: ['/dashboard/users']
          },
          {
            label: 'Studios', icon: 'pi pi-star', routerLink: ['/dashboard/studios']
          },
          {
            label: 'Bands', icon: 'pi pi-sitemap', routerLink: ['/dashboard/bands']
          },
          {
            label: 'Bookings', icon: 'pi pi-ticket', routerLink: ['/dashboard/bookings']
          }
        ];
      } else if (this.profileService.isStudio()) {
        this.model = [
          {
            label: 'Dashboard', icon: 'pi pi-home', routerLink: ['/dashboard']
          },

          {
            label: 'Bookings Page', icon: 'pi pi-ticket', routerLink: ['/dashboard/partner/bookings']
          },
          {
            label: 'Studio Page', icon: 'pi pi-star', routerLink: ['/dashboard/partner/studio']
          },
          {
            label: 'Calendar Page', icon: 'pi pi-calendar', routerLink: ['/dashboard/partner/calendar']
          },
          {
            label: 'Profile Page', icon: 'pi pi-user', routerLink: ['/dashboard/partner/profile']
          }
        ];
      } else if (this.profileService.isUser()) {
        this.model = [
          {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
              {label: 'Sales', icon: 'pi pi-desktop', routerLink: ['/dashboard']},
              {label: 'Analytics', icon: 'pi pi-chart-bar', routerLink: ['/dashboard/analytics']}
            ]
          }
        ];
      }

    }
}
