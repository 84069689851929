<p-dataView #dv
            [value]="(services | async)"
            [paginator]="true"
            [rows]="9"
            filterBy="service.name"
            [loading]="loading"
            layout="grid">
  <ng-template pTemplate="header">

    <div class="flex align-items-center justify-content-between">
      <p-button icon="pi pi-plus" styleClass="p-button-success" (onClick)="openNew()"></p-button>
      <p-button icon="pi pi-refresh" (onClick)="refresh()"></p-button>
    </div>

  </ng-template>


  <ng-template let-service pTemplate="gridItem">
    <div class="col-12 md:col-4">
      <div class="product-grid-item card">
        <div class="product-grid-item-top">
          <div></div>
          <span [class]="'product-badge status-instock'">${{service.price}}</span>

<!--          <h6 class="mt-0 mb-3">${{service.price}}</h6>-->

        </div>
        <div class="product-grid-item-content">
          <img [src]="'assets/images/sample_studio.png'" [alt]="service.name"/>
          <div class="product-name">{{service.name}}</div>
          <div class="product-description">{{service.description}}</div>


          <span
            class="product-category"
          >Available at Rooms : </span>

          <br>
          <p-tag
            severity="success"
            *ngFor="let room of service.rooms"
            [value]="room.name"
            icon="pi pi-home"
          ></p-tag>

        </div>
        <div class="product-grid-item-bottom" style="margin-top: 0.5em">

          <button
            pButton
            pRipple
            type="button"
            class="p-button-danger mr-2 mb-2"
            icon="pi pi-times-circle"
            label="Delete"
            (click)="removeService(service)"
          ></button>

          <button
            pButton
            pRipple
            type="button"
            class="p-button-info mr-2 mb-2"
            icon="pi pi-plus-circle"
            label="Change Rooms"
            (click)="openNewChangeRooms(service)"
          ></button>

        </div>
      </div>
    </div>
  </ng-template>

</p-dataView>

<p-dialog
  [(visible)]="serviceDialog"
  [style]="{width: '450px'}"
  header="New Service"
  [modal]="true"
  styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <label for="name">Name</label>
      <input type="text" pInputText id="name" [(ngModel)]="serviceName" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !serviceName">Name is required.</small>
    </div>
    <div class="field">
      <label for="price">Price</label>
      <p-inputNumber
        id="price"
        [(ngModel)]="servicePrice"
        mode="currency"
        [currency]="businessCurrency"
      ></p-inputNumber>

    </div>
    <div class="field">
      <label for="rooms">Available at Rooms </label>
      <p-multiSelect
        id="rooms"
        [options]="availableRooms"
        [(ngModel)]="selectedRooms"
        optionLabel="name"
        optionValue="code"
      ></p-multiSelect>

    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="addService()"></button>
  </ng-template>
</p-dialog>



<p-dialog
  [(visible)]="serviceRoomsDialog"
  [style]="{width: '450px'}"
  header="Change Service Rooms"
  [modal]="true"
  styleClass="p-fluid">
  <ng-template pTemplate="content">

    <div
      class="field-checkbox"
      *ngFor="let room of availableServiceRooms">
      <p-checkbox
        name="availableRooms"
        [value]="room.key"
        [inputId]="room.key"
        [(ngModel)]="selectedServiceSelectedRooms"
      ></p-checkbox>
      <label [for]="room.key">{{room.name}}</label>
    </div>

  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="changeRooms()"></button>
  </ng-template>
</p-dialog>



