import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BookingsService} from "../../../bookings/bookings.service";
import {StudiosService} from "../../../studios/studios.service";
import {HelperService} from '../../helper.service';

@Component({
    selector: 'app-band-booking-overview',
    templateUrl: './band-booking-overview.component.html',
    styleUrls: ['./band-booking-overview.component.scss']
})
export class BandBookingOverviewComponent implements OnInit, OnChanges {

  @Input() detailedBooking;

  @Output() newItemEvent = new EventEmitter<any>();
  @Output() confirmBookingEvent = new EventEmitter<any>();
  @Output() reportBookingEvent = new EventEmitter<any>();

    constructor(
      private helperService: HelperService
    ) {
    }

    header:string

    ngOnInit(): void {

    }

  ngOnChanges(changes: SimpleChanges) {
    if (this.detailedBooking.bookingType === 'band'){
      this.header = this.detailedBooking.team.name
    }
    else if (this.detailedBooking.bookingType === 'solo'){
      this.header = this.detailedBooking.user.name
    }
    else{
      this.header = 'Offline Time';
    }

    this.detailedBooking.hasPassed = this.helperService.isBefore( new Date( this.detailedBooking.bookingDate ) );
    this.detailedBooking.instrumentsLabels =this.detailedBooking.instruments.map(inst => inst.equipment.name).join(', ');
  }

  removeOfflineBooking(booking){
    this.newItemEvent.emit(booking);
  }

  confirmBooking(booking, accept = true){
    this.confirmBookingEvent.emit({booking,accept});
  }

  reportBooking(booking) {
      this.reportBookingEvent.emit(booking);
  }

}
