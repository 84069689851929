import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HelperService} from '../common/helper.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private users: BehaviorSubject<any>;
  public readonly users$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private dataStore: {
    users: any
  };

  constructor( private helperService: HelperService ) {

    this.users = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.users$ = this.users.asObservable();

    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.dataStore = {
      users: {
        results : []
      }
    };
  }

  fetchUsers( limit = 100 ) {
    return this.helperService.getAction('/users' , { limit } )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.users, data);
        this.users.next(data);
        return data;
      });
  }

  addUser( newUser: any ) {
    return this.helperService.postAction('/users' , newUser )
      .toPromise()
      .then((data) => {
        this.dataStore.users.results.push(data);
        this.users.next(this.dataStore.users);
        return data;
      });
  }

  deleteUser( userId: string ) {
    return this.helperService.deleteAction('/users/' + userId )
      .toPromise()
      .then((data) => {
        this.dataStore.users.results = this.dataStore.users.results.filter(usr => usr.id !== userId);
        this.users.next(this.dataStore.users);
        return data;
      });
  }

  editUser( editedUser: any) {
    const editBody: any = {};
    Object.assign(editBody, editedUser);
    delete editBody.id;
    delete editBody.isEmailVerified;
    return this.helperService.patchAction('/users/' + editedUser.id , editBody )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.users.results.find(usr => usr.id === editedUser.id) , data);
        this.users.next(this.dataStore.users);
        return data;
      });
  }


}
