import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {StudiosService} from "../../studios/studios.service";
import {HelperService} from "../../common/helper.service";
import {ProfileService} from "../../common/profile.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-wizard-studio-hours',
  templateUrl: './wizard-studio-hours.component.html',
  styleUrls: ['./wizard-studio-hours.component.scss']
})
export class WizardStudioHoursComponent implements OnInit {

  private studioId;

  public studio;


  public availabilityDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private studiosService: StudiosService,
    private helperService: HelperService,
    private profileService: ProfileService,
  ) {
    this.studio = this.studiosService.studio$
  }


  ngOnInit(): void {

    this.studioId = this.profileService.getOwnerStudio().id;
    this.studiosService.fetchStudio(this.studioId, true)
      .then((studioData) => {
        this.availabilityDays.forEach((day) => {
          if (studioData.availability[day].from) {
            studioData.availability[day].from = new Date(studioData.availability[day].from);
            studioData.availability[day].from.setMinutes(Math.ceil(studioData.availability[day].from.getMinutes() / 30) * 30);
          }
          if (studioData.availability[day].to) {
            studioData.availability[day].to = new Date(studioData.availability[day].to);
            studioData.availability[day].to.setMinutes(Math.ceil(studioData.availability[day].to.getMinutes() / 30) * 30);
          }
        });
      });

  }

  updateLocalData(studio) {
    this.helperService.saveLocalStorage('studio' , studio);
    this.profileService.setOwnerStudio(studio);
  }

  saveDetails() {
    return this.studio.pipe(take(1)).toPromise()
      .then((studioData) => {
        let editedStudio = Object.assign({} , studioData);

        editedStudio = (({ id, availability}) =>
          ({ id, availability}))(editedStudio);

        return this.studiosService.editStudio(editedStudio);
      })
      .then((data) => {
        this.updateLocalData(data);
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Changes saved', life: 3000});
        return Promise.resolve(data);
      })
      .catch((err) => {
        this.messageService.add({
          key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
        throw err;
      });
  }


  previousPage() {
    this.router.navigate(['studio-media'], { relativeTo: this.route.parent });
  }

  nextPage( skip = false) {
    if (!skip) {
      this.saveDetails()
        .then((data) => {
          this.router.navigate(['studio-rooms'], { relativeTo: this.route.parent });
        });
    } else {
      this.router.navigate(['studio-rooms'], { relativeTo: this.route.parent });
    }
  }

}
