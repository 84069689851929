<div class="grid grid grid-nogutter justify-content-center" >
  <div class="col-4" style="margin-top:40px; text-align: center;">
    <div>
      <img
        [src]="'/assets/images/sample_studio.png'"
        alt="studioLogo"
        style="width: 70%;vertical-align:middle;"
      />
      <h1>
        Oh it's a pity!
      </h1>
    </div>
    <div>
      <span>We hope to see you soon</span>
    </div>

    <div style="margin-top: 40px;">
      <button
        pButton
        type="button"
        label="Return to login page"
        (click)="goToHomepage()"
      ></button>
    </div>
  </div>

</div>
