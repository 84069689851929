<p-card [style]="{border: '1px solid #938F99',borderRadius : 12}" styleClass="p-card-shadow">

    <ng-template pTemplate="header">
        <div class="grid align-items-center">
            <div class="col-2" style="margin: auto">
                <p-avatar image="assets/demo/images/avatar/amyelsner.png" shape="circle" size="xlarge"
                          styleClass="mr-2"></p-avatar>
            </div>
            <div class="col-6"><h5 style="margin: auto">{{studio.name}}</h5>
                <div>{{studio.address}}</div>
            </div>
            <div class="col-4 ">
                <div class="col-4" style="color:#FFFFFF"><strong>20$/hour</strong></div>
            </div>
        </div>
    </ng-template>


    <img alt="Card" src="../../../../assets/images/sample_studio.png">

    <div class="grid align-items-center">
        <div class="col-4"><i class="pi pi-exclamation-triangle col" style="padding: unset"></i> {{studio.rooms.length}}
            Rooms
        </div>
        <div class="col-4"><i class="pi pi-exclamation-triangle col" style="padding: unset"></i> Recording</div>
        <div class="col-4"><i class="pi pi-exclamation-triangle col" style="padding: unset"></i> Rehearsal</div>
    </div>

    <p>{{studio.description}}</p>
    <ng-template pTemplate="footer">
        <p-button [style]="{width: '100%'}" class="p-button-lg p-button-rounded"
                  label="Book Studio"></p-button>
    </ng-template>
</p-card>
