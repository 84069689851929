import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { HelperService } from "../../../../common/helper.service";
import { StudiosService } from "../../../studios.service";
import { ProfileService } from "../../../../common/profile.service";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-studio-monthly-reports',
  templateUrl: './studio-monthly-reports.component.html',
  styleUrls: ['./studio-monthly-reports.component.scss']
})
export class StudioMonthlyReportsComponent implements OnInit {


  public env = environment;
  public user;

  private studioId;

  public monthlyReports;
  public availableMonths;
  public pickedMonth;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private helperService: HelperService,
    private studiosService: StudiosService,
    private profileService: ProfileService,
  ) {

    this.studioId = this.profileService.getOwnerStudio().id;
    this.monthlyReports = this.studiosService.monthlyReports$.pipe(
      map((data) => {
        this.availableMonths = data.map((el) => {
          return {
            name : this.helperService.formatDate(el.metadata.reportDate , 'MM-yyyy'),
            code : el.id,
            report : el
          }
        })
        return data;
      })
    );
    this.studiosService.fetchStudioReports(this.studioId );

  }

  ngOnInit(): void {
  }

  pickMonth(event) {
    const downloadUrl = environment.backendUrl + '/studio/' + this.studioId + '/reports' + '/monthly?date='+ new Date(event.value.report.metadata.reportDate).toISOString();
    window.open(downloadUrl,'_blank');
  }


}
