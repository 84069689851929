import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-filled-icon',
  templateUrl: './filled-icon.component.html',
  styles: [':host ::ng-deep .p-avatar {background-color:#C4B5D5; color:#000000}']
})
export class FilledIconComponent implements OnInit {

  @Input() icon: string;
  @Input() tnIcon: string;
  constructor() { }

  ngOnInit(): void {
  }
}
