<div class="grid" *ngIf="user | async as userData">
  <div class="col-2">

      <img
        [style]="{'max-height': '500px' , 'width' : '150px', 'height':'auto'} "
        class="m-5"
        [src]="userData.hasOwnProperty('avatar') && userData.avatar ? userData.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
        alt="User Image"
      >

  </div>
  <div class="col-10">
      <div class="grid m-5">
        <div class="col-6">
          Name : {{userData.name}}
        </div>
        <div class="col-6">
          Email : {{userData.email}}
        </div>
        <div class="col-6">
          Phone Number : {{userData.phoneNumber}}
        </div>
        <div class="col-6">
          Role : {{userData.role}}
        </div>
        <div class="col-6">
          Last Login : {{userData.lastLogin | date:'dd/MM/yyyy'}}
        </div>
        <div class="col-12">
          {{userData.description}}
        </div>


        <div class="col-12">

          <span >Instruments</span>


          <div style="padding-top:10px" class="flex align-items-center music-roles">

            <ng-template ngFor let-musicRole let-i="index" [ngForOf]="userData.musicRoles">

              <p-chip
                [label]="musicRolesMap.get(musicRole.type)?.label"
                [ngClass]="{'primary-music-role':musicRole.primary}"
                styleClass="custom-chip mr-4 primary"
                class="custom-chip"
                icon="{{musicRolesMap.get(musicRole.type)?.icon}}"
              ></p-chip>
            </ng-template>

          </div>

        </div>


      </div>
  </div>

  <div class="col-12">
      <p-tabView styleClass="tabview-custom  m-5">
<!--        <p-tabPanel>-->

<!--          <ng-template pTemplate = "header">-->
<!--            <i class="pi pi-calendar"></i>-->
<!--            <span>Instruments</span>-->
<!--          </ng-template>-->

<!--        <app-user-instruments-->
<!--          [instruments]="userData.instruments"-->
<!--          [userId]="userData.id"-->
<!--        ></app-user-instruments>-->


<!--        </p-tabPanel>-->
        <p-tabPanel header="Header II">

          <ng-template pTemplate = "header">
            <i class="pi pi-user"></i>
            <span>Bands</span>
          </ng-template>

          <app-user-bands
            [userId]="userData.id"
          ></app-user-bands>

        </p-tabPanel>
        <p-tabPanel header="Header III">
          <ng-template pTemplate = "header">
            <i class="pi pi-calendar"></i>
            <span>Bookings</span>
          </ng-template>

          <app-user-bookings
            [userId]="userData.id"
          ></app-user-bookings>

        </p-tabPanel>
        <p-tabPanel>

          <ng-template pTemplate = "header">
            <i class="pi pi-calendar"></i>
            <span>Reviews</span>
          </ng-template>

          <app-user-reviews
            [userId]="userData.id"
          ></app-user-reviews>

        </p-tabPanel>
      </p-tabView>
  </div>


</div>


<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
