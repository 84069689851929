<div class="grid grid grid-nogutter justify-content-center" >
  <div class="col-4" style="margin-top:40px; text-align: center;">
    <div>
      <img
        [src]="'/assets/images/static-wave.png'"
        alt="staticWave"
        style="width: 50%;vertical-align:middle;"
      />
      <h3>
        Thank you for becoming part of the Bandbeat Community!
      </h3>
    </div>
    <div>
      <span>A member of the Bandbeat team will contact your Studio for verification</span>
    </div>

    <div style="margin-top: 40px;">
      <button
        pButton
        type="button"
        label="Meanwhile you can set up your Studio Profile"
        (click)="goToHomepage()"
      ></button>
    </div>
  </div>

</div>
