import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {ActivatedRoute, Router} from "@angular/router";
import {BookingsService} from "../bookings/bookings.service";
import {StudiosService} from "../studios/studios.service";

@Component({
  selector: 'app-setup-wizard',
  templateUrl: './setup-wizard.component.html',
  styleUrls: ['./setup-wizard.component.scss']
})
export class SetupWizardComponent implements OnInit {


  items: MenuItem[] = [{
    label: 'Studio Information',
    routerLink: ['studio-info']
  },
    {
      label: 'Studio Media',
      routerLink: ['studio-media']
    },
    {
      label: 'Opening Hours',
      routerLink: ['studio-hours']
    },
    {
      label: 'Rooms',
      routerLink: ['studio-rooms']
    },
    {
      label: 'Instruments',
      routerLink: ['studio-rentals']
    }
  ];
  activeIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.router.navigate(this.items[0].routerLink, { relativeTo: this.route });

  }


}
