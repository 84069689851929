<div class="layout-wrapper layout-slim">
  <div class="layout-topbar">
    <div class="layout-topbar-left">
      <a class="app-name" href="/">
        <img alt="main logo" [src]="'assets/images/bandbeat/bb_logo_header.png'" class="app-logo">
        <span>Bandbeat</span>
      </a>
    </div>
  </div>

  <div class="layout-content-wrapper" style="width:100%;margin-left:0px;">
    <div class="layout-content" style="background:linear-gradient(147.38deg, #050505 99%, #121212 77%) !important">
      <div class="landing-body" style="background:transparent !important; height: 100%">
        <div class="landing-wrapper">
          <div class="header-content grid " style="margin-right: unset;color: #FFFFFF;">
            <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
              <section>
                <h1 class="welcomeMessage">Welcome to <span class="keyword">Band</span>beat</h1>
                <p
                  *ngIf="registrationStep === 'user'"
                  class="subtitle mb-4">We will just need the following information from you to become part of the
                  Bandbeat community</p>


                <p
                  *ngIf="registrationStep === 'business'"
                  class="subtitle mb-4">Please enter the following information as they should be shown on all invoices</p>

                <ng-template
                  [ngIf]="registrationStep === 'user'"
                >

                  <div class="p-fluid grid formgrid">
                    <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <input inputTrim
                          id="name"
                          type="text"
                          pInputText
                          [(ngModel)]="userName"
                        />
                        <label for="name">Name *</label>
                    </span>
                    </div>


                    <div class="field col-5 md:col-2">
                      <p-dropdown
                        inputId="country"
                        id="country"
                        required="true"
                        optionValue="phone"
                        [options]="countries"
                        [(ngModel)]="phonePrefix"
                        (onChange)="selectCountry($event)"
                        placeholder="Select a Country"
                      >

                        <ng-template let-country pTemplate="selectedItem">
                          <div class="flex align-items-center gap-2">
                            <div> <span>{{country.emoji}}</span> +{{ country.phone }}</div>
                          </div>
                        </ng-template>

                        <ng-template let-country pTemplate="item">
                          <div class="flex align-items-center gap-2">
                            <div> <span>{{country.emoji}}</span> +{{ country.phone }}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>


                    <div class="field col-7 md:col-4">
                    <span class="p-float-label">
                        <p-inputNumber
                          id="phoneNumber"
                          inputId="phoneNumber"
                          [prefix]="'(+' + phonePrefix + ') '"
                          [useGrouping]="false"
                          [(ngModel)]="userPhoneNumber"
                        ></p-inputNumber>
                        <label for="phoneNumber">Mobile Number *</label>
                    </span>
                    </div>


                    <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <input inputTrim
                          id="studioName"
                          type="text"
                          pInputText
                          [(ngModel)]="studioName"
                        />
                        <label for="email">Studio Name *</label>
                    </span>
                    </div>

                    <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <input inputTrim
                          id="email"
                          type="text"
                          pInputText
                          autocomplete="on"
                          [(ngModel)]="userEmail"
                        />
                        <label for="email">Email *</label>
                    </span>
                    </div>


                    <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <p-password
                                  id="password"
                                  inputId="password"
                                  [toggleMask]="true"
                                  [(ngModel)]="userPassword"
                                ></p-password>
                                <label for="password">Password *</label>
                            </span>
                    </div>

                    <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <p-password
                          id="confirmpassword"
                          inputId="confirmpassword"
                          required
                          class="mask-icon"
                          [(ngModel)]="userConfirmPassword"
                          [toggleMask]="true"
                        ></p-password>
                        <label for="password">Confirm Password *</label>
                    </span>
                    </div>






                    <div class="field-checkbox col-12 md:col-12">
                      <div class="col-1">
                        <p-checkbox [(ngModel)]="termsAndConditionAccepted" [binary]="true" inputId="binary"
                                    required="true"></p-checkbox>
                      </div>
                      <div class="col-11">
                        <p>I agree to the <span class="font-bold cursor-pointer" [routerLink]="['/dashboard/terms&conditions']">Terms & Conditions</span>
                          and <span class="font-bold cursor-pointer" [routerLink]="['/dashboard/privacy&policy']">Privacy policy</span>. *
                        </p>
                      </div>
                    </div>

                    <div class="field-checkbox col-12 md:col-12">
                      <div class="col-1">
                        <p-checkbox [(ngModel)]="newsletterAccepted" [binary]="true" inputId="binary"
                                    required="true"></p-checkbox>
                      </div>
                      <div class="col-11">
                        <p>I agree to sign up to the <span class="keyword" >Bandbeat</span> newsletter.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 center-signup-btn">

                    <button
                      *ngIf="!env.contractsEnabled"
                      pButton
                      pRipple
                      style="width:100%"
                      label="Sign up"
                      type="button"
                      class="p-button-raised p-button-rounded p-button-lg"
                      (click)="signup(false)"
                    ></button>

                    <button
                      *ngIf="env.contractsEnabled"
                      pButton
                      pRipple
                      style="width:100%"
                      label="Proceed"
                      type="button"
                      class="p-button-raised p-button-rounded p-button-lg"
                      (click)="proceed('business')"
                    ></button>

                  </div>

                  <div class="mt-4">
                    <span> *required fields</span>
                  </div>
                </ng-template>

                <ng-template
                  [ngIf]="registrationStep === 'business'"
                >
                  <app-company
                    [(businessCity)]="businessCity"
                    [(businessCountry)]="businessCountry"
                    [(businessName)]="businessName"
                    [(businessNumber)]="businessNumber"
                    [(businessStreet)]="businessStreet"
                    [(businessZip)]="businessZip"
                    [(businessCurrency)]="businessCurrency"
                    [(studioVat)]="studioVat"
                    (proceedToContract)="proceed($event)"
                    (backTo)="goBack($event)"
                  >
                  </app-company>

                </ng-template>

                <ng-template
                  [ngIf]="registrationStep === 'contract'"
                >
                  <app-contract
                    [studio_name]="studioName"
                    [business_name]="businessName"
                    [billing_country]="businessCountry"
                    [billing_city]="businessCity"
                    [billing_street]="businessStreet"
                    [billing_street_number]="businessNumber"
                    [billing_zip_code]="businessZip"
                    [billing_tin_number]="studioVat"
                    [billing_email]="userEmail"
                    [studio_owner_name]="userName"
                    [individual_name]="userName"
                    [individual_capacity]="'OWNER'"
                    (acceptContract)="signup($event)"
                    (backTo)="goBack($event)"
                  >
                  </app-contract>

                </ng-template>

                <p-messages></p-messages>

                <div class="mt-4" *ngIf="showLogin">
                  <span >Already have an account? <span class="font-bold cursor-pointer"
                                                      [routerLink]="['/login']">Sign in here!</span></span>
                </div>

              </section>
            </div>
            <div class="col-12 md:col-4 md:col-offset-1 overflow-hidden">
              <!-- <p-image [src]="'assets/images/landing/pexels-eduardo-romero-2949091.jpeg'" alt="Image" width="100%" max-height="500px"></p-image> -->
              <!-- <app-wave-animation></app-wave-animation> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
