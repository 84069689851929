<p-dataView #dv
            [value]="(invitesAndBands | async)"
            [paginator]="true"
            [rows]="9"
            filterBy="filterName"
            [loading]="loading"
            layout="list">
  <ng-template pTemplate="header">

    <div class="flex align-items-center justify-content-between">
<!--      <p-button icon="pi pi-plus" styleClass="p-button-success" (onClick)="bandDialog = true"></p-button>-->
      <span class="p-input-icon-left mb-2 md:mb-0">
                    <i class="pi pi-search"></i>
                    <input type="search" pInputText placeholder="Search by Name" (input)="dv.filter($event.target.value)">
      </span>
      <p-button icon="pi pi-refresh" (onClick)="refresh()"></p-button>
    </div>

  </ng-template>
  <ng-template let-item pTemplate="listItem">
    <div class="col-12">
      <div class="product-list-item">
        <ng-template
          [ngIf]="item.dataType === 'invite'"
        >

          <img [src]="item.team.avatar ? item.team.avatar['link'] : 'assets/images/sample_band.png'"  [alt]="item.team.name"/>
          <div class="product-list-detail">
            <div class="product-name">{{item.team.name}}</div>
            <div class="product-description">{{item.team.description}}</div>
            <i style="margin-left: 0.5em;" class="pi pi-user product-category-icon"></i><span class="product-category" *ngIf="item.team.members">{{item.team.members.length}}</span>
          </div>
          <div class="product-list-action">
            <span class="product-price">Wanna Join?</span>
            <p-button
              *ngIf="enableActions"
              icon="pi pi-check-circle"
              styleClass="p-button-success mr-2 mb-2"
              label="Accept Invite"
              (click)="acceptInvite(item)"
            ></p-button>

            <p-button
              *ngIf="enableActions"
              pRipple
              styleClass="p-button-danger mr-2 mb-2"
              icon="pi pi-minus-circle"
              label="Decline Invite"
              (click)="declineInvite(item)"
            ></p-button>

            <p-button icon="pi pi-arrow-circle-right" label="See Details" [disabled]="true"></p-button>


          </div>

        </ng-template>

        <ng-template
          [ngIf]="item.dataType === 'band'"
        >

          <img [src]="item.avatar ? item.avatar['link'] : 'assets/images/sample_band.png'"  [alt]="item.name"/>
          <div class="product-list-detail">
            <div class="product-name">{{item.name}}</div>
            <div class="product-description">{{item.description}}</div>
            <i style="margin-left: 0.5em;" class="pi pi-user product-category-icon"></i><span class="product-category" *ngIf="item.members">{{item.members.length}}</span>
          </div>

          <div class="product-list-action">
<!--            <p-button-->
<!--              pRipple-->
<!--              styleClass="p-button-danger mr-2 mb-2"-->
<!--              icon="pi pi-minus-circle"-->
<!--              label="Leave Band"-->
<!--              (click)="leaveBand(item)"-->
<!--            ></p-button>-->

            <p-button icon="pi pi-arrow-circle-right" label="See Details"  (onClick)="seeBand(item)"></p-button>

          </div>

        </ng-template>

      </div>
    </div>
  </ng-template>



</p-dataView>

<p-dialog [(visible)]="bandDialog" [style]="{width: '450px'}" header="Band Details" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <img [src]="'assets/images/sample_band.png'" style="width:100%;height:auto;" [alt]="band.image" class="product-image" >
    <div class="field">
      <label for="name">Name</label>
      <input type="text" pInputText id="name" [(ngModel)]="band.name" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !band.name">Name is required.</small>
    </div>
    <div class="field">
      <label for="description">Description</label>
      <input type="text" pInputText id="description" [(ngModel)]="band.description" required autofocus />
      <small class="p-invalid" *ngIf="submitted && !band.label">Description is required.</small>
    </div>



  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveBand()"></button>
  </ng-template>
</p-dialog>


